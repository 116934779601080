<template>
    <div class="no-xhide">
        <div data-aos="fade-up" class="pos-rlt d-none d-md-block">
            <v-card class="elevation-0 px-16 py-10 support-main-bg rounded-xl">
                <v-row no-glutters>
                    <v-col cols="6">
                        <p class="white--text fs-50 font-weight-bold mb-6">Can we help you?</p>
                        <p class="white--text headline">Stuck somewhere, you're just a <br> click away from help @ Zebu.
                            <br>
                            Type your question for answers.
                        </p>
                    </v-col>
                    <v-col cols="6">
                        <v-container fill-height class="d-flex">
                            <v-card class="elevation-0 mt-auto" width="100%" color="transparent">
                                <p class="mb-2 ml-2 font-weight-thin white--text">What do you need help with?</p>
                   
                                <v-autocomplete @change="searchTo()" item-text="attributes.topic_question" item-value="id"
                                    :disabled="isLoading" :loading="isLoading" append-icon="" :items="Searchitems"
                                    v-model="searchis" block flat solo clearable return-object
                                    background-color="#ffffff" class="elevation-0 mr-3 rounded-pill caption"
                                    label="Enter your search term here" hide-details>
                                    <template #prepend-inner>
                                        <img src="@/assets/search-icon.svg" width="100%" class="mx-2" alt="search-icon">
                                    </template>
                                </v-autocomplete>
                            </v-card>
                        </v-container>
                    </v-col>
                </v-row>
            </v-card>
        </div>
        <div data-aos="fade-up" class="d-md-none">
            <div class="text-center pt-16 pos-rlt px-6 px-sm-8 bgg-lin">
                <h1 class="hero-ctn-title mb-3">Can we help you?</h1>
                <p class="white--text title font-weight-regular mb-8">Stuck somewhere, you're just a click away from help @
                    Zebu. Type your question for answers.
                </p>

                <v-autocomplete @change="searchTo()" item-text="attributes.topic_question" item-value="id"
                    :disabled="isLoading" :loading="isLoading" append-icon="" :items="Searchitems" v-model="searchis" block
                    flat solo clearable return-object background-color="#ffffff"
                    class="elevation-0 pb-10 rounded-pill caption" label="Enter your search term here" hide-details>
                    <template #prepend-inner>
                        <img src="@/assets/search-icon.svg" width="100%" class="mx-2" alt="search-icon">
                    </template>
                </v-autocomplete>

            </div>
        </div>
        <div class="pa-md-16 mb-6">
            <div>
                <v-row v-if="mainsrc" no-glutters>
                    <v-col cols="12" md="7" class="pr-md-10">
                        <div class="pa-6 pa-sm-8 pa-md-0">
                            <div class="d-none d-md-block">
                                <p class="font-weight-bold fs-50 mb-0">Knowledge base</p>
                                <p class="headline font-weight-medium mb-16">Everything you need to know about Zebu</p>
                            </div>

                            <div class="d-md-none">
                                <p class="font-weight-bold fs-32 mb-0">Knowledge base</p>
                                <p class="fs-18 font-weight-medium">Everything you need to know about Zebu</p>
                            </div>

                            <div v-if="loader">
                                <v-container fill-height>
                                    <v-card class="elevation-0 mx-auto my-16">
                                        <v-progress-circular size="80" indeterminate color="#1e53e5"></v-progress-circular>
                                    </v-card>
                                </v-container>
                            </div>
                            <div v-else>
                                <v-card @click="getCategorie(f = t.id, q = 't')" data-aos="flip-up"
                                    v-for="(t, c) in knowledge" :key="c" width="100%"
                                    class="box-s1 hcard px-2 py-1 sec-two-sty1 rounded-xl"
                                    :class="c != knowledge.length - 1 ? 'mb-6' : 'mb-0'">
                                    <v-list-item class="pr-0">
                                        <v-list-item-avatar tile size="32">
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title class="font-weight-bold title d-none d-md-flex">{{
                                                t.attributes.category_heading
                                            }}</v-list-item-title>
                                            <v-list-item-title class="font-weight-bold fs-18 d-md-none">{{
                                                t.attributes.category_heading
                                            }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                            </div>
                        </div>

                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="px-6 px-sm-8 px-md-0">
                            <p class="secondary-infont mt-6 fs-32 d-none d-md-block">Frequently Asked Questions</p>
                        </div>

                        <div v-if="loader">
                            <v-container fill-height>
                                <v-card class="elevation-0 mx-auto my-16">
                                    <v-progress-circular size="80" indeterminate color="#1e53e5"></v-progress-circular>
                                </v-card>
                            </v-container>
                        </div>
                        <div v-if="faq.length > 0 && !loader">
                            <v-card width="100%"
                                class="price-card-sty elevation-0 rounded-xl py-4 px-3 sec-two-sty2 d-none d-md-block">
                                <v-card class="elevation-0" color="transparent" @click="singleQuestion(f)"
                                    v-for="(f, a) in faq" :key="a">
                                    <v-list-item data-aos="fade-left" two-line class="py-2">
                                        <v-list-item-content>
                                            <p class="mb-2 text-uppercase txt-666666">{{
                                                f.attributes.support_category.data.attributes.category_heading }}</p>
                                            <p class="font-weight-regular mb-0 fs-20">{{
                                                f.attributes.topic_question }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider data-aos="fade-right"></v-divider>
                                </v-card>
                                <v-card v-if="totalqst ? page != totalqst.pagination.total : ''" data-aos="fade-up"
                                    width="100%" color="transparent" class="text-center elevation-0 pt-4">
                                    <v-btn @click="moreFaq()" text color="#0037B7"><span
                                            class="mx-auto font-weight-bold text-none">More
                                            FAQs</span></v-btn>
                                </v-card>
                            </v-card>
                            <v-card width="100%"
                                class="price-card-sty elevation-0 rounded-0 py-4 px-6 px-sm-8 d-md-none sec-two-sty2">
                                <p class="secondary-infont mt-4 fs-28 d-md-none">Frequently Asked Questions</p>

                                <v-card class="elevation-0" color="transparent" @click="singleQuestion(f)"
                                    v-for="(f, a) in faq" :key="a">
                                    <v-list-item data-aos="fade-left" two-line class="pt-2 px-0">
                                        <v-list-item-content>
                                            <p class="mb-2 text-uppercase txt-666666 fs-14">{{
                                                f.attributes.support_category.data.attributes.category_heading }}</p>
                                            <p class="font-weight-regular mb-0 fs-18">{{
                                                f.attributes.topic_question }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider data-aos="fade-right"></v-divider>
                                </v-card>
                                <v-card v-if="totalqst ? page != totalqst.pagination.total : ''" data-aos="fade-up"
                                    width="100%" color="transparent" class="elevation-0 pt-4">
                                    <v-btn @click="moreFaq()" text color="#0037B7"><span
                                            class="mx-auto font-weight-bold text-none">More
                                            FAQs</span></v-btn>
                                </v-card>
                            </v-card>
                        </div>

                    </v-col>
                </v-row>
                <v-row v-else no-glutters>
                    <v-col cols="12" md="7" class="pr-md-10 pb-0 pb-md-3">
                        <p class="mb-md-4 mt-8 mb-0 mt-md-0 ml-6 ml-md-8 ml-md-4 subtitle-2 font-weight-regular"><span
                                @click="goBack()" class="cursor-p">Home
                                
                            </span> → {{
                                sinfaq.attributes.category_heading
                            }}
                        </p>
                        <v-card data-aos="flip-up" width="100%"
                            class="box-s1 pa-4 mb-6 sec-two-sty1 d-none d-md-block rounded-xl">
                            <p class="font-weight-bold fs-24 mb-2">{{ singlequest.attributes.topic_question }}</p>
                            <v-divider></v-divider>
                            <p class="pt-4 mb-0 font-weight-light white-space"
                                v-html="singlequest.attributes.topic_question_ans"></p>
                        </v-card>
                        <v-card data-aos="flip-up" width="100%"
                            class="pa-6 pa-sm-8 d-md-none mb-md-6 elevation-0 rounded-0">
                            <p class="font-weight-bold fs-24 mb-2">{{ singlequest.attributes.topic_question }}</p>
                            <v-divider></v-divider>
                            <p class="pt-4 mb-0 font-weight-light white-space"
                                v-html="singlequest.attributes.topic_question_ans"></p>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="5">
                        <p class="secondary-infont fs-32 d-none d-md-flex">{{ sinfaq.attributes.category_heading }}</p>
                        <v-card width="100%"
                            class="price-card-sty elevation-0 rounded-xl py-4 px-3 sec-two-sty2 d-none d-md-block">
                            <v-card class="elevation-0" color="transparent" @click="singleQuestion(f)"
                                v-for="(f, a) in sinfaq.attributes.support_questions.data" :key="a">
                                <v-list-item data-aos="fade-left" two-line class="py-2">
                                    <v-list-item-content>
                                        <p class="mb-2 text-uppercase txt-666666">{{
                                            sinfaq.attributes.category_heading }}</p>
                                        <p class="font-weight-regular mb-0 fs-20">{{
                                            f.attributes.topic_question }}</p>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider v-if="sinfaq.attributes.support_questions.data.length - 1 != a"
                                    data-aos="fade-right"></v-divider>
                            </v-card>
                        </v-card>
                        <v-card width="100%"
                            class="price-card-sty elevation-0 rounded-0 py-4 px-6 px-sm-8 d-md-none sec-two-sty2">
                            <p class="secondary-infont mt-4 fs-28 d-md-none">{{ sinfaq.attributes.category_heading }}</p>

                            <v-card class="elevation-0" color="transparent" @click="singleQuestion(f)"
                                v-for="(f, a) in sinfaq.attributes.support_questions.data" :key="a">
                                <v-list-item data-aos="fade-left" two-line class="pt-2 px-0">
                                    <v-list-item-content>
                                        <p class="mb-2 text-uppercase txt-666666 fs-14">{{
                                            sinfaq.attributes.category_heading }}</p>
                                        <p class="font-weight-regular mb-0 fs-18">{{
                                            f.attributes.topic_question }}</p>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider v-if="sinfaq.attributes.support_questions.data.length - 1 != a"
                                    data-aos="fade-right"></v-divider>
                            </v-card>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { strapi } from '../apiurl';

export default {

    data: () => ({
        loader: true,
        mainsrc: true,
        knowledge: [
            // { img: require('@/assets/support/kb1.svg'), tit: 'My account', sub: 'Opening - KYC - Nomination - Misc', article: '324 articles' },
            // { img: require('@/assets/support/kb2.svg'), tit: 'Payments', sub: 'Add funds - Check balance - Charges - Withdraw ', article: '324 articles' },
            // { img: require('@/assets/support/kb3.svg'), tit: 'Stocks', sub: 'Opening - KYC - Nomination - Misc', article: '324 articles' },
            // { img: require('@/assets/support/kb4.svg'), tit: 'Futures and options', sub: 'Opening - KYC - Nomination - Misc', article: '324 articles' },
            // { img: require('@/assets/support/kb5.svg'), tit: 'Mutual funds', sub: 'Opening - KYC - Nomination - Misc', article: '324 articles' },
            // { img: require('@/assets/support/kb6.svg'), tit: 'Technical', sub: 'Software - Backoffice - Pledge', article: '324 articles' },
        ],
        faq: [
            // { tit: 'Payments', sub: 'How can I place a simple order (New App)?' },
            // { tit: 'My Account', sub: 'What is the procedure to reopen my Zebuetrade account?' },
            // { tit: 'My account', sub: 'How can I place a simple order (New App)?' },
            // { tit: 'Stocks', sub: 'What are the pricing and charges on Zebu for equity, currency and commodity segments?' },
            // { tit: 'Technical', sub: 'How do I add a New Bank Account to my Trading Account?' },
            // { tit: 'Mutual funds', sub: 'How can I place a simple order (New App)?' },
            // { tit: 'Payments', sub: 'What is the settlement cycle when you sell your positions and holdings?' },
            // { tit: 'Mutual funds', sub: 'How can I place a simple order (New App)?' },
            // { tit: 'Technical', sub: 'How can I open a new Trading and Demat account?' },
        ],
        sinfaq: [],
        page: 10,
        totalqst: null,
        singlequest: {},
        Searchitems: [],
        isLoading: null,
        searchis: null,
    }),
    mounted() {
        this.getKnowledge();
        this.getQuestion();
    },
    methods: {
        searchTo() {
            if (this.searchis) {
                var f = this.searchis;
                this.singleQuestion(f);
            } else {
                this.goBack();
            }
        },
        getKnowledge() {
            this.loader = true;

            let config = {
                method: 'get',
                url: `${strapi}/support-categories?populate=*`,
            };

            let axiosThis = this;
            axios.request(config)
                .then((response) => {
                    // console.log(JSON.stringify(response.data));
                    if (response.data.data) {
                        axiosThis.knowledge = response.data.data;
                    }
                    axiosThis.loader = false;
                })
                .catch((error) => {
                    console.log(error);
                });

        },
        getQuestion() {
            this.loader = true;
            let config = {
                method: 'get',
                url: `${strapi}/support-questions?pagination[page]=1&pagination[pageSize]=${this.page}&populate=*`,
            };

            let axiosThis = this;
            axios.request(config)
                .then((response) => {
                    // console.log(JSON.stringify(response.data));
                    if (response.data.data) {
                        axiosThis.faq = response.data.data;
                        axiosThis.totalqst = response.data.meta;
                        axiosThis.getSearch();
                    }
                    axiosThis.loader = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getSearch() {
            this.isLoading = true;
            let config = {
                method: 'get',
                url: `${strapi}/support-questions?pagination[page]=1&pagination[pageSize]=${this.totalqst.pagination.total}&populate=*`,
            };

            let axiosThis = this;
            axios.request(config)
                .then((response) => {
                    // console.log(JSON.stringify(response.data));
                    if (response.data.data) {
                        axiosThis.Searchitems = response.data.data;
                    }
                    axiosThis.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        moreFaq() {
            window.scrollTo(0, 0);
            this.loader = true;
            this.page = this.totalqst.pagination.total;
            this.getQuestion();
            this.loader = false;
        },
        singleQuestion(f) {
            this.singlequest = [];
            window.scrollTo(0, 0);
            this.loader = true;
            let config = {
                method: 'get',
                url: `${strapi}/support-questions/${f.id}`,
            };

            let axiosThis = this;
            axios.request(config)
                .then((response) => {
                    // console.log(JSON.stringify(response.data.data));
                    if (response.data.data) {
                        axiosThis.singlequest = response.data.data;
                        axiosThis.getCategorie(f = f.attributes.support_category.data.id);
                    }
                    axiosThis.loader = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getCategorie(f, q) {
            window.scrollTo(0, 0);
            this.loader = true;
            let cconfig = {
                method: 'get',
                url: `${strapi}/support-categories/${f}?populate=*`,
            };

            let axiosThis = this;
            axios.request(cconfig)
                .then((response) => {
                    // console.log(JSON.stringify(response.data.data));
                    if (response.data.data) {
                        axiosThis.sinfaq = response.data.data;
                        if (q == 't') {
                            axiosThis.singlequest = response.data.data.attributes.support_questions.data[0];
                        }
                        axiosThis.loader = false;
                        axiosThis.mainsrc = false;
                    }
                    // console.log("sinfaq",response.data.data.attributes.support_questions.data[0], axiosThis.sinfaq, axiosThis.singlequest);
                })
                .catch((error) => {
                    console.log(error);
                });

        },
        goBack() {
            this.mainsrc = true;
            window.scrollTo(0, 0);
            this.sinfaq = [];
            this.singlequest = [];
        }
    },

}
</script>