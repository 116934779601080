import axios from 'axios';

export let genuid;

export const sessapi = "https://sess.mynt.in/"
export const strapi = "https://sess.mynt.in/strapi/"
export const zebumyntapi = "https://zebu.mynt.in/"
export const imgicon = "https://besim.zebull.in/static/equity/icons/"
export const myntapi = 'https://go.mynt.in/NorenWClientTV/';
export const myntltp = 'https://asvr.mynt.in/bcast/';
export const bemynt = 'https://be.mynt.in/';
export const ekycbe = 'https://ekycbe.mynt.in/';

export const ipoplipodet = 'https://v3.mynt.in/ipo';
export const ipolpdatastore = 'https://zebu.mynt.in';




// export const accapi = 'http://192.168.5.142:5000/'
export const accapi = 'https://v3.mynt.in/equity/'


export let counts;

let countitems = JSON.parse(localStorage.getItem('countitem'));
let date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
if (countitems && countitems.d == date) {
    counts = countitems.v;
} else {
    let config = {
        method: 'post',
        url: `${sessapi}/datalen`,
    };
    axios.request(config)
        .then((response) => {
            if (response.data) {
                counts = response.data;
                let datas = { d: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), v: response.data }
                localStorage.setItem("countitem", JSON.stringify(datas));
            }
        })
        .catch(() => {
            counts = countitems.v ? countitems.v : '';
        });
}

if (sessapi) {
    let config = {
        method: 'post',
        url: `${sessapi}/getoken`,
    };
    axios.request(config)
        .then((response) => {
            if (response.data) {
                genuid = response.data
            }
        })
        .catch((error) => {
            console.log(error);
            genuid = '';
        });
}