<template>
  <div>
    <AppBar />
    <!-- overflow-y-auto h100vh cust-scroll -->
    <div>
      <v-container class="pa-0 px-0 pt-10 pt-md-14 ma-0 ma-lg-auto">
        <div class="body-part px-0 px-md-16 px-lg-0 pt-3">
          <router-view />
        </div>
        <v-expand-transition>
          <v-card v-show="appdialog" class="app-download  apps-box elevation-0">
            <v-icon @click="appdialog = false" class="pos-abs app-download-icon" color="#000">mdi-close-circle</v-icon>
            <v-card class="pt-4 pb-2 text-center rounded-lg" color="#fff" width="140px">
              <p class="fs-13 font-weight-bold lh-16 mb-1">Download MYNT + <br> Mobile app</p>
              <div class="py-1">
                <!-- <img width="70%" src="@/assets/apps/bannerS.png" alt="qrcode" class=""> -->

                <img width="70%" src="@/assets/qrcode.svg" alt="qrcode" class="">
              </div>
              <div class="d-inline-flex">
                <a href="https://play.google.com/store/apps/details?id=com.mynt.trading_app_zebu" target="_blank"
                  rel="noopener noreferrer">
                  <img src="@/assets/playstore.svg" alt="playstore" class="mr-1">
                </a>
                <a href="https://apps.apple.com/in/app/mynt/id6478270319" target="_blank" rel="noopener noreferrer">
                  <img src="@/assets/apple.svg" alt="apple" class="ml-1">
                </a>
              </div>
            </v-card>
          </v-card>
        </v-expand-transition>
        <v-expand-transition>
          <v-card v-show="banndialog" class="elevation-0">
            <div class="webbanner d-none d-sm-block">
              <v-icon @click="setBannerPop()" class="pos-abs webbanner-icon">mdi-close</v-icon>
              <v-carousel cycle hide-delimiters :show-arrows="false">
                <v-carousel-item v-for="(j, i) in bannerdata" :key="i">
                  <a :href="j.url" target="_blank">
                    <img :src="j.img" :alt="j.tit" class="rounded-lg" width="100%"></a>
                </v-carousel-item>
              </v-carousel>
            </div>
          </v-card>
        </v-expand-transition>
      </v-container>
      <div class="sec-footer">
        <FooterSet />
      </div>
    </div>
  </div>
</template>

<script>
import AppBar from "../components/AppbarSet.vue";
import FooterSet from '../components/FooterSet.vue';

import axios from 'axios'
import { strapi } from '../apiurl.js';

export default {
  data: () => ({
    appdialog: false,
    banndialog: false,
    bannerdata: [],
  }),

  created() {
    let b = localStorage.getItem('banner')
    if (b != new Date().getHours()) {
      // this.getBannerData();
    }
    this.myOnLine();
  },
  mounted() {
    var styleElement = document.createElement("style");
    styleElement.appendChild(document.createTextNode("::-webkit-scrollbar {width: 8px; height:6px} *, .v-application, .v-application .body-1, .v-application .body-2, .v-application .caption, .v-application .display-1, .v-application .headline, .v-application .overline, .v-application .subtitle-1, .v-application .subtitle-2, .v-application .title, body, html { font-family: Tenon !important; }"));
    document.getElementsByTagName("head")[0].appendChild(styleElement);
    //       window.addEventListener('scroll', () => scrollY)
    //       if (scrollY > 100) {
    //           this.appclass = 1;
    //       } else {
    //           this.appclass = 1;
    //       }

    setTimeout(() => {
      this.appdialog = true;
    }, 3000);
  },
  methods: {
    getBannerData() {
      let config = {
        method: 'get',
        url: `${strapi}/website-banner?populate=*`,
        headers: {}
      };

      let axiosThis = this;
      axios.request(config)
        .then((res) => {
          if (res.data && res.data.data && res.data.data.length > 0) {
            var bann = res.data.data
            for (let d = 0; d < bann.length; d++) {
              let b = bann[d].attributes;
              let i = strapi + bann[d].attributes.b_img.data.attributes.url;
              axiosThis.bannerdata.push({ img: i, url: b.url ? b.url : i, tit: b.title })
            }
            setTimeout(() => {
              axiosThis.banndialog = true;
            }, 8000);
          } else {
            axiosThis.banndialog = false;
            axiosThis.bannerdata = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setBannerPop() {
      localStorage.setItem('banner', new Date().getHours())
      this.banndialog = false
    },
    myOnLine() {
      if (navigator.onLine) {
        // console.log("Connected to internet.");
      } else {
        // console.log("Unable to connect to internet.");
      }
    },
  },
  components: {
    FooterSet,
    AppBar
  }
}
</script>
<style>
@import '../assets/style/style.css';
</style>
