<template>
    <div>
        <div>
            <div class="my-6 my-md-16">
                <v-toolbar dense class="elevation-0 mb-6 d-none d-md-block">
                    <div>
                        <p class="mb-0 font-weight-bold secondary-infont fs-32 lh-32">All playlists</p>
                        <p class="mb-0 title font-weight-regular">Categorised list of all the videos we've made.</p>
                    </div>
                    <v-spacer></v-spacer>
                </v-toolbar>
                
                <v-toolbar dense class="elevation-0 mb-6 d-md-none">
                    <div class="pl-2 pl-sm-4">
                        <p class="mb-0 font-weight-bold secondary-infont fs-28">All playlists</p>
                        <p class="mb-0 fs-16 font-weight-regular">Categorised list of all the videos we've made.</p>
                    </div>
                </v-toolbar>

                <div class="pl-4">
                    <v-row v-if="allplaylist.length > 0" no-glutters class="pt-2 pt-md-6">
                        <v-col v-for="(v, l) in allplaylist" :key="l" cols="12" sm="6" md="4" lg="3" class="pb-0 pb-md-3">
                            <v-card data-aos="zoom-in-down" @click="playPlaylist(v)" width="100%" class="elevation-0 hcard pl-2 pl-sm-4 pr-6 pr-sm-8 px-md-0"
                                :class="l != allplaylist.length - 1 ? 'mr-md-4' : 'mr-md-0'">
                                <div class="pos-rlt">
                                    <v-card class="rounded-lg elevation-0" color="transparent">
                                        <img :src="v.thumbnailsURL" width="100%" :alt="v.thumbnailsURL">
                                    </v-card>
                                    <v-card color="#ffffff" class="elevation-0 rounded-md py-1 px-3 pos-abs abs-post3">
                                        <span class="font-weight-black subtitle-2">{{ v.videoCount }} VIDEOS</span>
                                    </v-card>
                                </div>
                                <v-list-item data-aos="fade-left" class="px-0">
                                    <v-list-item-content class="pt-1">
                                        <v-list-item-title class="font-weight-bold subtitle-1 text-rap-l2">{{ v.playlistName
                                        }}</v-list-item-title>
                                        <p class="mb-0 font-weight-regular subtitle-2">Updated {{ v.ago }}</p>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card>
                        </v-col>
                    </v-row>
                    <div v-else>
                        <v-container fill-height>
                            <v-card class="elevation-0 mx-auto my-16">
                                <v-progress-circular size="80" indeterminate color="#1e53e5"></v-progress-circular>
                            </v-card>
                        </v-container>
                    </div>
                    <v-container class="mt-6 mt-md-10">
                        <v-card v-if="pagecount" data-aos="fade-up" max-width="600px"
                            class="box-s1 rounded-xl mx-auto py-1">
                            <v-pagination @input="getPlaylist()" color="#000" prev-icon="mdi-arrow-left"
                                next-icon="mdi-arrow-right" v-model="page" class="my-3 elevation-0" :total-visible="11"
                                :length="pagecount"></v-pagination>
                        </v-card>
                    </v-container>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { sessapi } from '../../apiurl'

export default {
    data: () => ({
        page: 1,
        pagecount: 0,
        allplaylist: [],
    }),
    mounted() {
        this.getPlaylist();
        this.scrollBehavior();
    },
    methods: {
        getPlaylist() {
            this.allplaylist = [];
            let config = {
                method: 'get',
                // url: `${sessapi}/getallplaylist`,
                url: `${sessapi}/getallplaylist?pagesize=24&pagecount=${this.page}`,
                // url: `http://192.168.5.70:2001/getallplaylist`,
            };

            let axiosThis = this;
            axios.request(config)
                .then((response) => {
                    if (response.data.data) {
                        let data = response.data.data
                        for (let v = 0; v < data.length; v++) {
                            data[v]['isdate'] = `${new Date(data[v].date).toDateString().slice(3)} ${new Date(data[v].date).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} IST`;
                            data[v]['ago'] = axiosThis.differentDate(data[v].date);
                            axiosThis.allplaylist.push(data[v]);
                        }
                        axiosThis.pagecount = response.data.TotalPages;
                        axiosThis.scrollBehavior();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

        },
        differentView(view) {
            return `${view > 999 ? Math.round(view / 1000) : view > 999999 ? Math.round(view / 1000000) : view > 999999999 ? Math.round(view / 1000000000) : view} ${view > 999 ? 'K' : view > 999999 ? 'M' : view > 999999999 ? 'B' : ''}`
        },
        differentDate(date) {
            var date1 = new Date(date);
            var date2 = new Date();
            var ov = Math.abs(date2.getTime() - date1.getTime())
            var mt = Math.round(ov / 3600000)
            var dd = mt > 60 ? Math.round(mt / 24) : 0;
            var mm = dd > 30.4166667 ? Math.round(dd / 30.4166667) : 0;
            var yy = mm > 12 ? Math.round(dd / 365) : 0;
            return `${yy != 0 ? yy : mm != 0 ? mm : dd != 0 ? dd : mt != 0 ? mt : 0} ${yy != 0 ? yy > 1 ? 'years' : 'year' : mm != 0 ? mm > 1 ? 'months' : 'month' : dd != 0 ? dd > 1 ? 'days' : 'day' : mt > 1 ? 'hours' : 'minutes'} ago`;
        },
        scrollBehavior() {
            window.scrollTo(0, 0);
        },
        playPlaylist(v) {
            this.$router.push({ name: 'playlist', params: v });
        }
    }
}
</script>