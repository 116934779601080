<template>
    <div>
        <div data-aos="fade-up">
            <img src="@/assets/product-hero-bg.svg" width="100%" alt="product-hero-bg" class="d-none d-md-flex">
            <img src="@/assets/mproduct-hero-bg.svg" width="100%" alt="product-hero-bg" class="d-md-none">
        </div>
        <div class="pt-12 px-16 d-none d-md-block">
            <p class="mb-0 secondary-font font-weight-medium fs-28">Great results require</p>
            <p class="mb-4 font-weight-bold secondary-font">Great products</p>
            <p class="mb-5 title font-weight-regulars">We started off with the aim of filling the technological and
                service
                gap <br> that hindered
                traders' and investors' growth to increased returns.</p>
            <div class="d-inline-flex">
                <v-btn data-aos="fade-up" @click="act = p" v-for="(p, b) in products" :key="b" medium
                    :color="act == p ? '#000000' : '#666666'" class="rounded-pill mr-2 text-none brd-2" outlined>
                    <span class="font-weight-black fs-17">{{ p }}</span>
                </v-btn>

            </div>
        </div>
        <div class="pt-6 px-6 px-sm-8 d-md-none">
            <p class="mb-3 secondary-fn fs-32 font-weight-bold lh-32">Great products</p>
            <p class="mb-5 fs-18 font-weight-regulars">We started off with the aim of filling the technological and
                service
                gap <br> that hindered
                traders' and investors' growth to increased returns.</p>
            <div>
                <v-btn data-aos="fade-up" @click="act = p" v-for="(p, b) in products" :key="b" medium
                    :color="act == p ? '#000000' : '#666666'" class="rounded-pill mr-2 text-none brd-2 mb-4" outlined>
                    <span class="font-weight-black fs-17">{{ p }}</span>
                </v-btn>

            </div>
        </div>
        <div class="pt-16 mt-6 d-none d-md-flex">
            <v-row data-aos="fade-up" class="px-16" no-gutters>
                <v-col cols="12" md="6" class="mt-16">
                    <div>
                        <p class="mb-4 font-weight-black fs-40" v-html="act == 'Mynt' ? 'Mynt'
                    : act == 'Mynt API' ? 'Mynt API' : act == 'Mynt Tradingview' ? 'Mynt Tradingview'
                        : null
                    "></p>
                        <p class="font-weight-bold mt-4 title mb-10 lh-28">
                            {{ act == 'Mynt' ?
                    "Mynt is an elegant, state of the art, hybrid mobile application"
                    : act == 'Mynt API' ? "Power Your Trading with our propriety API Technology"
                        : act == 'Mynt Tradingview' ?
                            "Run your whole trading strategy from your favourite charting tool."
                            : null }}
                        </p>
                        <p class="txt-444 font-weight-light title lh-28">
                            <span v-if="act == 'Mynt'">MYNT Mobile is an elegant, state of the art,
                                hybrid mobile application with a host of features. Working in tandem with other
                                applications
                                in Zebu, this mobile app let the traders and investors go about their trading with
                                ease.</span>

                            <span v-if="act == 'Mynt API'">Introducing our Trading API, a powerful tool that eradicates
                                the
                                potential for execution errors and erases the emotional rollercoaster often associated
                                with
                                human trading. Say goodbye to missed opportunities and impulsive decisions. Embrace a
                                new
                                era of trading precision and rationality as you navigate the markets. </span>
                            <span v-if="act == 'Mynt Tradingview'">Charts that shape markets, spanning stock prices to
                                intricate pattern analysis, all in one place. With 15+ chart types, up to 16 charts per
                                tab, command search, and spreads, we've got your trading needs covered. Trusted by 50
                                million traders worldwide. </span>

                        </p>
                    </div>
                </v-col>
                <v-col cols="12" md="6" v-if="act == 'Mynt'" class="pl-12 z-i1">
                    <div>
                        <img src="@/assets/trade/trd1.svg" height="420px" alt="sec-six-img">
                    </div>
                </v-col>
                <v-col cols="12" md="6" v-if="act == 'Mynt API'" class="pr-16 z-i1">
                    <div class="pos-abs btm-0 rig-0">
                        <v-card color="transparent" class="elevation-0 rounded-xl" width="100%">
                            <div class="d-inline-flex py-2">
                                <v-btn @click="apic = 'curl'" text :ripple="false" class="mr-2 text-none"><span
                                        class="font-weight-medium"
                                        :class="apic == 'curl' ? 'prd-btn-active' : 'prd-btn-noactive'">cURL</span></v-btn>
                                <v-btn @click="apic = 'php'" text :ripple="false" class="mx-2 text-none"><span
                                        class="font-weight-medium"
                                        :class="apic == 'php' ? 'prd-btn-active' : 'prd-btn-noactive'">PHP</span></v-btn>
                                <v-btn @click="apic = 'js'" text :ripple="false" class="mx-2 text-none"><span
                                        class="font-weight-medium"
                                        :class="apic == 'js' ? 'prd-btn-active' : 'prd-btn-noactive'">Javascript</span></v-btn>
                                <v-btn @click="apic = 'ru'" text :ripple="false" class="mx-2 text-none"><span
                                        class="font-weight-medium"
                                        :class="apic == 'ru' ? 'prd-btn-active' : 'prd-btn-noactive'">Rust</span></v-btn>
                                <v-btn @click="apic = 'go'" text :ripple="false" class="mx-2 text-none"><span
                                        class="font-weight-medium"
                                        :class="apic == 'go' ? 'prd-btn-active' : 'prd-btn-noactive'">Golang</span></v-btn>
                            </div>
                        </v-card>
                        <img v-if="apic == 'curl'" src="@/assets/myntapi/curl.svg" width="90%" alt="sec-six-img">
                        <img v-if="apic == 'php'" src="@/assets/myntapi/php.svg" width="90%" alt="sec-six-img">
                        <img v-if="apic == 'js'" src="@/assets/myntapi/javascript.svg" width="90%" alt="sec-six-img">
                        <img v-if="apic == 'ru'" src="@/assets/myntapi/rust.svg" width="90%" alt="sec-six-img">
                        <img v-if="apic == 'go'" src="@/assets/myntapi/golang.svg" width="90%" alt="sec-six-img">

                    </div>
                </v-col>
                <v-col cols="12" md="6" v-if="act == 'Mynt Tradingview'" class="pos-rlt pr-16 z-i1">
                    <div class="pos-abs btm-0 rig-0">
                        <v-card color="#F1F3F8" class="elevation-0 rounded-xl mb-4" width="100%">
                            <div class="d-inline-flex py-2 px-6">
                                <img src="@/assets/product/tv-logo.png" alt="tv-logo" width="24px" height="18px"
                                    class="mt-1">
                                <p class="mb-0 black--text ml-4 font-weight-medium">https://tv.mynt.in/</p>
                            </div>
                        </v-card>
                        <img src="@/assets/product/sec-tv.png" width="100%" alt="sec-six-img">
                    </div>
                </v-col>
            </v-row>
        </div>
        <div class="pt-6 d-md-none">
            <div class="px-6 px-sm-8 pb-6">
                <p class="font-weight-black fs-28" v-html="act == 'Mynt' ? 'Mynt'
                    : act == 'Mynt API' ? 'Mynt API' : act == 'Mynt Tradingview' ? 'Mynt Tradingview'
                        : null
                    "></p>
                <p class="font-weight-bold fs-18 lh-28">
                    {{ act == 'Mynt' ?
                    "Mynt is an elegant, state of the art, hybrid mobile application"
                    : act == 'Mynt API' ? "Power Your Trading with our propriety API Technology"
                        : act == 'Mynt Tradingview' ?
                            "Run your whole trading strategy from your favourite charting tool."
                            : null }}
                </p>
                <p class="txt-444 font-weight-light fs-18 lh-28">
                    <span v-if="act == 'Mynt'">MYNT Mobile is an elegant, state of the art,
                        hybrid mobile application with a host of features. Working in tandem with other applications
                        in Zebu, this mobile app let the traders and investors go about their trading with
                        ease.</span>

                    <span v-if="act == 'Mynt API'">Introducing our Trading API, a powerful tool that eradicates the
                        potential for execution errors and erases the emotional rollercoaster often associated with
                        human trading. Say goodbye to missed opportunities and impulsive decisions. Embrace a new
                        era of trading precision and rationality as you navigate the markets. </span>
                    <span v-if="act == 'Mynt Tradingview'">Charts that shape markets, spanning stock prices to intricate
                        pattern analysis, all in one place. With 15+ chart types, up to 16 charts per tab, command
                        search, and spreads, we've got your trading needs covered. Trusted by 50 million
                        traders worldwide.</span>

                </p>
            </div>
            <div v-if="act == 'Mynt'" class="pos-rlt z-i1 px-6 px-sm-8">
                <img src="@/assets/trade/trd1.svg" width="100%" alt="sec-six-img">
            </div>
            <div v-if="act == 'Mynt API'" class="pos-rlt z-i1 px-6 px-sm-8">
                <v-card color="transparent" class="elevation-0 rounded-xl d-inline-flex py-2 overflow-x-auto"
                    width="100%">
                    <v-btn @click="apic = 'curl'" text :ripple="false" class="mr-2 text-none"><span
                            class="font-weight-medium"
                            :class="apic == 'curl' ? 'prd-btn-active' : 'prd-btn-noactive'">cURL</span></v-btn>
                    <v-btn @click="apic = 'php'" text :ripple="false" class="mx-2 text-none"><span
                            class="font-weight-medium"
                            :class="apic == 'php' ? 'prd-btn-active' : 'prd-btn-noactive'">PHP</span></v-btn>
                    <v-btn @click="apic = 'js'" text :ripple="false" class="mx-2 text-none"><span
                            class="font-weight-medium"
                            :class="apic == 'js' ? 'prd-btn-active' : 'prd-btn-noactive'">Javascript</span></v-btn>
                    <v-btn @click="apic = 'ru'" text :ripple="false" class="mx-2 text-none"><span
                            class="font-weight-medium"
                            :class="apic == 'ru' ? 'prd-btn-active' : 'prd-btn-noactive'">Rust</span></v-btn>
                    <v-btn @click="apic = 'go'" text :ripple="false" class="mx-2 text-none"><span
                            class="font-weight-medium"
                            :class="apic == 'go' ? 'prd-btn-active' : 'prd-btn-noactive'">Golang</span></v-btn>
                </v-card>
                <img src="@/assets/product/sec-api3.png" width="100%" alt="sec-six-img">
            </div>
            <div v-if="act == 'Mynt Tradingview'" class="pos-rlt z-i1 px-6 px-sm-8">
                <v-card color="#F1F3F8" class="elevation-0 rounded-xl mb-4" width="100%">
                    <div class="d-inline-flex py-2 px-6">
                        <img src="@/assets/product/tv-logo.png" alt="tv-logo" width="24px" height="18px" class="mt-1">
                        <p class="mb-0 black--text ml-4 font-weight-medium">https://tv.mynt.in/</p>
                    </div>
                </v-card>
                <img src="@/assets/product/sec-tv.png" width="100%" alt="sec-six-img">
            </div>
        </div>
        <div class="pt-4 px-16 d-none d-md-flex">
            <v-card data-aos="fade-down" width="100%" color="#E5EBEC"
                class="elevation-0 rounded-xl px-8 py-2 d-inline-flex align-center">
                <p class="font-weight-bold title mb-0">
                    {{ act == 'Mynt' ? 'Download the App Now!' : act == 'Mynt API' ? 'Checkout the API now.' : act ==
                    'Mynt Tradingview' ? 'Visit Tradingview on Mynt'
                    : null }}</p>
                <v-spacer></v-spacer>
                <div v-if="act == 'Mynt'" class="rounded-0 d-inline-flex">
                    <v-card target="_blank" rel="noopener noreferrer" height="45px"
                        href="https://play.google.com/store/apps/details?id=com.zebu.mynt" color="#000"
                        class="mr-2 rounded-lg hcardd elevation-0">
                        <img src="@/assets/product/scr-mob1.svg" class="px-1 pt-1" alt="playstore-btn-img">
                    </v-card>
                    <v-card target="_blank" rel="noopener noreferrer" height="45px"
                        href="https://apps.apple.com/in/app/mynt-zebu/id1663709767" color="#000"
                        class="mr-2 rounded-lg hcardd elevation-0">
                        <img src="@/assets/product/scr-mob2.svg" class="px-1 pt-1" alt="playstore-btn-img">
                    </v-card>
                    <v-card target="_" rel="noopener noreferrer" height="45px"
                        href="https://zebu.mynt.in/static/Downloads/Apps/ZEBU_INV.exe" color="#000"
                        class="rounded-lg hcardd elevation-0">
                        <img src="@/assets/product/scr-mob3.svg" class="px-1 pt-1 " alt="web-btn-img">
                    </v-card>
                </div>
                <div v-if="act == 'Mynt API'" class="hcard">
                    <a target="_blank" rel="noopener noreferrer" href="https://zebumyntapi.web.app/">
                        <img src="@/assets/product/scr-api.png" alt="download-btn-img"></a>
                </div>
                <div v-if="act == 'Mynt Tradingview'" class="d-inline-flex ">
                    <a target="_blank" rel="noopener noreferrer" class="hcard" href="https://tv.mynt.in/">
                        <img src="@/assets/product/scr-tv1.png" class="mr-2" alt="tv1-btn-img"></a>
                    <a target="_blank" rel="noopener noreferrer" class="hcard" href="https://tv.mynt.in/">
                        <img src="@/assets/product/scr-tv2.png" alt="tv2-btn-img"></a>
                </div>
            </v-card>
        </div>
        <div class="d-md-none mt-n10">
            <v-card data-aos="fade-down" width="100%" color="#E5EBEC"
                class="elevation-0 rounded-0 px-6 px-sm-8 pt-14 pb-8 text-center">
                <p class="font-weight-bold title">
                    {{ act == 'Mynt' ? 'Download the App Now!' : act == 'Mynt API' ? 'Checkout the API now.' : act ==
                    'Mynt Tradingview' ? 'Visit Tradingview on Mynt'
                    : null }}</p>

                <div v-if="act == 'Mynt'" class="rounded-0 d-sm-inline-flex">
                    <v-card target="_blank" rel="noopener noreferrer" height="45px"
                        href="https://play.google.com/store/apps/details?id=com.zebu.mynt" color="#000"
                        class="mr-sm-2 mb-3 mb-sm-0 rounded-lg elevation-0">
                        <img src="@/assets/product/scr-mob1.svg" class="px-1 hcardd pt-1" alt="playstore-btn-img">
                    </v-card>
                    <v-card target="_blank" rel="noopener noreferrer" height="45px"
                        href="https://apps.apple.com/in/app/mynt-zebu/id1663709767" color="#000"
                        class="mr-sm-2 mb-3 mb-sm-0 rounded-lg elevation-0">
                        <img src="@/assets/product/scr-mob2.svg" class="px-1 hcardd pt-1" alt="playstore-btn-img">
                    </v-card>
                    <v-card target="_blank" rel="noopener noreferrer" height="45px"
                        href="https://zebu.mynt.in/static/Downloads/Apps/ZEBU_INV.exe" color="#000"
                        class="mb-3 mb-sm-0 rounded-lg elevation-0">
                        <img src="@/assets/product/scr-mob3.svg" class="px-1 hcardd pt-1" alt="web-btn-img">
                    </v-card>
                </div>
                <div v-if="act == 'Mynt API'">
                    <a target="_blank" rel="noopener noreferrer" href="https://zebumyntapi.web.app/">
                        <img src="@/assets/product/scr-api.png" alt="download-btn-img"></a>
                </div>
                <div v-if="act == 'Mynt Tradingview'" class="d-inline-flex">
                    <a target="_blank" rel="noopener noreferrer" href="https://tv.mynt.in/">
                        <img src="@/assets/product/scr-tv1.png" class="mr-2" alt="tv1-btn-img"></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://tv.mynt.in/">
                        <img src="@/assets/product/scr-tv2.png" alt="tv2-btn-img"></a>
                </div>
            </v-card>
        </div>
        <div class="pa-16 mb-16 d-none d-md-flex">
            <v-card width="100%" data-aos="fade-down" class="price-card-sty elevation-0 rounded-xl pt-16 pb-10">
                <div class="pos-rlt">
                    <v-row no-glutters>
                        <v-col cols="12" md="6">
                            <div class="px-16">
                                <p class="subtitle-2 text-uppercase ">Features</p>
                                <p class="secondary-font mb-8">
                                    <span v-if="act == 'Mynt'">State of the art,<br> hybrid trading <br>
                                        application</span>
                                    <span v-if="act == 'Mynt API'">Our API offers a<br> smarter trading<br>
                                        experience</span>
                                    <span v-if="act == 'Mynt Tradingview'">Smart tools.<br> Seamless<br> trading</span>


                                </p>
                                <v-card v-if="act == 'Mynt'" data-aos="fade-down" width="100%"
                                    class="box-s1 brd-r-16 py-3 px-6 mb-4">
                                    <p class="mb-0 title font-weight-regular">App rating <span
                                            class="float-right font-weight-bold">4.5 <v-icon size="20"
                                                color="#2A2A2A">mdi-star</v-icon></span></p>
                                </v-card>
                                <v-card v-if="act == 'Mynt'" data-aos="fade-down" width="100%"
                                    class="box-s1 brd-r-16 py-3 px-6">
                                    <p class="mb-0 title font-weight-regular">Downloads <span
                                            class="float-right font-weight-bold">100,000+</span></p>
                                </v-card>

                                <v-card v-if="act == 'Mynt API'" data-aos="fade-down" width="100%"
                                    class="box-s1 brd-r-16 py-3 px-6 mb-4">
                                    <p class="mb-0 title font-weight-regular">API End points <span
                                            class="float-right font-weight-bold">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                viewBox="0 0 16 16" fill="none">
                                                <g clip-path="url(#clip0_1273_3572)">
                                                    <path
                                                        d="M12.6489 8.90042L13.8619 7.63042C14.4638 7.02512 14.8488 6.23801 14.9573 5.39135C15.0658 4.5447 14.8917 3.68591 14.4619 2.94842L15.7029 1.70742C15.8044 1.6169 15.8865 1.50662 15.9439 1.38332C16.0014 1.26002 16.0331 1.12629 16.0371 0.99032C16.0411 0.854348 16.0173 0.71899 15.9672 0.592528C15.917 0.466065 15.8417 0.351157 15.7456 0.254833C15.6496 0.158509 15.5349 0.0827943 15.4085 0.0323213C15.2822 -0.0181516 15.1469 -0.0423216 15.0109 -0.03871C14.875 -0.0350983 14.7411 -0.00378095 14.6177 0.0533262C14.4942 0.110433 14.3837 0.192129 14.2929 0.293416L13.0519 1.53442C12.3144 1.10465 11.4556 0.930489 10.609 1.03899C9.76232 1.14749 8.9752 1.53257 8.36991 2.13442L7.12891 3.37942L12.6489 8.90042Z"
                                                        fill="#212121" />
                                                    <path
                                                        d="M9.53057 9.52875L8.47057 8.46875L6.60057 10.3438L5.66057 9.40375L7.53057 7.52875L6.47057 6.46875L4.60057 8.34375L3.35157 7.09875L2.13857 8.36875C1.53673 8.97404 1.15164 9.76116 1.04314 10.6078C0.934642 11.4545 1.1088 12.3133 1.53857 13.0508L0.293569 14.2918C0.198059 14.384 0.121877 14.4943 0.0694681 14.6163C0.0170591 14.7383 -0.0105272 14.8696 -0.011681 15.0023C-0.0128348 15.1351 0.0124669 15.2668 0.0627478 15.3897C0.113029 15.5126 0.187282 15.6243 0.281174 15.7181C0.375067 15.812 0.486719 15.8863 0.609615 15.9366C0.732512 15.9869 0.864191 16.0122 0.996971 16.011C1.12975 16.0098 1.26097 15.9823 1.38297 15.9299C1.50498 15.8774 1.61532 15.8013 1.70757 15.7057L2.94857 14.4647C3.68606 14.8945 4.54485 15.0687 5.3915 14.9602C6.23816 14.8517 7.02528 14.4666 7.63057 13.8648L8.87157 12.6238L7.65557 11.4037L9.53057 9.52875Z"
                                                        fill="#212121" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1273_3572">
                                                        <rect width="16" height="16" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            89 </span></p>
                                </v-card>
                                <v-card v-if="act == 'Mynt API'" data-aos="fade-down" width="100%"
                                    class="box-s1 rounded-xl py-3 px-6">
                                    <p class="mb-0 title font-weight-regular">Requests/sec <span
                                            class="float-right font-weight-bold">50,000+</span></p>
                                </v-card>

                                <v-card v-if="act == 'Mynt Tradingview'" data-aos="fade-down" width="100%"
                                    class="box-s1 brd-r-16 py-3 px-6 mb-4">
                                    <p class="mb-0 title font-weight-regular">Indicators<span
                                            class="float-right font-weight-bold">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                viewBox="0 0 16 16" fill="none">
                                                <g clip-path="url(#clip0_1273_3902)">
                                                    <path
                                                        d="M12.5 8.5C14.433 8.5 16 6.933 16 5C16 3.067 14.433 1.5 12.5 1.5C10.567 1.5 9 3.067 9 5C9 6.933 10.567 8.5 12.5 8.5Z"
                                                        fill="#212121" />
                                                    <path
                                                        d="M9 9H4C3.72386 9 3.5 9.22386 3.5 9.5V14.5C3.5 14.7761 3.72386 15 4 15H9C9.27614 15 9.5 14.7761 9.5 14.5V9.5C9.5 9.22386 9.27614 9 9 9Z"
                                                        fill="#212121" />
                                                    <path
                                                        d="M7.75 6.99979H0.250001C0.205374 6.99979 0.161561 6.98785 0.123111 6.9652C0.0846599 6.94255 0.0529729 6.91002 0.0313395 6.87099C0.00970603 6.83195 -0.00108519 6.78784 8.61525e-05 6.74323C0.00125749 6.69862 0.0143487 6.65514 0.0380006 6.61729L3.788 0.617293C3.81203 0.583446 3.84382 0.555845 3.8807 0.536798C3.91758 0.517752 3.95849 0.507812 4 0.507812C4.04151 0.507812 4.08242 0.517752 4.1193 0.536798C4.15618 0.555845 4.18797 0.583446 4.212 0.617293L7.962 6.61729C7.98565 6.65514 7.99874 6.69862 7.99992 6.74323C8.00109 6.78784 7.9903 6.83195 7.96866 6.87099C7.94703 6.91002 7.91534 6.94255 7.87689 6.9652C7.83844 6.98785 7.79463 6.99979 7.75 6.99979Z"
                                                        fill="#212121" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1273_3902">
                                                        <rect width="16" height="16" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            100+</span></p>
                                </v-card>
                                <v-card v-if="act == 'Mynt Tradingview'" data-aos="fade-down" width="100%"
                                    class="box-s1 brd-r-16 py-3 px-6 mb-4">
                                    <p class="mb-0 title font-weight-regular">Chart types<span
                                            class="float-right font-weight-bold">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                viewBox="0 0 16 16" fill="none">
                                                <g clip-path="url(#clip0_1273_3911)">
                                                    <path
                                                        d="M15 16H1C0.4 16 0 15.6 0 15V1C0 0.4 0.4 0 1 0H15C15.6 0 16 0.4 16 1V15C16 15.6 15.6 16 15 16ZM2 14H14V2H2V14Z"
                                                        fill="#212121" />
                                                    <path
                                                        d="M3.39982 10.7984C2.99982 10.4984 2.89982 9.79844 3.19982 9.39844L6.19982 5.39844C6.59982 4.89844 7.29982 4.89844 7.69982 5.29844L9.79982 7.39844L11.0998 5.39844C11.3998 4.89844 11.9998 4.79844 12.4998 5.09844C12.9998 5.39844 13.0998 5.99844 12.7998 6.49844L10.7998 9.49844C10.4998 9.99844 9.69982 10.0984 9.29982 9.69844L7.09982 7.49844L4.79982 10.5984C4.49982 10.9984 3.79982 11.0984 3.39982 10.7984Z"
                                                        fill="#212121" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1273_3911">
                                                        <rect width="16" height="16" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            10+</span></p>
                                </v-card>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <div class="pr-16 pt-4">
                                <v-list-item data-aos="fade-left"
                                    v-for="(item, k) in act == 'Mynt' ? product_mob : act == 'Mynt API' ? product_api : product_tv "
                                    :key="k">
                                    <v-list-item-avatar tile size="32">
                                        <img :src="item.img" :alt="item.img" />
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <p class="pt-2 font-weight-light title lh-24">{{ item.txt }}</p>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                        </v-col>
                    </v-row>
                </div>
                <div class="feat-bg-img pos-abs">
                    <img src="@/assets/product/feat-bg.svg" alt="feat-bg">
                </div>
            </v-card>
        </div>
        <div class="d-md-none">
            <v-card width="100%" data-aos="fade-down" class="price-card-sty elevation-0 rounded-0 pt-10 pb-6">
                <div>
                    <div class="px-6 px-sm-8">
                        <p class="subtitle-2 text-uppercase ">Features</p>
                        <p class="secondary-fn fs-32 font-weight-regular lh-32 mb-8">{{ act == 'Mynt'
                    ? "State of the art, hybrid trading application"
                    : act == 'Mynt API' ? "Our API offers a smarter trading experience"
                        : "Smart tools. Seamless trading" }}
                        </p>
                        <v-row no-glutters v-if="act == 'Mynt'">
                            <v-col cols="6">
                                <v-card data-aos="fade-down" width="100%" class="box-s1 brd-r-16 py-3 px-4">
                                    <p class="mb-0 title font-weight-regular"><span class="font-weight-bold">4.5 <v-icon
                                                size="20" color="#2A2A2A">mdi-star</v-icon></span><br> App rating </p>
                                </v-card>
                            </v-col>
                            <v-col cols="6">

                                <v-card data-aos="fade-down" width="100%" class="box-s1 brd-r-16 py-3 px-4">
                                    <p class="mb-0 title font-weight-regular"><span
                                            class="font-weight-bold">100,000+</span>
                                        <br>
                                        Downloads
                                    </p>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row no-glutters v-if="act == 'Mynt API'">
                            <v-col cols="6">
                                <v-card data-aos="fade-down" width="100%" class="box-s1 brd-r-16 py-3 px-4">
                                    <p class="mb-0 title font-weight-regular"><span class="font-weight-bold">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                viewBox="0 0 16 16" fill="none">
                                                <g clip-path="url(#clip0_1273_3572)">
                                                    <path
                                                        d="M12.6489 8.90042L13.8619 7.63042C14.4638 7.02512 14.8488 6.23801 14.9573 5.39135C15.0658 4.5447 14.8917 3.68591 14.4619 2.94842L15.7029 1.70742C15.8044 1.6169 15.8865 1.50662 15.9439 1.38332C16.0014 1.26002 16.0331 1.12629 16.0371 0.99032C16.0411 0.854348 16.0173 0.71899 15.9672 0.592528C15.917 0.466065 15.8417 0.351157 15.7456 0.254833C15.6496 0.158509 15.5349 0.0827943 15.4085 0.0323213C15.2822 -0.0181516 15.1469 -0.0423216 15.0109 -0.03871C14.875 -0.0350983 14.7411 -0.00378095 14.6177 0.0533262C14.4942 0.110433 14.3837 0.192129 14.2929 0.293416L13.0519 1.53442C12.3144 1.10465 11.4556 0.930489 10.609 1.03899C9.76232 1.14749 8.9752 1.53257 8.36991 2.13442L7.12891 3.37942L12.6489 8.90042Z"
                                                        fill="#212121" />
                                                    <path
                                                        d="M9.53057 9.52875L8.47057 8.46875L6.60057 10.3438L5.66057 9.40375L7.53057 7.52875L6.47057 6.46875L4.60057 8.34375L3.35157 7.09875L2.13857 8.36875C1.53673 8.97404 1.15164 9.76116 1.04314 10.6078C0.934642 11.4545 1.1088 12.3133 1.53857 13.0508L0.293569 14.2918C0.198059 14.384 0.121877 14.4943 0.0694681 14.6163C0.0170591 14.7383 -0.0105272 14.8696 -0.011681 15.0023C-0.0128348 15.1351 0.0124669 15.2668 0.0627478 15.3897C0.113029 15.5126 0.187282 15.6243 0.281174 15.7181C0.375067 15.812 0.486719 15.8863 0.609615 15.9366C0.732512 15.9869 0.864191 16.0122 0.996971 16.011C1.12975 16.0098 1.26097 15.9823 1.38297 15.9299C1.50498 15.8774 1.61532 15.8013 1.70757 15.7057L2.94857 14.4647C3.68606 14.8945 4.54485 15.0687 5.3915 14.9602C6.23816 14.8517 7.02528 14.4666 7.63057 13.8648L8.87157 12.6238L7.65557 11.4037L9.53057 9.52875Z"
                                                        fill="#212121" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1273_3572">
                                                        <rect width="16" height="16" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            89 </span> <br> API End points </p>
                                </v-card>
                            </v-col>
                            <v-col cols="6">
                                <v-card data-aos="fade-down" width="100%" class="box-s1 brd-r-16 py-3 px-4">
                                    <p class="mb-0 title font-weight-regular"><span
                                            class="font-weight-bold">50,000+</span>
                                        <br>
                                        Requests/sec
                                    </p>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-row no-glutters v-if="act == 'Mynt Tradingview'">
                            <v-col cols="6">
                                <v-card data-aos="fade-down" width="100%" class="box-s1 brd-r-16 py-3 px-4">
                                    <p class="mb-0 title font-weight-regular"><span class="font-weight-bold">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                viewBox="0 0 16 16" fill="none">
                                                <g clip-path="url(#clip0_1273_3902)">
                                                    <path
                                                        d="M12.5 8.5C14.433 8.5 16 6.933 16 5C16 3.067 14.433 1.5 12.5 1.5C10.567 1.5 9 3.067 9 5C9 6.933 10.567 8.5 12.5 8.5Z"
                                                        fill="#212121" />
                                                    <path
                                                        d="M9 9H4C3.72386 9 3.5 9.22386 3.5 9.5V14.5C3.5 14.7761 3.72386 15 4 15H9C9.27614 15 9.5 14.7761 9.5 14.5V9.5C9.5 9.22386 9.27614 9 9 9Z"
                                                        fill="#212121" />
                                                    <path
                                                        d="M7.75 6.99979H0.250001C0.205374 6.99979 0.161561 6.98785 0.123111 6.9652C0.0846599 6.94255 0.0529729 6.91002 0.0313395 6.87099C0.00970603 6.83195 -0.00108519 6.78784 8.61525e-05 6.74323C0.00125749 6.69862 0.0143487 6.65514 0.0380006 6.61729L3.788 0.617293C3.81203 0.583446 3.84382 0.555845 3.8807 0.536798C3.91758 0.517752 3.95849 0.507812 4 0.507812C4.04151 0.507812 4.08242 0.517752 4.1193 0.536798C4.15618 0.555845 4.18797 0.583446 4.212 0.617293L7.962 6.61729C7.98565 6.65514 7.99874 6.69862 7.99992 6.74323C8.00109 6.78784 7.9903 6.83195 7.96866 6.87099C7.94703 6.91002 7.91534 6.94255 7.87689 6.9652C7.83844 6.98785 7.79463 6.99979 7.75 6.99979Z"
                                                        fill="#212121" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1273_3902">
                                                        <rect width="16" height="16" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            100+</span> <br>Indicators</p>
                                </v-card>
                            </v-col>
                            <v-col cols="6">

                                <v-card data-aos="fade-down" width="100%" class="box-s1 brd-r-16 py-3 px-4">
                                    <p class="mb-0 title font-weight-regular"><span class="font-weight-bold">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                viewBox="0 0 16 16" fill="none">
                                                <g clip-path="url(#clip0_1273_3911)">
                                                    <path
                                                        d="M15 16H1C0.4 16 0 15.6 0 15V1C0 0.4 0.4 0 1 0H15C15.6 0 16 0.4 16 1V15C16 15.6 15.6 16 15 16ZM2 14H14V2H2V14Z"
                                                        fill="#212121" />
                                                    <path
                                                        d="M3.39982 10.7984C2.99982 10.4984 2.89982 9.79844 3.19982 9.39844L6.19982 5.39844C6.59982 4.89844 7.29982 4.89844 7.69982 5.29844L9.79982 7.39844L11.0998 5.39844C11.3998 4.89844 11.9998 4.79844 12.4998 5.09844C12.9998 5.39844 13.0998 5.99844 12.7998 6.49844L10.7998 9.49844C10.4998 9.99844 9.69982 10.0984 9.29982 9.69844L7.09982 7.49844L4.79982 10.5984C4.49982 10.9984 3.79982 11.0984 3.39982 10.7984Z"
                                                        fill="#212121" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1273_3911">
                                                        <rect width="16" height="16" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            10+</span><br>Chart types</p>
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="pt-6 pl-2 pl-sm-4 overflow-x-hidden">
                        <v-list-item data-aos="fade-left"
                            v-for="(item, k) in act == 'Mynt' ? product_mob : act == 'Mynt API' ? product_api : product_tv "
                            :key="k">
                            <v-list-item-avatar class="mt-0" tile size="28">
                                <img :src="item.img" :alt="item.img" />
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <p class="font-weight-light fs-18 lh-24">{{ item.txt }}</p>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
export default {

    data: () => ({
        act: 'Mynt',
        apic: 'js',
        products: [
            'Mynt',
            // 'Mynt +',
            'Mynt API',
            'Mynt Tradingview'
        ],
        product_mob: [
            { img: require('@/assets/product/p1.png'), txt: 'First In-house built Hybrid mobile app for trading in India' },
            { img: require('@/assets/product/p2.png'), txt: 'All features are built with "One click" access' },
            { img: require('@/assets/product/p3.png'), txt: 'All order types enabled. Multiple ways to trade the markets.' },
            { img: require('@/assets/product/p4.png'), txt: 'One margin for all segments (Not app specific, but we can highlight)' },
            { img: require('@/assets/product/p5.png'), txt: 'Easy Navigations. Fast and quick way to get around the app.' },
            { img: require('@/assets/product/p6.png'), txt: 'Flash trade / Quick trade settings based on segment' },
            { img: require('@/assets/product/p7.png'), txt: 'Technical chart features. Tradingview charts on the fly.' },
            { img: require('@/assets/product/p8.png'), txt: 'Easy FNO access and option chain access' },
        ],
        product_api: [
            { img: require('@/assets/product/a1.svg'), txt: 'Market Feed API - Everyday market feed delivered, tick by tick ' },
            { img: require('@/assets/product/a2.svg'), txt: 'Historical Data API - Check history or run backtests on past data.' },
            { img: require('@/assets/product/a3.svg'), txt: 'Order Management API - Setup buy and sell orders on the fly.' },
            { img: require('@/assets/product/a4.svg'), txt: 'Portfolio API - Visualise your portfolio  with our crunched data.' },
            { img: require('@/assets/product/a5.svg'), txt: 'Profile API - All user data and preferences delivered right.' },
            { img: require('@/assets/product/a6.svg'), txt: 'Request Limits - Ample request bandwidth and prompt responses.' },
            { img: require('@/assets/product/a7.svg'), txt: 'Pricing - Affordable modular pricing based on usage.' },
        ],

        product_tv: [
            { img: require('@/assets/product/t1.svg'), txt: '100+ Indicators - Offers a boutique of 100 and more indicators for free.' },
            { img: require('@/assets/product/t2.svg'), txt: '10+ Chart Types - Bar, candle, mountain, histogram and many more.' },
            { img: require('@/assets/product/t3.svg'), txt: 'Advance Option Chain (OI Bar and OI interpretation, Greeks, IV, PCR) ' },
            { img: require('@/assets/product/t4.svg'), txt: 'Quick order placing - keyboard shortcuts to make quick buy/sell.' },
            { img: require('@/assets/product/t5.svg'), txt: 'Orders on chart, place orders on right from the chart and manage it.' },
            { img: require('@/assets/product/t6.svg'), txt: 'Position reversal on chart, setup pivots to figure out reversals.' },
            { img: require('@/assets/product/t7.svg'), txt: 'Position exit on chart, exit from positions on the fly from the chart.' },
            { img: require('@/assets/product/t8.svg'), txt: 'Profit and Loss on chart, daily MTM of everyday positions on the chart.' },
            { img: require('@/assets/product/t9.svg'), txt: 'Position Strategies & Grouping. Add in strategy to the chart.' },
            { img: require('@/assets/product/t10.svg'), txt: 'One click square off of trade. Enable quick switch between positions.' },
            { img: require('@/assets/product/t11.svg'), txt: 'Advance Orders Types, Intraday, CNC, bracket and more order types.' },
            { img: require('@/assets/product/t12.svg'), txt: 'Live Corporate Action & News, quick read of the news and alerts.' },
            { img: require('@/assets/product/t13.svg'), txt: 'Price Ladder, to show market depth on the graph to understand liquidity.' },


        ],

    }),

}
</script>