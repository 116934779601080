<template>
    <div>
        <AppBar />
        <div class="overflow-y-auto h100vh ss-cust-scroll overflow-x-hidden" @scroll.passive="handleScroll">
            <v-container class="pa-0 px-0 pt-10 pt-md-14 ma-0 ma-lg-auto">
                <div class="body-part px-0 px-md-16 px-lg-0 pt-3">
                    <div class="mb-6 pos-rlt">
                        <v-toolbar class="d-none d-md-block px-0 pos-stk top-68 z-i3 tool-sty" flat dense color="#fff">
                            <v-row>
                                <v-col cols="12" md="9" class="px-0">
                                    <v-tabs @change="setbodyTab" fixed color="#000000" v-model="bodytab" show-arrows
                                        dense>
                                        <v-tab dense v-for="(t, index) in dashitems" :key="index"
                                            class="text-none text-start min-w-fit">
                                            <span class="text-center tab-txt">{{ t.txt }}</span></v-tab>
                                        <v-tabs-slider color="#000000"></v-tabs-slider>
                                    </v-tabs>
                                </v-col>
                                <v-col cols="12" md="3" class="text-right mt-auto pr-0">
                                    <p class="mb-3 tab-end-side"><v-icon color="#1FD601"
                                            size="6">mdi-checkbox-blank-circle</v-icon>
                                        Market live
                                    </p>
                                </v-col>
                            </v-row>
                        </v-toolbar>
                        <!-- <v-progress-linear v-if="mainloader" color="primary" class="pos-stk z-i3 top-116"
                            indeterminate></v-progress-linear> -->
                        <v-divider class="mb-md-4 mb-3 d-none d-md-flex pos-stk z-i3 top-116"></v-divider>
                        <v-card class="elevation-0 rounded-0 pos-fix z-i3 pa-2 top-56 rig-0 d-none d-md-flex"
                            width="100%" color="#fff"></v-card>
                        <div>
                            <v-row no-glutters>
                                <v-col cols="12" md="9" class="px-md-0 pt-4 pt-md-3 pb-0 pb-md-3">
                                    <v-card :loading="mainloader" id="overview" class="ss-cards overflow-hidden mb-md-6"
                                        width="100%">
                                        <v-card color="#FAFBFF" class="elevation-0 rounded-0 pt-2 px-md-2 px-3">
                                            <v-row no-glutters>
                                                <v-col cols="12" md="7" class="pb-1 pb-md-2">
                                                    <v-list-item class="px-0 px-md-4">
                                                        <v-list-item-avatar color="#FFFFFF" size="56" class="my-0">
                                                            <img v-if="imageicon" :src="imageicon"
                                                                @error="imageLoadError" class="pa-1" :alt="imageicon"
                                                                width="100%" />
                                                            <span class="headline font-weight-bold" v-else>{{
                                                                menudata[0] ?
                                                                    (menudata[0].cname ? menudata[0].cname :
                                                                        menudata[0].symname).slice(0, 1) : ''
                                                            }}</span>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content class="pt-3 pb-0">
                                                            <v-list-item-title
                                                                :class="menudata[0] ? 'black--text' : 'txt-trn'"
                                                                class="fs-18 font-weight-bold mb-0 text-capitalize lh-14">
                                                                {{ menudata[0] ? menudata[0].cname : 'abcd'
                                                                }}</v-list-item-title>
                                                            <v-chip-group class="my-0 py-0">
                                                                <v-chip label small text-color="#666666" color="#fff"
                                                                    class="font-weight-medium fs-10">
                                                                    {{ menudata.f ? menudata.f.industry : 'Industry' }}
                                                                </v-chip>
                                                                <v-chip label small text-color="#666666" color="#fff"
                                                                    class=" font-weight-medium fs-10">
                                                                    {{ menudata.f ? menudata.f.market_cap_type
                                                                        : 'Marketcap type' }}
                                                                </v-chip>
                                                            </v-chip-group>
                                                            <!-- <v-list-item-subtitle
                                                                class="fs-10 txt-666 font-weight-normal text-uppercase">{{(new Date().toString()).slice(4, 21) }}
                                                                IST</v-list-item-subtitle> -->
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-col>
                                                <v-col cols="12" md="5"
                                                    class="text-md-right text-left pt-0 pt-md-5 pb-2 pl-4 pl-md-3">
                                                    <p class="mb-0 mr-md-4 subtitle-1 font-weight-bold lh-24">
                                                        {{ menudata.g && Number(menudata.g.lp) ?
                                                            `₹${Number(menudata.g.lp).toFixed(2)}` : '0.00' }} <br
                                                            class="d-none d-md-block">
                                                        <span class="fs-12"
                                                            :class="menudata.ch > 0 ? 'txt-gre' : menudata.ch < 0 ? 'txt-red' : 'txt-444'">
                                                            {{ menudata.ch ? `${menudata.ch}` : '0.00' }}
                                                            ({{ menudata.g ? `${menudata.g.change}` : '0.00' }}%)</span>
                                                    </p>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                        <div class="py-3 py-md-6">
                                            <v-toolbar class="nav-drawer elevation-0 px-md-2 px-0" color="transparent"
                                                dense>
                                                <p class="wid-160 font-weight-bold title mb-0">Price Chart</p>
                                                <v-spacer></v-spacer>
                                            </v-toolbar>
                                            <v-divider></v-divider>
                                            <v-row
                                                class="pt-md-6 pb-md-3 pl-md-6 pr-md-8 flex-column-reverse flex-md-row">
                                                <v-col cols="12" md="4" class="px-7 px-md-3">
                                                    <v-row no-gluttters>
                                                        <v-col cols="6">
                                                            <v-list-item class="px-0">
                                                                <v-list-item-content class="pb-0">
                                                                    <v-list-item-subtitle
                                                                        class="font-weight-regular fs-10 txt-666 mb-2">Market
                                                                        Cap
                                                                    </v-list-item-subtitle>
                                                                    <v-list-item-title
                                                                        class="black--text font-weight-medium fs-12 mb-1">
                                                                        {{ menudata.f ?
                                                                            `${(Number(menudata.f.market_cap)).toLocaleString()}`
                                                                            : '0.00' }} Cr</v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider></v-divider>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-list-item class="px-0">
                                                                <v-list-item-content class="pb-0">
                                                                    <v-list-item-subtitle
                                                                        class="font-weight-regular fs-10 txt-666 mb-2">Volume
                                                                    </v-list-item-subtitle>
                                                                    <v-list-item-title
                                                                        class="black--text font-weight-medium fs-12 mb-1">
                                                                        {{ menudata[0] && menudata[0].v ?
                                                                            `${Number(menudata[0].v).toLocaleString()}` :
                                                                            '0.00' }}</v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider></v-divider>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-list-item class="px-0">
                                                                <v-list-item-content class="pb-0">
                                                                    <v-list-item-subtitle
                                                                        class="font-weight-regular fs-10 txt-666 mb-2">Open
                                                                    </v-list-item-subtitle>
                                                                    <v-list-item-title
                                                                        class="black--text font-weight-medium fs-12 mb-1">
                                                                        {{ menudata[0] && Number(menudata[0].o) ?
                                                                            `₹${Number(menudata[0].o).toFixed(2)}` :
                                                                            '0.00' }}</v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider></v-divider>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-list-item class="px-0">
                                                                <v-list-item-content class="pb-0">
                                                                    <v-list-item-subtitle
                                                                        class="font-weight-regular fs-10 txt-666 mb-2">Close
                                                                    </v-list-item-subtitle>
                                                                    <v-list-item-title
                                                                        class="black--text font-weight-medium fs-12 mb-1">
                                                                        {{ menudata[0] && Number(menudata[0].c) ?
                                                                            `₹${Number(menudata[0].c).toFixed(2)}` :
                                                                            '0.00' }}</v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider></v-divider>
                                                        </v-col>

                                                        <v-col cols="12">
                                                            <p class="font-weight-regular fs-10 txt-666 mb-0">
                                                                High - Low</p>
                                                            <div class="d-flex flex-row mb-1">
                                                                <span
                                                                    class="black--text font-weight-medium fs-12 pt-1 lh-24">
                                                                    {{ menudata[0] && Number(menudata[0].h) ?
                                                                        `₹${Number(menudata[0].h).toFixed(2)}` : '0.00'
                                                                    }}
                                                                </span>
                                                                <v-card v-if="menudata[0]" width="100%"
                                                                    class="elevation-0 px-2">
                                                                    <v-slider hide-details thumb-color="#000"
                                                                        color="#D9D9D9" v-model="menudata[0].lp"
                                                                        readonly :min="Number(menudata[0].l)"
                                                                        :max="Number(menudata[0].h)"
                                                                        track-color="#D9D9D9"></v-slider>
                                                                </v-card>
                                                                <span
                                                                    class="black--text font-weight-medium fs-12 pt-1 lh-24 float-right">
                                                                    {{ menudata[0] && Number(menudata[0].l) ?
                                                                        `₹${Number(menudata[0].l).toFixed(2)}` :
                                                                        '0.00' }}
                                                                </span>
                                                            </div>
                                                            <v-divider></v-divider>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <p class="font-weight-regular fs-10 txt-666 mb-0">52
                                                                Weeks high - 52 Weeks
                                                                low</p>
                                                            <div class="d-flex flex-row mb-1">
                                                                <span
                                                                    class="black--text font-weight-medium fs-12 pt-1 lh-24">
                                                                    {{ menudata[0] && Number(menudata[0].wk52_h) ?
                                                                        `₹${Number(menudata[0].wk52_h).toFixed(2)}` :
                                                                        '0.00' }}
                                                                </span>
                                                                <v-card v-if="menudata[0]" width="100%"
                                                                    class="elevation-0 px-2">
                                                                    <v-slider hide-details thumb-color="#000"
                                                                        color="#D9D9D9" v-model="menudata[0].lp"
                                                                        readonly :min="Number(menudata[0].wk52_l)"
                                                                        :max="Number(menudata[0].wk52_h)"
                                                                        track-color="#D9D9D9"></v-slider>
                                                                </v-card>
                                                                <span
                                                                    class="black--text font-weight-medium fs-12 pt-1 lh-24 float-right">
                                                                    {{ menudata[0] && Number(menudata[0].wk52_l) ?
                                                                        `₹${Number(menudata[0].wk52_l).toFixed(2)}`
                                                                        : '0.00' }}
                                                                </span>
                                                            </div>
                                                            <v-divider></v-divider>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="12" md="8" class="pa-md-0 pt-4 pb-0 px-6">
                                                    <v-card class="elevation-0 rounded-0 ss-adv-chart"
                                                        v-if="menudata[0]">
                                                        <!-- {{`https://tv3-chart.firebaseapp.com/charts?tsym=${menudata[0].exch}%3A${menudata[0].tsym}&token=${usdata.mas}&userId=${usdata.uid}&userToken=${usdata.token}`}} -->
                                                        <iframe
                                                            :src="`https://tv3-chart.firebaseapp.com?tsym=${menudata[0].exch}%3A${menudata[0].tsym}&token=${usdata.mas}&userId=${usdata.uid}&userToken=${usdata.token}`"
                                                            class="brd-0" width="100%" height="100%"></iframe>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <div class="px-md-6 px-4">
                                            <v-divider class="mb-4 d-none d-md-flex"></v-divider>
                                            <p class="font-weight-bold title mb-1 text-capitalize">{{
                                                menudata[0] ? menudata[0].symname : '' }} Stock overview</p>
                                            <p class="fs-12 txt-666 txt-x-j">{{ menudata.d ? menudata.d : '' }}
                                            </p>
                                            <p class="font-weight-bold subtitle-2 mb-1">Returns
                                            </p>
                                            <v-row no-glutters class="mt-0 pb-6">
                                                <v-col cols="6" md="2"
                                                    v-for="(r, l)  in stockreturns ? stockreturns : 6" :key="l"
                                                    class="pt-0">
                                                    <v-card
                                                        :color="r.returns > 0 ? '#FBFFFA' : r.returns < 0 ? '#FFFCFB' : '#FAFAFA'"
                                                        class="rounded-lg px-3 py-2 elevation-0 brd-1-solid-ccc text-center"
                                                        width="100%">
                                                        <p :class="r.returns > 0 ? 'txt-gre' : r.returns < 0 ? 'txt-red' : 'txt-444'"
                                                            class="fs-18 font-weight-bold mb-2">{{ Number(r.returns) ?
                                                                r.returns : '0.00' }}%</p>
                                                        <p class="mb-0 fs-10 text-uppercase font-weight-medium">
                                                            {{ r.type ? r.type : '---' }}
                                                        </p>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-card>
                                    <v-card id="fun" class="ss-cards overflow-hidden mb-md-6" width="100%">
                                        <div class="py-3 py-md-6">
                                            <v-toolbar class="nav-drawer elevation-0 px-md-2 px-0" color="transparent"
                                                dense>
                                                <v-list-item class="px-0">
                                                    <v-list-item-content>
                                                        <v-list-item-title
                                                            class="font-weight-bold title mb-2">Fundamental
                                                            ratios</v-list-item-title>
                                                        <v-list-item-title
                                                            class="black--text font-weight-medium fs-12">Fundamental
                                                            breakdown of
                                                            {{ menudata[0] ? menudata[0].symname : '' }}
                                                            information</v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-toolbar>

                                            <v-row class="px-4 px-md-6 mt-md-4 mt-2">
                                                <v-col cols="4" class="pb-0" v-for="( t, d, l ) in Fundamentalsfield"
                                                    :key="l">
                                                    <v-text-field class="funda-field" readonly color="#000" :label="d"
                                                        :value="t ? t : '-'">
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-card>
                                    <v-card id="fin" class="ss-cards overflow-hidden mb-md-6" width="100%">
                                        <div class="pt-md-6 pt-4 px-4 px-md-6">
                                            <v-list-item class="px-0">
                                                <v-list-item-content class="pt-0">
                                                    <v-list-item-title
                                                        class="font-weight-bold title mb-2">Financial</v-list-item-title>
                                                    <v-list-item-title
                                                        class="black--text font-weight-medium fs-12">Financial
                                                        breakdown of
                                                        {{ menudata[0] ? menudata[0].symname : '' }}
                                                        information</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-chip-group @change="setFinchartdata()" v-model="financialtab" mandatory
                                                class="mb-2" color="#666" active-class="black black--text">
                                                <v-chip outlined class="font-weight-medium fs-14">
                                                    Income
                                                </v-chip>
                                                <v-chip outlined class="font-weight-medium fs-14">
                                                    Balance Sheet
                                                </v-chip>
                                                <v-chip outlined class="font-weight-medium fs-14">
                                                    Cashflow
                                                </v-chip>
                                            </v-chip-group>
                                        </div>
                                        <v-card height="420px" id="financialchart" class="rounded-lg pt-4 elevation-0">
                                        </v-card>
                                        <div class="pb-4 px-md-6 px-4">
                                            <v-divider class="my-md-6 my-3"></v-divider>
                                            <v-toolbar flat dense class="tool-sty">
                                                <v-list-item class="px-0">
                                                    <v-list-item-content>
                                                        <v-list-item-title class="font-weight-bold fs-16">
                                                            {{ financialtab == 2 ? 'Cashflow' : financialtab == 0 ?
                                                                'Income' : 'Balance Sheet' }}
                                                            Statement</v-list-item-title>
                                                        <v-list-item-subtitle
                                                            class="txt-666 font-weight-medium fs-12">All Figures
                                                            in
                                                            Cr.</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-spacer></v-spacer>

                                                <v-select @change="setFinchartdata()" hide-details
                                                    append-icon="mdi-chevron-down" v-model="fin_fiter"
                                                    :items="[{ key: 'Standalone', val: 'stockFinancialsStandalone' }, { key: 'Consolidated', val: 'stockFinancialsConsolidated' }]"
                                                    item-text="key" item-value="val" class="rounded-pill max-w-160"
                                                    dense flat solo background-color="#F1F3F8"
                                                    label="Filter"></v-select>
                                            </v-toolbar>
                                        </div>
                                        <v-data-table mobile-breakpoint item-key="name" must-sort
                                            :sort-by="[financialtab != 2 ? 'idx' : 'name']" :sort-desc="[false]"
                                            hide-default-footer fixed-header class="financialtabel rounded-0"
                                            :headers="financialheader" :search="financialsearch"
                                            :items="financialitem[fin_fiter] ? financialtab == 0 ? financialitem[fin_fiter].incomeSheet : financialtab == 1 ? financialitem[fin_fiter].balanceSheet : financialitem[fin_fiter].cashflowSheet : []"
                                            :items-per-page="-1">
                                            <template v-slot:[`header.y0`]>
                                                <v-select hide-details append-icon="mdi-chevron-down"
                                                    v-model="fin_table" :items="financialheader.slice(1, 6)"
                                                    item-text="text" item-value="value" class="rounded-pill" dense
                                                    flat></v-select>
                                            </template>
                                            <template v-slot:[`item.exp`]="{ item }">
                                                <td class="pl-md-6 pl-4">
                                                    <span class="font-weight-medium black--text text-capitalize"
                                                        :class="finKeyname(item.name).includes('-') ? 'txt-red' : ''">{{
                                                            finKeyname(item.name) }}</span>
                                                </td>
                                            </template>
                                            <template v-slot:[`item.y1`]="{ item }">
                                                <td class="pr-md-6 pr-4 text-right d-none d-md-table-cell"
                                                    :class="item.y1 < 0 ? 'txt-red' : ''">
                                                    {{ item.sym == '₹' ? item.sym : '' }}{{ item.y1 }}
                                                    {{ item.sym == '%' ? item.sym : '' }}
                                                </td>
                                            </template>

                                            <template v-slot:[`item.y2`]="{ item }">
                                                <td class="text-right d-none d-md-table-cell"
                                                    :class="item.y2 < 0 ? 'txt-red' : ''">
                                                    {{ item.sym == '₹' ? item.sym : '' }}{{ item.y2 }}
                                                    {{ item.sym == '%' ? item.sym : '' }}
                                                </td>
                                            </template>
                                            <template v-slot:[`item.y3`]="{ item }">
                                                <td class="text-right d-none d-md-table-cell"
                                                    :class="item.y3 < 0 ? 'txt-red' : ''">
                                                    {{ item.sym == '₹' ? item.sym : '' }}{{ item.y3 }}
                                                    {{ item.sym == '%' ? item.sym : '' }}
                                                </td>
                                            </template>
                                            <template v-slot:[`item.y4`]="{ item }">
                                                <td class="text-right d-none d-md-table-cell"
                                                    :class="item.y4 < 0 ? 'txt-red' : ''">
                                                    {{ item.sym == '₹' ? item.sym : '' }}{{ item.y4 }}
                                                    {{ item.sym == '%' ? item.sym : '' }}
                                                </td>
                                            </template>
                                            <template v-slot:[`item.y5`]="{ item }">
                                                <td class="text-right d-none d-md-table-cell"
                                                    :class="item.y5 < 0 ? 'txt-red' : ''">
                                                    {{ item.sym == '₹' ? item.sym : '' }}{{ item.y5 }}
                                                    {{ item.sym == '%' ? item.sym : '' }}
                                                </td>
                                            </template>
                                            <template v-slot:[`item.y0`]="{ item }">
                                                <td class="pr-4 text-right d-md-none"
                                                    :class="item[fin_table] < 0 ? 'txt-red' : ''">
                                                    {{ item.sym == '₹' ? item.sym : '' }}{{ item[fin_table] }}
                                                    {{ item.sym == '%' ? item.sym : '' }}
                                                </td>
                                            </template>
                                            <template v-slot:no-data>
                                                <v-col cols="12" class="text-center pa-16">
                                                    <div class="mx-auto ">
                                                        <img class="align-self-stretch mx-auto" width="80px"
                                                            :src="require('@/assets/no data folder.svg')"
                                                            alt="no data" />
                                                        <h5 class="txt-999 font-weight-regular">There is no Financial
                                                            data here yet!
                                                        </h5>

                                                    </div>
                                                </v-col>
                                            </template>
                                        </v-data-table>
                                        <v-divider class="d-md-none"></v-divider>
                                    </v-card>
                                    <v-card id="peers" class="ss-cards overflow-hidden mb-md-6 pt-6" width="100%">
                                        <v-toolbar class="nav-drawer elevation-0 px-md-2 px-0" color="transparent"
                                            dense>
                                            <v-list-item class="px-0">
                                                <v-list-item-content>
                                                    <v-list-item-title class="font-weight-bold title mb-2">Peers
                                                        Comparison</v-list-item-title>
                                                    <v-list-item-title
                                                        class="black--text font-weight-medium fs-12">Peers Comparison
                                                        breakdown of
                                                        {{ peeritem[0] ?
                                                            peeritem[0].industry :
                                                            '' }} information</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-spacer></v-spacer>
                                            <v-text-field v-model="peersearch" hide-details
                                                prepend-inner-icon="mdi-magnify" label="Search"
                                                class="rounded-pill mb-2 d-none d-md-flex" dense flat solo
                                                background-color="#F1F3F8"></v-text-field>
                                        </v-toolbar>
                                        <v-data-table mobile-breakpoint must-sort :sort-by="['']" :sort-desc="[false]"
                                            hide-default-footer fixed-header class="rounded-0 overflow-y-auto mt-4"
                                            :headers="peerheader" height="334px" width="800px" :search="peersearch"
                                            :items="peeritem" :items-per-page="peeritem.length">
                                            <template v-slot:[`header.peers`]>
                                                <v-select hide-details append-icon="mdi-chevron-down"
                                                    v-model="peer_table" :items="peerheader.slice(1, 9)"
                                                    item-text="text" item-value="value" class="rounded-pill" dense
                                                    flat></v-select>
                                            </template>
                                            <template v-slot:[`item.SYMBOL`]="{ item }">
                                                <td class="pl-md-6 pl-4 font-weight-medium black--text text-capitalize">
                                                    {{ item.SYMBOL.split(':')[1] }}
                                                </td>
                                            </template>
                                            <template v-slot:[`item.ltp`]="{ item }">
                                                <td class="text-right d-none d-md-table-cell">
                                                    {{ item.ltp && item.ltp.lp ? Number(item.ltp.lp).toFixed(2) : '0.00'
                                                    }}</td>
                                            </template>
                                            <template v-slot:[`item.market_cap`]="{ item }">
                                                <td class="text-right d-none d-md-table-cell">
                                                    {{ item.market_cap ? item.market_cap : '0.00'
                                                    }}</td>
                                            </template>
                                            <template v-slot:[`item.pe`]="{ item }">
                                                <td class="text-right d-none d-md-table-cell">
                                                    {{ item.pe ? item.pe : '0.00'
                                                    }}</td>
                                            </template>
                                            <template v-slot:[`item.price_book_value`]="{ item }">
                                                <td class="text-right d-none d-md-table-cell">
                                                    {{ item.price_book_value ? item.price_book_value : '0.00'
                                                    }}</td>
                                            </template>
                                            <template v-slot:[`item.roce_percent`]="{ item }">
                                                <td class="text-right d-none d-md-table-cell">
                                                    {{ item.roce_percent ? item.roce_percent : '0.00'
                                                    }}</td>
                                            </template>
                                            <template v-slot:[`item.ev_ebitda`]="{ item }">
                                                <td class="text-right d-none d-md-table-cell">
                                                    {{ item.ev_ebitda ? item.ev_ebitda : '0.00'
                                                    }}</td>
                                            </template>
                                            <template v-slot:[`item.debt_to_equity`]="{ item }">
                                                <td class="text-right d-none d-md-table-cell">
                                                    {{ item.debt_to_equity ? item.debt_to_equity : '0.00'
                                                    }}</td>
                                            </template>
                                            <template v-slot:[`item.dividend_yield_percent`]="{ item }">
                                                <td class="pr-md-6 pr-4 text-right d-none d-md-table-cell">
                                                    <span>{{ item.dividend_yield_percent ?
                                                        `${item.dividend_yield_percent}%` : '---'
                                                        }}</span>
                                                </td>
                                            </template>
                                            <template v-slot:[`item.peers`]="{ item }">
                                                <td class="pr-4 text-right d-md-none">
                                                    <span v-if="peer_table == 'dividend_yield_percent'">
                                                        {{ item.dividend_yield_percent ?
                                                            `${item.dividend_yield_percent}%` : '---'
                                                        }}</span>
                                                    <span v-else-if="peer_table == 'ltp'">
                                                        {{ item.ltp && item.ltp.lp ? Number(item.ltp.lp).toFixed(2) :
                                                            '0.00'
                                                        }}
                                                    </span>
                                                    <span v-else>{{ item[peer_table] ?
                                                        item[peer_table] : ''
                                                        }}</span>
                                                </td>
                                            </template>
                                            <template v-slot:no-data>
                                                <v-col cols="12" class="text-center pa-16">
                                                    <div class="mx-auto ">
                                                        <img class="align-self-stretch mx-auto" width="80px"
                                                            :src="require('@/assets/no data folder.svg')"
                                                            alt="no data" />
                                                        <h5 class="txt-999 font-weight-regular">There is no Peers
                                                            comparison
                                                            data here yet!</h5>
                                                    </div>
                                                </v-col>
                                            </template>
                                        </v-data-table>
                                        <v-divider class="d-md-none"></v-divider>
                                        <!-- <v-text-field hide-details prepend-inner-icon="mdi-magnify" label="Search for stocks to add"
                                class="rounded-pill mr-4 mx-8 mb-6 max-w-28" dense flat solo
                                background-color="#F1F3F8"></v-text-field> -->
                                    </v-card>
                                    <v-card id="price" class="ss-cards overflow-hidden mb-md-6" width="100%">
                                        <div class="pt-6 pb-4 pl-md-6 pl-4">
                                            <v-toolbar flat dense class="tool-sty mb-5">
                                                <v-list-item class="px-0">
                                                    <v-list-item-content>
                                                        <v-list-item-title class="font-weight-bold title mb-2">Price
                                                            Comparison</v-list-item-title>
                                                        <v-list-item-subtitle
                                                            class="txt-666 font-weight-medium fs-12">Compare <span
                                                                class="txt-fobly">{{
                                                                    menudata[0] ? menudata[0].symname :
                                                                        '' }}
                                                            </span> with other stocks</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-spacer></v-spacer>
                                            </v-toolbar>

                                            <!-- <div class="d-flex">
                                                <v-chip color="#444" v-for="(p, c) in pricecompar.closes" :key="c" outlined
                                                    class="font-weight-medium fs-14 mr-3 my-4">
                                                    <p class="font-weight-medium black--text mb-0 d-inline-flex">
                                                        <v-card :color="p.color" class="mt-1 mr-2 elevation-0"
                                                            width="12px" height="12px"></v-card>
                                                        {{ p.name }}
                                                    </p>
                                                </v-chip>
                                            </div> -->
                                            <v-card height="460px" width="100%" id="pricechart"
                                                class="rounded-lg elevation-0">
                                            </v-card>

                                            <!-- <p class="fs-12 txt-666 mb-0 mt-4 pr-6">Reliance Global Group's total assets
                                                for
                                                Q1
                                                2023 were $33.88M, a decrease of
                                                -11.82% from the previous quarter. RELI total liabilities were $21.65M
                                                for the fiscal quarter, a -26.66% a
                                                decrease from the previous quarter. See a summary of the company's
                                                assets, liabilities, and equity.
                                            </p> -->
                                        </div>
                                    </v-card>
                                    <v-card id="hold" class="ss-cards overflow-hidden mb-md-6" width="100%">
                                        <div class="pt-6 pb-md-4 pb-8 px-md-9 px-7">
                                            <v-row no-glutters>
                                                <v-col cols="12" md="7" class="pa-0">
                                                    <p class="font-weight-bold title mb-md-2 mb-0">Holdings</p>
                                                    <v-chip-group v-if="shareholdings.all" v-model="holdtab" mandatory
                                                        class="mb-2" color="#666" active-class="black black--text">
                                                        <v-chip v-for="(h, j) in shareholdings.all" outlined
                                                            class="font-weight-medium fs-14" :key="j"
                                                            @click="shareholdings.x = j">
                                                            {{ h.date ? (new Date(h.date)).toLocaleString('dafault', {
                                                                month: 'short', year: '2-digit'
                                                            }) :
                                                                'MMM YYYY' }}
                                                        </v-chip>
                                                    </v-chip-group>
                                                    <p class="font-weight-bold subtitle-1 font-weight-medium mb-2">
                                                        Shareholding
                                                        Breakdown
                                                    </p>
                                                    <v-card width="100%" color="#DEDEDE" height="36px"
                                                        class="d-inline-flex elevation-0 rounded-0 mb-3">
                                                        <v-card v-for="(s, b) in shareholdings.table" height="36px"
                                                            :width="`${((shareholdings.all[shareholdings.x][s.shares] / (shareholdings.all[shareholdings.x].dii + shareholdings.all[shareholdings.x].fii_fpi + shareholdings.all[shareholdings.x]['mutual funds'] + shareholdings.all[shareholdings.x].promoters + shareholdings.all[shareholdings.x].retail_and_others)) * 100).toFixed(0)}%`"
                                                            class="elevation-0 rounded-0" :color="s.color" :key="b">
                                                        </v-card>
                                                    </v-card>
                                                    <v-data-table mobile-breakpoint hide-default-footer
                                                        class="rounded-0 overflow-y-auto" fixed-header
                                                        :headers="holdingheader" :items="shareholdings.table">
                                                        <template v-slot:[`item.investor`]="{ item }">
                                                            <p
                                                                class="font-weight-medium black--text mb-0 d-inline-flex">
                                                                <v-card :color="item.color"
                                                                    class="mt-1 mr-2 elevation-0" width="12px"
                                                                    height="12px"></v-card> {{ item.investor
                                                                }}
                                                            </p>
                                                        </template>
                                                        <template v-slot:[`item.holding`]="{ item }">
                                                            <span class="black--text">{{ shareholdings.table &&
                                                                item.shares
                                                                ?
                                                                (shareholdings.all[shareholdings.x][item.shares]).toFixed(2)
                                                                :
                                                                '0.00' }} %</span>
                                                        </template>
                                                        <template v-slot:no-data>
                                                            <v-col cols="12" class="text-center pa-16">
                                                                <div class="mx-auto ">
                                                                    <img class="align-self-stretch mx-auto" width="80px"
                                                                        :src="require('@/assets/no data folder.svg')"
                                                                        alt="no data" />
                                                                    <h5 class="txt-999 font-weight-regular">There is no
                                                                        Holdings
                                                                        data here yet!</h5>
                                                                </div>
                                                            </v-col>
                                                        </template>
                                                    </v-data-table>
                                                    <v-divider class="d-md-none"></v-divider>
                                                </v-col>
                                                <v-col cols="12" md="5" class="pr-md-0 pl-md-4 px-0 pb-0">
                                                    <v-card outlined class="pa-3 rounded-lg">
                                                        <p class="font-weight-bold subtitle-1 font-weight-medium mb-1">
                                                            Shareholding
                                                            History
                                                        </p>
                                                        <p class="txt-666 font-weight-medium fs-12">Select a segment
                                                            from the
                                                            breakdowns to see its pattern
                                                            here</p>
                                                        <v-select @change="setHoldchartdata()" hide-details
                                                            append-icon="mdi-chevron-down" v-model="shareholdings.y"
                                                            :items="shareholdings.table" item-text="investor"
                                                            item-value="shares" block class="rounded-pill" dense flat
                                                            solo background-color="#F1F3F8" label="Filter"></v-select>
                                                        <v-card height="294px" width="100%" id="holdchart"
                                                            class="rounded-lg elevation-0">
                                                        </v-card>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <v-divider class="my-4 d-none d-md-flex"></v-divider>
                                        <v-toolbar flat dense class="tool-sty px-md-6 px-4 mb-4">
                                            <v-list-item class="px-0">
                                                <v-list-item-content>
                                                    <v-list-item-title class="font-weight-bold fs-16 mb-2">Mutual Funds
                                                        Holding
                                                        Trend</v-list-item-title>
                                                    <p class="txt-666 mb-0 font-weight-medium fs-12">In
                                                        last 3 months,
                                                        mutual
                                                        fund
                                                        holding of the company has almost stayed
                                                        constant</p>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-spacer></v-spacer>
                                            <v-text-field v-model="mfsearch" hide-details
                                                prepend-inner-icon="mdi-magnify" label="Search"
                                                class="rounded-pill mb-2 d-none d-md-flex" dense flat solo
                                                background-color="#F1F3F8"></v-text-field>
                                        </v-toolbar>
                                        <v-data-table mobile-breakpoint must-sort :sort-by="['market_cap_Held']"
                                            :sort-desc="[true]" hide-default-footer fixed-header class="rounded-0"
                                            :headers="mfholdheader" :items="mfholdings" :search="mfsearch"
                                            :items-per-page="mfholdings.length">
                                            <template v-slot:[`header.mftrend`]>
                                                <v-select hide-details append-icon="mdi-chevron-down" v-model="mf_table"
                                                    :items="mfholdheader.slice(1, 4)" item-text="text"
                                                    item-value="value" class="rounded-pill" dense flat></v-select>
                                            </template>
                                            <template v-slot:[`item.mutual_fund`]="{ item }">
                                                <td class="pl-md-6 pl-4">
                                                    <span class="font-weight-medium black--text text-capitalize">
                                                        {{ item.mutual_fund.split('-') ? item.mutual_fund.split('-')[0]
                                                            : item.mutual_fund }}</span>
                                                </td>
                                            </template>
                                            <template v-slot:[`item.market_cap_Held`]="{ item }">
                                                <td class="text-right d-none d-md-table-cell">
                                                    <span>{{ item.market_cap_Held ?
                                                        `${item.market_cap_Held.toFixed(4)}` : '0.0000'
                                                        }}%</span>
                                                </td>
                                            </template>
                                            <template v-slot:[`item.mf_holding_percent`]="{ item }">
                                                <td class="pr-md-6 pr-4 text-right d-none d-md-table-cell">
                                                    <span>{{ item.mf_holding_percent ?
                                                        `${item.mf_holding_percent.toFixed(2)}` : '0.00'
                                                        }}%</span>
                                                </td>
                                            </template>
                                            <template v-slot:[`item.mf_aum`]="{ item }">
                                                <td class="text-right d-none d-md-table-cell">
                                                    <span>{{ item.mf_aum ?
                                                        `${item.mf_aum.toFixed(2)}` : '0.00'
                                                        }}</span>
                                                </td>
                                            </template>
                                            <template v-slot:[`item.mftrend`]="{ item }">
                                                <td class="pr-4 text-right d-md-none">
                                                    <span v-if="mf_table == 'market_cap_Held'">
                                                        {{ item.market_cap_Held ?
                                                            `${item.market_cap_Held.toFixed(4)}` : '0.0000'
                                                        }}%</span>
                                                    <span v-else-if="mf_table == 'mf_holding_percent'">
                                                        {{ item.mf_holding_percent ?
                                                            `${item.mf_holding_percent.toFixed(2)}` : '0.00'
                                                        }}%
                                                    </span>
                                                    <span v-else>{{ item[mf_table] ?
                                                        item[mf_table].toFixed(2) : '0.00'
                                                        }}</span>
                                                </td>
                                            </template>
                                            <template v-slot:no-data>
                                                <v-col cols="12" class="text-center pa-16">
                                                    <div class="mx-auto">
                                                        <img class="align-self-stretch mx-auto" width="80px"
                                                            :src="require('@/assets/no data folder.svg')"
                                                            alt="no data" />
                                                        <h5 class="txt-999 font-weight-regular">There is no Peers
                                                            comparison
                                                            data here yet!</h5>
                                                    </div>
                                                </v-col>
                                            </template>
                                        </v-data-table>
                                        <v-divider class="d-md-none"></v-divider>
                                    </v-card>
                                    <v-card id="event" class="ss-cards overflow-hidden mb-md-6" width="100%">
                                        <div class="pt-6 pb-4 px-md-6 px-4">
                                            <p class="font-weight-bold title mb-md-2 mb-0">Events</p>
                                            <v-chip-group v-if="menudata.events" v-model="eventtab" mandatory
                                                class="mb-4" color="#666" active-class="black black--text">
                                                <v-chip v-for="(a, b, c) in menudata.events" outlined
                                                    @click="eventchip = b"
                                                    class="font-weight-medium fs-14 text-capitalize" :key="c">
                                                    {{ b
                                                    }}
                                                </v-chip>
                                            </v-chip-group>
                                            <div
                                                v-if="menudata.events && menudata.events[eventchip] && menudata.events[eventchip].length > 0">
                                                <v-card outlined v-for="(d, e, f) in menudata.events[eventchip]"
                                                    :key="f" class="mb-3 rounded-lg" color="#F2F2F3">
                                                    <v-list-item>
                                                        <v-list-item-content v-for="(g, h, i) in d" :key="i">
                                                            <v-list-item-subtitle
                                                                class="txt-5E6 mb-2 font-weight-medium fs-13 text-capitalize">{{
                                                                    finKeyname(h)
                                                                }}</v-list-item-subtitle>
                                                            <v-list-item-title
                                                                class="txt-000 font-weight-medium fs-14 text-capitalize">
                                                                <span v-if="h.includes('date')">
                                                                    {{ new Date(new Date(g).getFullYear(), new
                                                                        Date(g).getMonth(),
                                                                        new
                                                                            Date(g).getDate()).toISOString().slice(0, 10)
                                                                    }}</span>
                                                                <span v-else>{{ g }}</span>
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-card>
                                            </div>
                                            <div v-else>
                                                <v-col cols="12" class="text-center pa-16">
                                                    <div class="mx-auto">
                                                        <img class="align-self-stretch mx-auto" width="80px"
                                                            :src="require('@/assets/no data folder.svg')"
                                                            alt="no data" />
                                                        <h5 class="txt-999 font-weight-regular">There is no
                                                            {{ eventchip }} events data here yet!</h5>
                                                    </div>
                                                </v-col>
                                            </div>
                                        </div>
                                    </v-card>
                                    <v-card id="news" class="ss-cards overflow-hidden" width="100%">
                                        <div class="pt-md-6 pb-4 px-md-6 px-4">
                                            <p class="font-weight-bold title mb-md-2 mb-0">News</p>
                                            <div class="d-inline-flex mb-6">
                                                <v-chip-group v-if="menudata.events" v-model="newstab" mandatory
                                                    class="mb-0" color="#666" active-class="black black--text">
                                                    <v-chip @click="setFiltere(i = p)" v-for="(p, b) in newstypes"
                                                        :key="b" outlined
                                                        class="font-weight-medium fs-14 text-capitalize">
                                                        {{ p.txt
                                                        }}
                                                    </v-chip>
                                                </v-chip-group>
                                            </div>
                                            <div v-if="newsloading">
                                                <v-container fill-height>
                                                    <v-card class="elevation-0 mx-auto py-16">
                                                        <v-progress-circular size="80" indeterminate
                                                            color="#1e53e5"></v-progress-circular>
                                                    </v-card>
                                                </v-container>
                                            </div>
                                            <div v-else-if="allnews.length > 0">
                                                <v-row>
                                                    <v-col cols="12" md="6" v-for="(n, e) in allnews" :key="e">
                                                        <v-card class="rounded-lg elevation-0 mb-2" @click="newsPage(n)">
                                                            <v-row no-glutters>
                                                                <v-col cols="4" sm="3" xl="2">
                                                                    <v-card class="rounded-lg elevation-0">
                                                                        <img :src="n.image" width="100%" height="60px"
                                                                            class="rounded-lg" :alt="n.image">
                                                                    </v-card>
                                                                </v-col>
                                                                <v-col cols="8" sm="9" xl="10" class="pl-0 pl-sm-2 pt-2">
                                                                    <v-list-item two-line class="px-0 pr-sm-3">
                                                                        <v-list-item-content class="py-0">
                                                                            <p
                                                                                class="font-weight-medium fs-14 mb-2 text-rap-l2 lh-20">
                                                                                {{ n.title }}</p>
                                                                            <v-list-item-subtitle
                                                                                class="fs-12 font-weight-regular">{{
                                                                                    n.isdate
                                                                                }}</v-list-item-subtitle>
                                                                        </v-list-item-content>
                                                                    </v-list-item>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
            
                                            </div>
                                            <div v-else>
                                                <v-container fill-height>
                                                    <v-card class="elevation-0 mx-auto py-16 text-center">
                                                        <div class="mx-auto">
                                                            <img class="align-self-stretch mx-auto" width="80px"
                                                                :src="require('@/assets/no data folder.svg')" alt="no data" />
                                                            <h5 class="txt-999 font-weight-regular">There is no news data here yet!
                                                            </h5>
                                                        </div>
                                                    </v-card>
                                                </v-container>
                                            </div>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="3" class="pr-md-0 pt-0 pt-md-3">
                                    <div class="pos-md-stk top-md-134 px-4 px-md-0">
                                        <v-card outlined
                                            class="overflow-hidden ss-login-card pt-md-3 px-3 rounded-lg text-center pos-md-rlt"
                                            width="100%">
                                            <img src="@/assets/products-sec/Stocks-ETFs.png" alt="Stocks-ETFs"
                                                class="mb-md-2 mt-md-4 px-md-4 px-16" width="100%">
                                            <p class="font-weight-bold fs-16 mb-1">Ready to invest in stocks?</p>
                                            <p class="txt-666 font-weight-medium fs-12 mb-8">Open your demat account
                                                effortlessly <br> with our six-click
                                                process.
                                            </p>
                                            <v-card class="pos-md-abs pt-6 pt-md-0 btm-32 elevation-0 rounded-0"
                                                width="100%">
                                                <v-form ref="form" v-model="valid" lazy-validation
                                                    @submit.prevent="getCall()" class="pr-md-6">

                                                    <v-text-field v-model="mobile" @keypress="NumberValid($event)"
                                                        @keyup.enter="getCall()" :maxlength="10" :rules="numberis"
                                                        required hide-spin-buttons flat hide-details solo
                                                        background-color="#F1F3F8"
                                                        class="sign-up-filed elevation-0 rounded-pill caption mb-3"
                                                        label="Enter mobile number to begin">
                                                        <template #prepend-inner>
                                                            <img src="@/assets/phone-icon.svg" width="100%"
                                                                alt="phone-icon">
                                                        </template>
                                                    </v-text-field>

                                                    <v-btn type="submit" block height="48px"
                                                        :color="valid ? '#FFF07E' : '#FFF07E30'"
                                                        class="text-none rounded-pill elevation-0"><span
                                                            class="black--text font-weight-bold">Invest
                                                            now</span></v-btn>
                                                </v-form>
                                            </v-card>

                                            <!-- <img src="@/assets/sstk/ssimgb.png" alt="ssimgb" class="pos-abs btm-0 rig-0"
                                                width="100%"> -->
                                        </v-card>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                    <v-overlay :value="mainloader" opacity="1" color="#ffffff30" class="rounded-0">
                        <!-- <div class="text-center">
                            <v-card width="300px" class="elevation-0 mx-auto" color="transparent">
                                <v-progress-linear color="primary" indeterminate></v-progress-linear>
                            </v-card>
                        </div> -->
                    </v-overlay>
                </div>
            </v-container>
            <div class="sec-footer">
                <FooterSet />
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios';
import AppBar from "../../components/AppbarSet.vue";
import FooterSet from '../../components/FooterSet.vue';
import * as echarts from 'echarts';
import { imgicon, sessapi } from '../../apiurl.js';
import eventBus from '../../eventBus.js';
// import { getMasters, getToken, getQuotesdata, getLtpdata, getFundamental, getSSEvents, getSSHoldings, getConsolidated, getStandalone, getPeers, getMFHoldings, getReturns } from "@/components/mixins/getAPIdata.js";
import { getToken, getLtpdata, getssDetails, getQuotedata } from "@/components/mixins/getAPIdata.js";

export default {

    components: {
        FooterSet,
        AppBar
    },

    data: () => ({
        mainloader: true,
        bodytab: 0,
        valid: true,
        mobile: '',
        numberis: [
            (v) => !!v || "Your mobile number is required",
            (v) =>
                /^[6-9][0-9]{9}$/.test(v) || "mobile number must be 10 Digit Number",
        ],

        dashitems: [
            { txt: 'Overview' },
            { txt: 'Fundamental' },
            { txt: 'Financials' },
            { txt: 'Peers' },
            { txt: 'Holdings' },
            { txt: 'Events' },
            { txt: 'News' },
        ],
        menudata: {},
        master: {},

        chartitems: [
            { txt: '1M' },
            { txt: '3M' },
            { txt: '6M' },
            { txt: '1YR' },
            { txt: '3YR' },
            { txt: '5YR' },
            { txt: 'MAX' },
        ],
        chartdate: 0,
        Fundamentalsfield: {
            'PE Ratio': '',
            'Sector PE': '',
            'EVEBITDA': '',
            'PB Ratio': '',
            'Sector PB': '',
            'Dividend Yield': '',
            'ROCE': '',
            'ROE': '',
            'Debt to equity': '',
            'Price to Sale': '',
            'Book Value': '',
            'Face Value': '',
        },

        financialtab: 0,
        financialsearch: null,
        financialitem: {},
        fin_fiter: 'stockFinancialsStandalone',
        fin_table: 'y5',

        peerstab: 0,
        peersearch: null,
        peeritem: [],
        peer_table: 'dividend_yield_percent',

        pricecompar: [],

        holdtab: 0,
        shareholdings: [],
        mfholdings: [],
        mfsearch: null,
        mf_table: 'mf_holding_percent',

        eventtab: 0,
        eventchip: 'announcement',

        imageicon: '',
        usdata: null,
        fff: {
            'l': {
                'n': ['secured_loans', 'unsecured_loans', 'deferred_tax_assets__and__liabilities', 'other_long_term_liabilities', 'long_term_trade_payables'],
                'c': ['trade_payables', 'other_current_liabilities', 'short_term_borrowings', 'short_term_provisions']
            },
            'a': {
                'n': ['gross_block', 'non_current_investments', 'long_term_loans__and__advances', 'other_non_current_assets'],
                'c': ['currents_investments', 'inventories', 'sundry_debtors', 'cash_and_bank', 'other_current_assets', 'short_term_loans_and_advances']
            },
            // revenue
            'r': ['sales_turnover', 'less_inter_divisional_transfers', 'less_sales_returns', 'less_excise',],
            // operating_profit
            'o': ['revenue', 'expenditure'],
            // expenditure
            't': ['increase_and_decrease_in_stock', 'raw_material_consumed', 'power__and__fuel_cost', 'employee_cost', 'general_and_administration_expenses', 'selling_and_distribution_expenses', 'miscellaneous_expenses', 'less_expenses_capitalised', 'operating__and__manufacturing_expenses'],
            // profit_after_tax
            'p': ['profit_before_tax', 'provision_for_tax']
        },
        finexpanded: [],

        newstypes: [
            { txt: 'Daily', key: 'day', tit: "Today's" },
            { txt: 'Weekly', key: 'weekly', tit: "Week's" },
            { txt: 'Monthly', key: 'monthly', tit: "Month's" },
            // { txt: 'Older news', key: 'older', tit: '' }
        ],
        totalnews: 0,
        allnews: [],
        newstab: 0,
        newschip: {},
        newsloading: null,

        stockreturns: [],
    }),
    computed: {
        financialheader() {
            return [
                { text: 'Financial Years', value: 'exp', filter: this.setFinexpand, sortable: false, class: 'fin-w-head pl-md-6 pl-4' },
                { text: 'MM YYYY', value: 'y5', sortable: false, align: 'right', class: 'd-none d-md-table-cell' },
                { text: 'MM YYYY', value: 'y4', sortable: false, align: 'right', class: 'd-none d-md-table-cell' },
                { text: 'MM YYYY', value: 'y3', sortable: false, align: 'right', class: 'd-none d-md-table-cell' },
                { text: 'MM YYYY', value: 'y2', sortable: false, align: 'right', class: 'd-none d-md-table-cell' },
                { text: 'MM YYYY', value: 'y1', sortable: false, align: 'right', class: 'pr-md-6 pr-4 d-none d-md-table-cell' },
                { text: 'MM YYYY', value: 'y0', sortable: false, align: 'right', class: 'pr-4 d-md-none' },
            ]
        },
        peerheader() {
            return [
                { text: 'Stocks', value: 'SYMBOL', sortable: false, class: 'peer-w-head pl-md-6 pl-4' },
                { text: 'LTP', value: 'ltp', sortable: false, align: 'right', class: 'd-none d-md-table-cell' },
                { text: 'Mkt. Cap', value: 'market_cap', sortable: false, align: 'right', class: 'ws-p d-none d-md-table-cell' },
                { text: 'PE Ratio', value: 'pe', sortable: false, align: 'right', class: 'ws-p d-none d-md-table-cell' },
                { text: 'PB Ratio', value: 'price_book_value', sortable: false, align: 'right', class: 'ws-p d-none d-md-table-cell' },
                { text: 'ROCE%', value: 'roce_percent', sortable: false, align: 'right', class: 'd-none d-md-table-cell' },
                { text: 'Evebitda', value: 'ev_ebitda', sortable: false, align: 'right', class: 'd-none d-md-table-cell' },
                { text: 'Debt to EQ', value: 'debt_to_equity', sortable: false, align: 'right', class: 'ws-p d-none d-md-table-cell' },
                { text: 'Div yield', value: 'dividend_yield_percent', sortable: false, align: 'right', class: 'pr-md-6 pr-4 ws-p d-none d-md-table-cell' },
                { text: 'Peers', value: 'peers', sortable: false, align: 'right', class: 'pr-md-6 pr-4 ws-p d-md-none' },
            ]
        },
        holdingheader() {
            return [
                { text: 'Investor', value: 'investor', sortable: false, class: 'hold-w-head' },
                { text: 'Holding %', value: 'holding', sortable: false, align: 'right' },
                // { text: 'No. of shares', value: 'shares', sortable: false, align: 'right' }
            ]
        },
        mfholdheader() {
            return [
                { text: 'Funds', value: 'mutual_fund', sortable: false, class: 'mf-w-head pl-md-6 pl-4' },
                { text: 'Mkt. cap held%', value: 'market_cap_Held', sortable: false, align: 'right', class: 'ws-p d-none d-md-table-cell' },
                { text: 'AUM', value: 'mf_aum', sortable: false, align: 'right', class: 'd-none d-md-table-cell' },
                { text: 'Weight%', value: 'mf_holding_percent', sortable: false, align: 'right', class: 'pr-md-6 pr-4 d-none d-md-table-cell' },
                { text: 'Trend', value: 'mftrend', sortable: false, align: 'right', class: 'pr-4 d-md-none' },
                // { text: 'Mkt. value (cr)', value: 'market_value', sortable: false, align: 'right', class: 'pr-6 ws-p' },
            ]
        }
    },
    async created() {
        this.usdata = await getToken();
        // this.usdata = genuid;
        // this.master = await getMasters();
        // this.master = masjson;

        let params = this.$route.params;
        let path = `NSE:${this.$route.path.split('/stocks/')[1].toUpperCase()}-EQ`;
        let local = localStorage.getItem('Cdfgh=')
        if (params.tsym) {
            this.checkScript(params);
        } else if (path && path != 'NSE:SINGLE-EQ') {
            this.checkScript(path);
        } else if (local && local.includes(':')) {
            this.checkScript(local);
        } else {
            this.$router.push(`/stocks`)
        }
        // this.usdata['mas'] = this.master[path]
    },
    mounted() {
        var styleElement = document.createElement("style");
        styleElement.appendChild(document.createTextNode("::-webkit-scrollbar {width: 0px; height:0px} *, html, body, .v-application, .v-application .display-1, .v-application .headline, .v-application .title, .v-application .subtitle-1, .v-application .subtitle-2, .v-application .body-1, .v-application .body-2, .v-application .caption, .v-application .overline { font-family: 'Inter var' !important; }"));
        document.getElementsByTagName("head")[0].appendChild(styleElement);

        eventBus.$on('custom-event', (params) => {
            this.mainloader = true;
            this.clearData();
            this.checkScript(params);
        })

        this.newschip = this.newstypes[0];
        // var element = document.getElementById('price');
        // element.scrollIntoView();
    },
    beforeDestroy() {
        eventBus.$off('custom-event')
    },
    methods: {
        async checkScript(params) {
            let quotesdata = await getQuotedata(params.tsym ? params.tsym : params);
            if (quotesdata && quotesdata.symname && !quotesdata.msg) {
                this.setSingleData(quotesdata.token, quotesdata.exch, quotesdata.tsym, quotesdata)
                localStorage.setItem('Cdfgh=', params.tsym ? params.tsym : params)
            } else {
                this.$router.push(`/stocks`)
            }
        },
        imageLoadError() {
            this.imageicon = null;
        },
        async setSingleData(token, exch, tsym, quotesdata) {
            this.imageicon = `${imgicon}${tsym.split('-')[0]}.png`;
            this.menudata = [];
            this.usdata['mas'] = [quotesdata.token, quotesdata.symname, quotesdata.ti, quotesdata.ls, ""]
            let ltpraw = await getLtpdata([{ "token": token, "exch": exch }]);
            var data = await getssDetails(`${exch}:${tsym}`);
            this.mainloader = false;
            this.menudata.push(quotesdata);
            this.menudata['g'] = ltpraw.data[`${token}`];
            this.menudata[0].lp = Number(quotesdata.lp) ? Number(quotesdata.lp) : Number(this.menudata.g.lp)
            this.menudata['ch'] = this.menudata.g ? (Number(this.menudata.g.lp) - Number(this.menudata.g.close)).toFixed(2) : '';
            this.menudata['d'] = data.stockDescription ? data.stockDescription : '';

            if (data && data.fundamental && data.fundamental[0]) {
                this.menudata['f'] = data.fundamental[0]
                this.Fundamentalsfield = {
                    'PE Ratio': data.fundamental[0]['pe'],
                    'Sector PE': data.fundamental[0]['sector_pe'],
                    'EVEBITDA': data.fundamental[0]['ev_ebitda'],
                    'PB Ratio': data.fundamental[0]['price_book_value'],
                    'EPS': data.fundamental[0]['eps'],
                    'Dividend Yield': data.fundamental[0]['dividend_yield_percent'],
                    'ROCE': data.fundamental[0]['roce_percent'],
                    'ROE': data.fundamental[0]['roe_percent'],
                    'Debt to equity': data.fundamental[0]['debt_to_equity'],
                    'Price to Sale': data.fundamental[0]['sales_to_working_capital'],
                    'Book Value': data.fundamental[0]['book_value'],
                    'Face Value': data.fundamental[0]['fv'],
                };
            } else {
                this.Fundamentalsfield = [];
            }

            if (data.returns) {
                this.stockreturns = data.returns
            } else {
                this.stockreturns = [];
            }

            if (data.stockFinancialsConsolidated && data.stockFinancialsStandalone && Object.keys(data.stockFinancialsConsolidated).length == 3 && Object.keys(data.stockFinancialsStandalone).length == 3) {
                this.financialitem['stockFinancialsConsolidated'] = {};
                this.financialitem['stockFinancialsStandalone'] = {};
                for (const [keysss, valuesss] of Object.entries(this.financialitem)) {
                    this.financialitem[keysss]['balanceSheet'] = [];
                    this.financialitem[keysss]['cashflowSheet'] = [];
                    this.financialitem[keysss]['incomeSheet'] = [];
                    for (const [keyss, valuess] of Object.entries(data[keysss])) {
                        for (const [keys, values] of Object.entries(valuess)) {
                            for (const [key, value] of Object.entries(values)) {
                                // .replace(/Bal|Cash|FH|FR|Inc|%|_/gi, '')
                                var index = this.financialitem[keysss][keyss].findIndex(p => p.name == key);
                                if (keyss && keys && key != "year_end_date" && index == -1) {
                                    this.financialitem[keysss][keyss].push({
                                        'name': key,
                                        'sym': key.includes('_percent') ? '%' : '₹',
                                        'y1': valuess[0] && Number(valuess[0][key]) ? Number(valuess[0][key]).toFixed(2) : 0,
                                        'y2': valuess[1] && Number(valuess[1][key]) ? Number(valuess[1][key]).toFixed(2) : 0,
                                        'y3': valuess[2] && Number(valuess[2][key]) ? Number(valuess[2][key]).toFixed(2) : 0,
                                        'y4': valuess[3] && Number(valuess[3][key]) ? Number(valuess[3][key]).toFixed(2) : 0,
                                        'y5': valuess[4] && Number(valuess[4][key]) ? Number(valuess[4][key]).toFixed(2) : 0,
                                        'exp': keyss == 'balanceSheet' ? this.menudata.f.industry.includes('Bank') ? (key == 'total_assets') ? 'total_assets' : (key == 'total_liabilities') ? 'total_liabilities' : '' : (key == 'total_current_assets') ? 'total_current_assets' : (key == 'total_non_current_assets') ? 'total_non_current_assets' : (key == 'total_current_liabilities') ? 'total_current_liabilities' : (key == 'total_non-current_liabilities') ? 'total_non-current_liabilities' : '' : keyss == 'incomeSheet' ? (key == 'revenue') ? 'Revenue' : (key == 'operating_profit') ? 'operating_profit' : (key == 'expenditure') ? 'Expenditure' : (key == 'profit_before_tax') ? 'profit_before_tax' : (key == 'tax') ? 'Tax' : (key == 'profit_after_tax') ? 'Profit_After_Tax' : '' : '',
                                        // 'mgp': keyss == 'balanceSheet' ? (this.fff.l.n.includes(key) || this.fff.l.c.includes(key)) ? 'Liabilities' : (this.fff.a.n.includes(key) || this.fff.a.c.includes(key)) ? 'Asset' : '' : keyss == 'incomeSheet' ? this.fff.r.includes(key) ? 'Revenue' : this.fff.o.includes(key) ? 'operating_profit' : this.fff.t.includes(key) ? 'Expenditure' : this.fff.p.includes(key) ? 'Profit_After_Tax' : '' : '',
                                        'idx': keyss == 'incomeSheet' ? (key == 'revenue') ? '0' : (key == 'operating_profit') ? '2' : (key == 'expenditure') ? '1' : (key == 'profit_before_tax') ? '3' : (key == 'tax') ? '4' : (key == 'profit_after_tax') ? '5' : '' : keyss == 'balanceSheet' ? (key == 'total_current_assets') ? '0' : (key == 'total_non_current_assets') ? '1' : (key == 'total_current_liabilities') ? '2' : (key == 'total_non-current_liabilities') ? '3' : '' : ''
                                        // 'gp': keyss == 'balanceSheet' ? this.fff.l.n.includes(key) ? 'Liabilities_Non-Current' : this.fff.l.c.includes(key) ? 'Liabilities_Current' : this.fff.a.n.includes(key) ? 'Asset_Non-Current' : this.fff.a.c.includes(key) ? 'Asset_Current' : '' : keyss == 'incomeSheet' ? this.fff.r.includes(key) ? 'Revenue' : this.fff.t.includes(key) ? 'Expenditure' : this.fff.p.includes(key) ? 'Profit_After_Tax' : '' : ''
                                    })
                                    this.financialitem[keysss]['valuevalue'] = value + valuesss
                                } else if (key == "year_end_date") {
                                    this.financialheader[5].text = valuess[0] ? (new Date(valuess[0][key])).toLocaleString('dafault', { month: 'short', year: 'numeric' }) : 'MM YYYY'
                                    this.financialheader[4].text = valuess[1] ? (new Date(valuess[1][key])).toLocaleString('dafault', { month: 'short', year: 'numeric' }) : 'MM YYYY'
                                    this.financialheader[3].text = valuess[2] ? (new Date(valuess[2][key])).toLocaleString('dafault', { month: 'short', year: 'numeric' }) : 'MM YYYY'
                                    this.financialheader[2].text = valuess[3] ? (new Date(valuess[3][key])).toLocaleString('dafault', { month: 'short', year: 'numeric' }) : 'MM YYYY'
                                    this.financialheader[1].text = valuess[4] ? (new Date(valuess[4][key])).toLocaleString('dafault', { month: 'short', year: 'numeric' }) : 'MM YYYY'
                                }
                            }
                        }
                    }
                }
                this.setFinchartdata();
            } else {
                this.financialitem = [];
            }

            if (data.peersComparison && data.peersComparison.peers && data.peersComparison.peers.length > 0) {
                let peers = [];
                data.peersComparison.peers.map((element) => { peers.push({ "token": element.zebuToken ? element.zebuToken : '0', "exch": element.SYMBOL.split(':')[0] }) })
                let ltpraws = await getLtpdata(peers);
                data.peersComparison.peers.map((element) => {
                    element['ltp'] = element.zebuToken ? ltpraws.data[element.zebuToken] : null
                })
                this.peeritem = data.peersComparison.peers;
                data.peersComparison.stock[0]['ltp'] = ltpraw.data[`${token}`];
                this.peeritem.unshift(data.peersComparison.stock[0]);
            } else {
                this.peeritem = [];
            }

            if (data.peerComparisonChart && Object.keys(data.peerComparisonChart).length > 0) {
                this.pricecompar['dates'] = [];
                this.pricecompar['script'] = [];
                this.pricecompar['closes'] = [];
                var colors = ['#148564', '#7CD36E', '#F9CD6C', '#FDEBC4', '#DEDEDE'];
                Object.entries(data.peerComparisonChart).forEach(([key, value], i) => {
                    this.pricecompar.script.push(key.split(':')[1].split('-')[0]);
                    if (value.date.length == 61 && this.pricecompar.dates.length != 61) {
                        value.date.map((ele) => { this.pricecompar.dates.push((new Date(ele)).toLocaleString('dafault', { month: 'short', year: '2-digit' })) })
                    }
                    this.pricecompar.closes.push({
                        name: key.split(':')[1].split('-')[0],
                        type: 'line',
                        symbol: 'none',
                        sampling: 'lttb',
                        data: value.close.slice(1),
                        color: colors[i],
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: colors[i] + '20'
                                },
                                {
                                    offset: 1,
                                    color: colors[i]
                                }
                            ])
                        },
                    })
                });
                this.setPricechart();
            } else {
                this.pricecompar = [];
            }

            if (data.shareholdings && data.shareholdings.length > 0) {
                this.shareholdings['all'] = data.shareholdings
                this.shareholdings['table'] = [
                    { "color": "#148564", "holding": 100, "shares": 'promoters', "investor": 'Total Promoter Holding' },
                    { "color": "#7CD36E", "holding": 100, "shares": 'fii_fpi', "investor": 'Foreign Institutions' },
                    { "color": "#F9CD6C", "holding": 100, "shares": 'dii', "investor": 'Other Domestic Institutions' },
                    { "color": "#FDEBC4", "holding": 100, "shares": 'retail_and_others', "investor": 'Retail and other' },
                    { "color": "#DEDEDE", "holding": 100, "shares": 'mutual funds', "investor": 'Mutual Funds' },
                ]
                this.shareholdings['x'] = 0;
                this.shareholdings['y'] = 'promoters';
                this.setHoldchartdata();
            } else {
                this.shareholdings = [];
            }

            if (data.MFholdings && data.MFholdings.length > 0) {
                this.mfholdings = data.MFholdings
            } else {
                this.mfholdings = [];
            }

            if (data.stockEvents && Object.keys(data.stockEvents).length > 0) {
                this.menudata['events'] = data.stockEvents
            } else {
                this.menudata['events'] = {};
            }
            window.scrollTo(0, 0);
            this.getNews();

            // console.log('this.financialitem', this.financialitem);
            // console.log('this.shareholdings', this.shareholdings);
            // console.log('this.MFholdings', this.MFholdings);
            // this.$emit('snack', 2, data.msg ? data.msg : data)            
            // console.log("this.menudata", this.menudata);
        },
        clearData() {
            this.menudata = [];
            this.Fundamentalsfield = [];
            this.stockreturns = [];
            this.financialitem = [];
            this.peeritem = [];
            this.pricecompar = [];
            this.shareholdings = [];
            this.mfholdings = [];
            echarts.dispose(document.getElementById('financialchart'));
            echarts.dispose(document.getElementById('holdchart'));
            echarts.dispose(document.getElementById('pricechart'));
        },

        setFinchartdata() {
            let dates = [];
            let values = { '0': [], '1': [], '2': [] };
            this.financialitem['chart'] = true;
            if (this.financialitem[this.fin_fiter].balanceSheet.length > 0 && this.financialitem[this.fin_fiter].cashflowSheet.length > 0 && this.financialitem[this.fin_fiter].incomeSheet.length > 0) {
                let head = this.financialheader.slice(1, 6);
                let o = null;
                let t = null;
                let r = null;
                var tab = this.financialtab
                if (this.financialtab != 2) {
                    o = this.financialitem[this.fin_fiter][tab == 0 ? 'incomeSheet' : 'balanceSheet'].findIndex(x => x.name == (tab == 0 ? 'revenue' : 'total_assets'));
                    t = this.financialitem[this.fin_fiter][tab == 0 ? 'incomeSheet' : 'balanceSheet'].findIndex(x => x.name == (tab == 0 ? 'expenditure' : 'total_liabilities'));
                    r = this.financialitem[this.fin_fiter][tab == 0 ? 'incomeSheet' : 'balanceSheet'].findIndex(x => x.name == (tab == 0 ? 'profit_after_tax' : 'deferred_tax_assets__and__liabilities'));
                }
                for (let b = 0; b < head.length; b++) {
                    dates.push(head[b].text);
                    values[0].unshift(this.financialitem[this.fin_fiter][tab == 0 ? 'incomeSheet' : tab == 1 ? 'balanceSheet' : 'cashflowSheet'][tab == 2 ? 2 : o][`y${b + 1}`]);
                    values[1].unshift(this.financialitem[this.fin_fiter][tab == 0 ? 'incomeSheet' : tab == 1 ? 'balanceSheet' : 'cashflowSheet'][tab == 2 ? 1 : t][`y${b + 1}`]);
                    values[2].unshift(this.financialitem[this.fin_fiter][tab == 0 ? 'incomeSheet' : tab == 1 ? 'balanceSheet' : 'cashflowSheet'][tab == 2 ? 0 : r][`y${b + 1}`]);
                }
                this.financialitem['chart'] = false;
            }
            this.putFinancialUpdates(dates, values)
        },
        setFinancialchart(dates, values) {
            var myChart = echarts.init(document.getElementById('financialchart'));
            var option = {
                grid: {
                    left: 64,
                    right: 24,
                    y: '4%'
                },
                color: [
                    '#148564',
                    '#7CD36E',
                    '#F9CD6C',
                ],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    // , 'Debt to assets'
                    data: this.financialtab == 0 ? ['Revenue', 'Expenditure', 'Profit After Tax'] : this.financialtab == 1 ? ['Assets', 'Liabilities'] : ['Operating', 'Investing', 'Financing'],
                    orient: 'horizontal',
                    bottom: 24,
                    top: 'bottom'
                },
                toolbox: {
                    show: true,
                    orient: 'vertical',
                    left: 'right',
                    top: 'center'
                },
                xAxis: [
                    {
                        type: 'category',
                        axisTick: { show: false },
                        data: dates ? dates : false
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: val => `${val / 1000} K`
                        },
                    }
                ],
                series: [
                    {
                        name: this.financialtab == 0 ? 'Revenue' : this.financialtab == 1 ? 'Assets' : 'Operating',
                        type: 'bar',
                        label: false,
                        emphasis: {
                            focus: 'series'
                        },
                        data: values && values[0] ? values[0] : false
                    },
                    {
                        name: this.financialtab == 0 ? 'Expenditure' : this.financialtab == 1 ? 'Liabilities' : 'Investing',
                        type: 'bar',
                        label: false,
                        emphasis: {
                            focus: 'series'
                        },
                        data: values && values[1] ? values[1] : false
                    },
                    {
                        name: this.financialtab == 0 ? 'Profit After Tax' : this.financialtab == 1 ? 'Debt to assets' : 'Financing',
                        type: this.financialtab == 2 ? 'bar' : 'line',
                        label: false,
                        emphasis: {
                            focus: 'series'
                        },
                        data: this.financialtab != 1 ? values && values[2] ? values[2] : [] : false
                    }
                ]
            };
            option && myChart.setOption(option);

            window.addEventListener('resize', function () {
                myChart.resize();
            })
        },
        putFinancialUpdates(dates, values) {
            echarts.dispose(document.getElementById('financialchart'));
            this.setFinancialchart(dates, values);
        },
        setPricechart() {
            var myChart = echarts.init(document.getElementById('pricechart'));

            var option = {
                grid: {
                    left: 44,
                    right: 24,
                    y: '4%',
                    // bottom: 32
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                },
                legend: {
                    data: this.pricecompar.script,
                    orient: 'horizontal',
                    bottom: 240,
                    top: 'bottom'
                },
                toolbox: {
                    show: true,
                    orient: 'vertical',
                    left: 'right',
                    top: 'center'
                },
                xAxis: {
                    type: 'category',
                    // boundaryGap: false,
                    data: this.pricecompar.dates.slice(1)
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter: '{value} %'
                    }
                    // boundaryGap: [0, '100%']
                },
                // dataZoom: [
                //     {
                //         type: 'inside',
                //         start: 0,
                //         end: 10
                //     },
                //     {
                //         start: 0,
                //         end: 10
                //     }
                // ],
                series: this.pricecompar.closes
            }
            option && myChart.setOption(option);

            window.addEventListener('resize', function () {
                myChart.resize();
            })
        },
        setHoldchartdata() {
            let dates = [];
            let values = [];
            for (let b = 0; b < this.shareholdings.all.length; b++) {
                values.unshift(Number(this.shareholdings.all[b][this.shareholdings.y].toFixed(2)));
                dates.unshift((new Date(this.shareholdings.all[b].date)).toLocaleString('dafault', { month: 'short', year: '2-digit' }));
            }

            let clr = this.shareholdings.table.findIndex(x => x.shares == this.shareholdings.y);
            clr = this.shareholdings.table[clr].color
            this.putHoldingUpdates(dates, values, clr)
        },
        setHoldingchart(dates, values, clr) {
            var myChart = echarts.init(document.getElementById('holdchart'));

            var option = {
                color: clr,
                xAxis: {
                    type: 'category',
                    data: dates,
                    splitLine: {
                        show: false
                    },
                    scale: false,
                    axisLine: {},
                },
                yAxis: {
                    type: 'value',
                    splitLine: {
                        show: false
                    },
                    // scale: false,
                    // axisLine: false,
                    max: 100,
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: '<b>{c}%</b> on {b}',
                },
                grid: {
                    left: 28,
                    containLabel: false,
                    bottom: 32,
                    top: 32,
                    right: 8
                },

                series: [
                    {
                        data: values,
                        type: 'bar',
                        label: {
                            show: true,
                            formatter: '{c}%',
                            precision: 1,
                            position: 'top',
                            valueAnimation: true,
                        }
                    }
                ]
            };
            option && myChart.setOption(option);

            window.addEventListener('resize', function () {
                myChart.resize();
            })
        },
        putHoldingUpdates(dates, values, clr) {
            echarts.dispose(document.getElementById('holdchart'));
            this.setHoldingchart(dates, values, clr);
        },
        getNews() {
            this.newsloading = true;
            this.allnews = [];
            this.totalnews = 0;
            let config = {
                method: 'get',
                url: `${sessapi}/newsfeedin?pagesize=16&pagecount=1&filterdate=${this.newschip.key}`,
            };

            let axiosThis = this;
            axios.request(config)
                .then((response) => {
                    if (response.data.data && response.data.data.length > 0) {
                        let data = response.data.data;
                        for (let v = 0; v < data.length; v++) {
                            data[v]['isdate'] = `${new Date(data[v].pubDate).toDateString().slice(3)} ${new Date(data[v].pubDate).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} IST`;
                            axiosThis.allnews.push(data[v]);
                        }
                        axiosThis.totalnews = response.data.newsCount
                        axiosThis.pagecount = response.data.Totalpages;
                    }
                    axiosThis.newsloading = false;
                })
                .catch((error) => {
                    console.log(error);
                    axiosThis.newsloading = false;
                });
        },
        differentDate(date) {
            var date1 = new Date(date);
            var date2 = new Date();
            var ov = Math.abs(date2.getTime() - date1.getTime())
            var mt = Math.round(ov / 3600000)
            var dd = mt > 60 ? Math.round(mt / 24) : 0;
            var mm = dd > 30.4166667 ? Math.round(dd / 30.4166667) : 0;
            var yy = mm > 12 ? Math.round(dd / 365) : 0;
            return `${yy != 0 ? yy : mm != 0 ? mm : dd != 0 ? dd : mt != 0 ? mt : 0} ${yy != 0 ? yy > 1 ? 'years' : 'year' : mm != 0 ? mm > 1 ? 'months' : 'month' : dd != 0 ? dd > 1 ? 'days' : 'day' : mt > 1 ? 'hours' : 'minutes'} ago`;
        },
        newsPage(n) {
            if (n) {
                window.open(n.link, "_blank");
            }
        },
        setFiltere(p) {
            this.newschip = p;
            this.getNews();
        },
        setbodyTab() {
            var element;
            if (this.bodytab == 0) {
                element = document.getElementById('overview')
            }
            else if (this.bodytab == 1) {
                element = document.getElementById('fun')
            }
            else if (this.bodytab == 2) {
                element = document.getElementById('fin')
            }
            else if (this.bodytab == 3) {
                element = document.getElementById('peers')
            }
            else if (this.bodytab == 4) {
                element = document.getElementById('hold')
            }
            else if (this.bodytab == 5) {
                element = document.getElementById('event')
            }
            else if (this.bodytab == 6) {
                element = document.getElementById('news')
            }
            element.scrollIntoView({ behavior: 'smooth' });
        },
        handleScroll(e) {
            var scrollPos = e.target.scrollTop;
            console.log(scrollPos);
            
            var fun = document.getElementById('fun').offsetTop - 216;
            var fin = document.getElementById('fin').offsetTop - 216;
            var peers = document.getElementById('peers').offsetTop - 216;
            var hold = document.getElementById('hold').offsetTop - 216;
            var event = document.getElementById('event').offsetTop - 216;
            var news = document.getElementById('news').offsetTop - 216;
            if (scrollPos >= 0 && scrollPos < fun) {
                this.bodytab = 0
            }
            else if (scrollPos > fun && scrollPos < fin) {
                this.bodytab = 1
            }
            else if (scrollPos > fin && scrollPos < peers) {
                this.bodytab = 2
            } else if (scrollPos > peers && scrollPos < hold) {
                this.bodytab = 3
            }
            else if (scrollPos > hold && scrollPos < event) {
                this.bodytab = 4
            } else if (scrollPos > event && scrollPos < news) {
                this.bodytab = 5

            } else if (scrollPos > news) {
                this.bodytab = 6
            }
        },
        finKeyname(key) {
            var abc = key.toLowerCase().replace('_percent', ' in %')
            abc = abc.replace(/[._-]/g, ' ')
            abc = abc.replace(/less|total/gi, '')
            return abc
        },
        setFinexpand(value) {
            if (value || this.financialtab == 2) {
                return true;
            }
            return value;
        },
        NumberValid($event) {
            if (($event.keyCode >= 48 && $event.keyCode <= 57) || ($event.keyCode >= 96 && $event.keyCode <= 105)) {
                // 0-9 only
            } else {
                $event.preventDefault();
            }
        },
        getCall() {
            if (this.$refs.form.validate() == true) {
                // let config = {
                //     method: 'post',
                //     url: `${ekycbe}/zebu/getSignupMobile`,
                //     headers: {
                //         'Content-Type': 'application/json'
                //     },
                //     data: JSON.stringify({ "mobile": this.mobile, "login_type": "signup" })
                // };

                // let axiosThis = this;
                // axios.request(config)
                //     .then((res) => {
                //         if (res.data.data && res.data.data == 'OTP has been sent sucessfully') {
                //             window.open(
                //                 `https://oa.mynt.in/?ref=zws&Smobile=${axiosThis.mobile}`
                //             );
                //         } else if (res.data.data && res.data.data == 'Client with this details already exists') {
                //             window.open('https://go.mynt.in/');
                //         }
                //         this.$refs.form.reset();
                //     })
                //     .catch((error) => {
                //         console.log(error);
                //     });
                window.open(
                    `https://oa.mynt.in/?ref=zws&mobile=${this.mobile}`
                );
                this.$refs.form.reset();

            }
        },
    }
}
</script>

<style>
@import '../../assets/style/style.css';
</style>