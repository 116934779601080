<template>
    <div class="no-xhide">
        <v-snackbar class="snakbar-sty pt-6 d-md-none pr-6 z-i6 rounded-pill" transition="slide-x-reverse-transition"
            v-model="snackbar" timeout="4000" :value="true" :color="snackbarclr" absolute text-color="white">
            <v-icon class="mr-2" color="#fff">mdi-alert-outline</v-icon>
            <span v-html="snackmsgbar"> </span>
            <v-icon @click="snackbar = false" class="float-right" color="#fff">mdi-close-circle</v-icon>
        </v-snackbar>
        <v-snackbar class="snakbar-sty d-none d-md-flex pt-6 pr-6 z-i6 rounded-pill mt-10"
            transition="slide-x-reverse-transition" top right v-model="snackbar" timeout="4000" :value="true"
            :color="snackbarclr" absolute text-color="white">
            <v-icon class="mr-2" color="#fff">mdi-alert-outline</v-icon>
            <span v-html="snackmsgbar"> </span>
            <v-icon @click="snackbar = false" class="float-right" color="#fff">mdi-close-circle</v-icon>
        </v-snackbar>
        <div data-aos="fade-up" class="pt-4">
            <v-card class="elevation-0 px-16 py-10 support-main-bg rounded-xl pos-rlt d-none d-md-block">
                <p class="white--text fs-50 lh-48 font-weight-bold mb-5">Insurance
                </p>
                <!-- <p class="white--text lh-28 title">
                    With Zebu Insurance, you receive expert guidance on all your insurance <br />
                    needs. Understand your policy in detail, get clear answers to your <br />
                    pressing questions, and purchase insurance all in one place.
                </p> -->
                <p class="white--text lh-28 title">
                    Maximize your financial future with insurance designed for <br> wealth creation, protection,
                    retirement, and savings. Discover how <br> these plans can help you grow and safeguard your assets
                    today!
                    <!-- You'll gain crystal-clear answers to your burning questions,<br>
                       understand your policy inside-out, and can even purchase the perfect plan all in one convenient place. <br> -->
                    <!-- Your peace of mind is just a click away! -->

                </p>
                <!-- <p class="font-weight-regular mt-2 mb-1 fs-14 white--text">Don't have an account yet? <a target="_blank"
                        rel="noopener noreferrer" class="mailto-txt1 font-weight-medium"
                        href="https://oa.mynt.in/?ref=zws-lpins">Sign up
                    </a></p> -->

                <div class="pos-abs prd-ipo-img text-right">
                    <img alt="main-bg.png" src="@/assets/product/lpins/insurance.svg" width="100%" />
                </div>
            </v-card>
            <v-card class="elevation-0 px-6 px-sm-8 py-10 support-main-bg rounded-0 d-md-none text-center">
                <p class="white--text fs-38 font-weight-bold mb-6">Insurance </p>
                <!-- <p class="white--text fs-38 font-weight-bold mb-6">Insurance made easy</p> -->
                <p class="white--text fs-18">
                    Maximize your financial future with insurance designed for <br> wealth creation, protection,
                    retirement, and savings. Discover how <br> these plans can help you grow and safeguard your assets
                    today!


                </p>
                <!-- 
                <p class="font-weight-regular mt-2 mb-1 fs-14 white--text">Don't have an account yet? <a target="_blank"
                        rel="noopener noreferrer" class="mailto-txt1 font-weight-medium"
                        href="https://oa.mynt.in/?ref=zws-lpins">Sign up
                    </a></p> -->
            </v-card>
        </div>

        <div class="px-6 px-sm-8 px-md-16 pt-8 pt-md-16 ">

            <div class="px-16 pb-3 d-none d-md-block">
                <p class="font-weight-medium black--text"><span class="display-1">Insurance made easy</span></p>
                <p class="txt-444 font-weight-light title lh-28">Unravel the Zebu Insurance difference where expertise
                    meets convenience! We offer expert guidance for all your insurance needs, making the process
                    smoother and stress free. You'll gain crystal clear answers to your burning questions, understand
                    your policy inside out, and can even purchase the perfect plan all in one convenient place. Your
                    peace of mind is just a click away!
                </p>

                <v-btn target="_blank" rel="noopener noreferrer" href="https://oa.mynt.in/?ref=zws-lpins" height="48px"
                    color="#2A2A2A" class="text-none rounded-pill elevation-0 mb-10 mt-4"><span
                        class="white--text subtitle-1 font-weight-medium px-2">Start investing</span></v-btn>
            </div>

            <div class="px-6 px-sm-8 d-md-none">
                <p class="font-weight-medium black--text"><span class="title">Insurance made easy</span></p>
                <p class="txt-444 font-weight-light fs-18 lh-28">Unravel the Zebu Insurance difference where expertise
                    meets convenience! We offer expert guidance for all your insurance needs, making the process
                    smoother and stress free. You'll gain crystal clear answers to your burning questions, understand
                    your policy inside out, and can even purchase the perfect plan all in one convenient place. Your
                    peace of mind is just a click away!</p>

                <v-btn target="_blank" rel="noopener noreferrer" href="https://oa.mynt.in/?ref=zws-lpins" height="48px"
                    color="#2A2A2A" block class="text-none rounded-pill elevation-0 mb-10 mt-4"><span
                        class="white--text subtitle-1 font-weight-medium px-2">Start investing</span></v-btn>
            </div>


            <v-card width="100%" data-aos="fade-down"
                class="box-s1 elevation-0 rounded-xl pt-16 pb-10 d-none d-md-block" outlined>
                <div class="pos-rlt">
                    <v-row no-glutters>
                        <v-col cols="6">
                            <div class="px-16">
                                <p class="subtitle-2 text-uppercase">What's special</p>
                                <p class="secondary-font mb-8">
                                    Cover your today, prepare for a brighter tomorrow.

                                </p>
                                <v-card @click="changeTois(l)" v-for="(l, k) in datas" :key="k" data-aos="fade-down"
                                    :color="activelist.id == k ? '#444444' : '#ffffff'" width="100%"
                                    class="box-s1 brd-r-16 py-3 px-6 mb-4">
                                    <p class="mb-0 title font-weight-regular"
                                        :class="activelist.id == k ? 'white--text' : 'black--text'">{{
                                            l.tit }}</p>
                                </v-card>
                            </div>
                        </v-col>
                        <v-col cols="6">
                            <div class="px-16">
                                <img data-aos="fade-up" :alt="activelist.image" :src="activelist.image" width="100%" />
                                <div data-aos="fade-down">
                                    <p class="font-weight-medium headline black--text">{{ activelist.tit }}</p>
                                    <p class="font-weight-light mt-3 black--text fs-17">{{ activelist.sub }}</p>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
            <div class="d-md-none">
                <p class="subtitle-2 text-uppercase">What's special</p>
                <p class="secondary-fn fs-28 mb-8 lh-32">Secure your today and Protect your Tomorrow</p>
                <div v-for="(l, k) in datas" :key="k" :class="k != datas.length - 1 ? 'mb-16' : ''">
                    <img data-aos="fade-up" :alt="l.image" :src="l.image" width="100%" />
                    <div data-aos="fade-down">
                        <p class="font-weight-medium fs-24 black--text">{{ l.tit }}</p>
                        <p class="font-weight-light black--text fs-18">{{ l.sub }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="pt-8 pt-md-16 mb-6">
            <!-- <div class="px-16 d-none d-md-block mb-15">
                <p class="mb-0 fs-24 text-center mb-5 black--text font-weight-medium">
                    Secure your today and Protect your Tomorrow

                </p>
                <v-row>
                    <v-col cols="12" md="4" class="text-center text-md-left" v-for="(l, k) in bestmfdata" :key="k">
                        <v-card data-aos="flip-up" width="100%"
                            class="box-s1 elevation-0 px-8 py-8 rounded-xl text-center" outlined>


                            <p class="font-weight-bold title mb-0">{{ l.tit }}</p>
                            <p class="font-weight-light fontjustfiycss">{{ l.sub }}</p>
                        </v-card>
                    </v-col>
                </v-row>
            </div> -->
            <div class="sec-seven d-none d-md-block  mb-15">
                <v-card class="mx-auto elevation-0 text-center " width="64%">
                    <p class="mb-0 fs-24 text-center mb-5 black--text font-weight-medium">
                        “Driving race cars is risky, not having life insurance is riskier” <br> - Danica Patrick



                    </p>

                    <v-row justify="center">
                        <v-col v-for="(s, n) in detailitem" :key="n" cols="12" md="6" sm="6" lg="4" class="px-4 pb-6">
                            <v-card width="256px" elevation="0" color="#E5EBEC" height="270px" data-aos="zoom-in-up"
                                class="secseven-card rounded-xl text-center pa-4 mx-auto">
                                <img class="mt-3" :alt="s.image" :src="s.image" width="50px" height="50px" />
                                <p class="font-weight-bold title mb-0">{{ s.title }}</p>
                                <!-- <p class="txt-444 font-weight-regular caption text-uppercase">{{ s.subtitle }}</p> -->
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>



            </div>

            <div class="d-md-none mb-13">

                <p class="mb-0 fs-24 text-center mb-5 black--text font-weight-medium">
                    “Driving race cars is risky, not having life insurance is riskier” <br> - Danica Patrick



                </p>

                <v-row>
                    <v-col v-for="(s, n) in detailitem" :key="n" cols="12" md="6" sm="6" lg="4" class="px-4 pb-6">
                        <v-card elevation="0" color="#E5EBEC" height="130px" data-aos="zoom-in-up"
                            class="secseven-card rounded-xl  pa-4 mx-auto">

                            <div class="d-flex">
                                <v-img class="mt-6" :alt="s.image" :src="s.image" width="50px" height="50px"></v-img>
                                <div class="ml-auto">
                                    <p class="mb-0  ml-7 bleck--text f-14 mt-3 font-weight-regular">{{ s.title }}</p>
                                </div>
                            </div>

                        </v-card>
                    </v-col>
                </v-row>

            </div>


            <div class="px-16 d-none d-md-block mb-15 ">
                <v-card width="100%" class="price-card-sty elevation-0 rounded-xl py-6 px-6 ">
                    <v-row>
                        <v-col cols="12" md="12" lg="7">
                            <p class=" font-weight-bold secondary-infont mb-1 fs-22">Not sure which Life Insurance to
                                buy?

                            </p>
                            <p class="mb-1 font-weight-regular">Talk to an Advisor right away
                                , We help you to choose best
                                insurance plan based on your needs.

                            </p>
                        </v-col>
                        <v-col cols="12" md="12" lg="5">

                            <v-container fill-height>
                                <!-- <v-text-field flat solo background-color="#ffffff" outlined hide-details
                                    v-model="marketemail" :maxlength="10"
                                    class="menu-field-num elevation-0  rounded-pill caption my-auto "
                                    label="Enter your mobile no" required :rules="mobileRules" hide-spin-buttons
                                    type="number">
                                    <template #prepend-inner>
                                        <img src="@/assets/contactus/phone.svg" width="100%" class="mx-2"
                                            alt="mail-icon">
                                    </template>
<template #append>
                                        <v-btn color="#000"  type="submit"   @click.prevent="formadataipo" :disabled="marketemail.length != 10 || loader" @click="formadataipo()"
                                            class="text-none rounded-pill elevation-0"><span
                                                class="black--text font-weight-bold white--text">Book a 
                                                call</span></v-btn>
                                    </template>
</v-text-field> -->
                                <form @submit.prevent="formadataipo">
                                    <v-text-field flat solo background-color="#ffffff" outlined hide-details
                                        v-model="marketemail" :maxlength="10"
                                        class="menu-field-num elevation-0 rounded-pill caption my-auto"
                                        label="Enter your mobile no" required :rules="mobileRules" hide-spin-buttons
                                        type="number">
                                        <template #prepend-inner>
                                            <img src="@/assets/contactus/phone.svg" width="100%" class="mx-2"
                                                alt="mail-icon" />
                                        </template>

                                        <template #append>
                                            <v-btn color="#000" type="submit"
                                                :disabled="marketemail.length !== 10 || loader"
                                                class="text-none rounded-pill elevation-0">
                                                <span class="black--text font-weight-bold white--text">
                                                    Book a call
                                                </span>
                                            </v-btn>
                                        </template>
                                    </v-text-field>
                                </form>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-card>
            </div>

            <div class="d-md-none mb-13 ">
                <v-card width="100%" class="price-card-sty py-6 elevation-0  ">
                    <v-row>
                        <v-col cols="12" md="12" lg="7" class=" px-6 ">
                            <p class=" font-weight-bold secondary-infont mb-1 fs-22">Not sure which Life Insurance to
                                buy?

                            </p>
                            <p class="mb-1 font-weight-regular">Talk to an Advisor right away
                                , We help you to choose best
                                insurance plan based on your needs.

                            </p>
                        </v-col>
                        <v-col cols="12" md="12" lg="5">

                            <v-container fill-height>
                                <v-text-field flat solo background-color="#ffffff" outlined hide-details
                                    v-model="marketemail" type="number" :maxlength="10"
                                    class="menu-field-num elevation-0  rounded-pill caption my-auto "
                                    label="Enter your mobile no" required :rules="mobileRules">
                                    <template #prepend-inner>
                                        <img src="@/assets/contactus/phone.svg" width="100%" class="mx-2"
                                            alt="mail-icon">
                                    </template>
                                    <template #append>
                                        <v-btn color="#000" :disabled="marketemail.length != 10" @click="formadataipo()"
                                            class="text-none rounded-pill elevation-0"><span
                                                class="black--text font-weight-bold white--text">book a
                                                call</span></v-btn>
                                    </template>
                                </v-text-field>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-card>
            </div>


            <div class="px-16 d-none d-md-block mb-13">
                <v-card width="100%" data-aos="fade-down" class="box-s1 elevation-0 rounded-xl ">
                    <p class="mb-0 fs-24 text-center mb-5 black--text font-weight-medium">
                        How Zebu Insurance Works?<br>
                        In 3 Easy steps Insurance made simple for You


                    </p>

                    <v-row>
                        <v-col cols="12" md="4" class="text-center text-md-left" v-for="(l, k) in steps" :key="k">
                            <v-card data-aos="flip-up" width="100%" class="box-s1 elevation-0 px-3 py-3 rounded-xl "
                                outlined>
                                <v-row>
                                    <v-col cols="3">
                                        <v-img class="mt-6" :alt="l.image" :src="l.image"></v-img>
                                    </v-col>
                                    <v-col cols="9">
                                        <p class="mb-0 bleck--text f-14 font-weight-regular">{{ l.sub }}</p>

                                        <p class="mb-0 grey--text f-12 font-weight-regular">{{ l.tit }}</p>
                                    </v-col>
                                </v-row>

                            </v-card>

                        </v-col>
                    </v-row>
                </v-card>
            </div>

            <div class="d-md-none mb-13">
                <v-card width="100%" data-aos="fade-down" class="box-s1 elevation-0 rounded-xl ">
                    <p class="mb-0 fs-24 text-center mb-5 black--text font-weight-medium">
                        How Zebu Insurance Works?<br>
                        In 3 Easy steps Insurance made simple for You


                    </p>

                    <v-row>
                        <v-col cols="12" md="4" class=" text-md-left" v-for="(l, k) in steps" :key="k">
                            <v-card data-aos="flip-up" width="100%" class="box-s1 elevation-0 px-3 py-3 rounded-xl "
                                outlined>
                                <v-row>
                                    <v-col cols="3">
                                        <v-img class="mt-6" :alt="l.image" :src="l.image"></v-img>
                                    </v-col>
                                    <v-col cols="9">
                                        <p class="mb-0 bleck--text f-14 font-weight-regular">{{ l.sub }}</p>

                                        <p class="mb-0 grey--text f-12 font-weight-regular">{{ l.tit }}</p>
                                    </v-col>
                                </v-row>

                            </v-card>

                        </v-col>
                    </v-row>
                </v-card>
            </div>



            <div class="sec-seven  mb-13">
                <!-- <v-card class="mx-auto elevation-0 text-center d-none d-md-block" width="64%">
                    <p class="secondary-font">Why Choose Zebu Insurance?
                    </p>
                    <p class="txt-444 font-weight-light title lh-28 mb-8">Insurance has never been easier. <br> We offer
                        a seamless
                        insurance buying experience,<br> ensuring simplicity and complete satisfaction for our customers
                        at every
                        step.

                    </p>

                    <v-row justify="center">
                        <v-col v-for="(s, n) in learnitems" :key="n" cols="12" md="4" class="px-4 pb-6">
                            <v-card width="256px" height="210px" data-aos="zoom-in-up"
                                class="secseven-card rounded-xl text-center pa-4 mx-auto">
                                <img data-aos="zoom-in-down" :src="s.image" width="80px" class="pa-4" :alt="s.title">
                                <p class="font-weight-bold title mb-0">{{ s.title }}</p>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card> -->

                <div class="px-16 d-none d-md-block ">
                    <v-card data-aos="fade-down" class="secfive-bg rounded-xl pa-14 box-s1x elevation-0 "
                        color="#C9EFCD" width="100%">
                        <v-row>
                            <v-col cols="12" lg="6" md="12" class="d-flex align-center my-auto pt-0">
                                <div>
                                    <!-- <p class="font-weight-medium mb-2 txt-444 text-uppercase ls-1 fs-16">Investing</p> -->
                                    <p class="secondary-font">Why Choose Zebu Insurance?</p>
                                    <p class="txt-444 font-weight-light title lh-28">
                                        At Zebu, we make insurance simple and stress-free. Our seamless process,
                                        personalized
                                        coverage options, and expert guidance ensure you’re fully informed and confident
                                        in your
                                        decisions. With Zebu, you’re not just buying insurance you’re securing your
                                        future with
                                        confidence.

                                    </p>
                                </div>
                            </v-col>
                            <v-col cols="12" lg="6" md="12">
                                <v-row>
                                    <v-col cols="12" v-for="(l, k) in learnitems" :key="k">
                                        <v-card data-aos="flip-up" class="elevation-0  rounded-xl " outlined>

                                            <v-list>
                                                <template>



                                                    <v-list-item>
                                                        <v-list-item-avatar>
                                                            <v-img :alt="l.image" :src="l.image"></v-img>
                                                        </v-list-item-avatar>

                                                        <v-list-item-content>
                                                            <v-list-item-title class="font-weight-bold">{{ l.title
                                                                }}</v-list-item-title>

                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                            </v-list>
                                        </v-card>
                                    </v-col>
                                </v-row>

                            </v-col>
                        </v-row>
                    </v-card>
                </div>

                <div class="d-md-none">
                    <v-card data-aos="fade-down" class="secfive-bg  pa-5 pt-10 box-s1x elevation-0 " color="#C9EFCD"
                        width="100%">
                        <v-row>
                            <v-col cols="12" lg="6" md="12" class="d-flex align-center my-auto pt-0">
                                <div>
                                    <!-- <p class="font-weight-medium mb-2 txt-444 text-uppercase ls-1 fs-16">Investing</p> -->
                                    <p class="secondary-font">Why Choose Zebu Insurance?</p>
                                    <p class="txt-444 font-weight-light title lh-28">
                                        At Zebu, we make insurance simple and stress-free. Our seamless process,
                                        personalized
                                        coverage options, and expert guidance ensure you’re fully informed and confident
                                        in your
                                        decisions. With Zebu, you’re not just buying insurance you’re securing your
                                        future with
                                        confidence.

                                    </p>
                                </div>
                            </v-col>
                            <v-col cols="12" lg="6" md="12">
                                <v-row>
                                    <v-col cols="12" v-for="(l, k) in learnitems" :key="k">
                                        <v-card data-aos="flip-up" class="elevation-0  rounded-xl " outlined>

                                            <v-list>
                                                <template>



                                                    <v-list-item>
                                                        <v-list-item-avatar>
                                                            <v-img :alt="l.image" :src="l.image"></v-img>
                                                        </v-list-item-avatar>

                                                        <v-list-item-content>
                                                            <v-list-item-title class="font-weight-bold">{{ l.title
                                                                }}</v-list-item-title>

                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                            </v-list>
                                        </v-card>
                                    </v-col>
                                </v-row>

                            </v-col>
                        </v-row>
                    </v-card>

                </div>


                <div data-aos="fade-down" class="secfive-bg rounded-xl px-lg-14 pt-14  box-s1x elevation-0 " width="100%">

                    <p class="mb-0 fs-24  text-center  black--text font-weight-medium">
                        Insurance Partner
                    </p>
                    <div data-aos="flip-up" class="sec-four mt-8  py-0 py-md-2">
                        <v-card class="pt-2 pb-1 px-md-3 elevation-0" width="100%">
                            <div class="wrapper">
                                <div class="slider">
                                    <div class="slide-track">
                                        <span v-for="logo in insparlogo" :key="logo.id" class="slide">
                                            <v-img width="190" height="100" :alt="logo.image" :src="logo.image"></v-img>
                                        </span>
                                        <span v-for="logo in insparlogo" :key="`${logo.id}-duplicate`" class="slide">
                                            <v-img width="190" height="100" :alt="logo.image" :src="logo.image"></v-img>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </v-card>
                    </div>
                </div>


            </div>
        </div>


        <div class="py-md-16 rounded-xl">
            <v-card data-aos="fade-down" class="rounded-0 pb-10 mb-10 px-sm-3 elevation-0 d-md-none" width="100%">
            </v-card>
            <div data-aos="fade-up" class="secineight-bg mb-8 mb-md-0">
                <v-card color="transparent"
                    class="secineight-card mt-16 pos-rlt mx-auto brd-r-32 elevation-0 d-none d-md-block" width="90%">
                    <img src="@/assets/sec-eight-bg.webp" width="100%" alt="sec-eight-bg" />

                    <div class="seceight-ctn pos-abs">
                        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="getCall()">
                            <v-row no-gutters class="pt-10">
                                <v-col cols="9" class="pr-4">
                                    <v-text-field v-model="mobile" @keypress="NumberValid($event)"
                                        @keyup.enter="getCall()" :maxlength="10" :rules="numberis" required
                                        hide-spin-buttons flat solo background-color="#595959"
                                        class="menu-field-seceight elevation-0 mr-3 rounded-pill caption txt-field"
                                        label="Enter mobile number to begin">
                                        <template #prepend-inner>
                                            <img src="@/assets/phone-icon white.svg" width="100%" class="mx-2"
                                                alt="phone-icon" />
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-btn type="submit" block height="48px" :color="valid ? '#FFF07E' : '#FFF07Ec2'"
                                        class="text-none rounded-pill elevation-0"><span
                                            class="black--text font-weight-bold">Sign
                                            up</span></v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </div>
                </v-card>
                <v-card color="#282828" class="secineight-card pos-rlt mx-auto brd-r-32 elevation-0 d-md-none pa-6"
                    width="90%">
                    <img src="@/assets/Msec-eight-bg.svg" width="100%" alt="sec-eight-bg" />
                    <!-- <v-img src="@/assets/Msec-eight-bg.svg" width="100%" alt="sec-eight-bg"></v-img> -->

                    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="getCall()">
                        <v-text-field v-model="mobile" @keypress="NumberValid($event)" @keyup.enter="getCall()"
                            :maxlength="10" :rules="numberis" required hide-spin-buttons flat solo
                            background-color="#595959"
                            class="menu-field-seceight elevation-0 rounded-pill caption txt-field"
                            label="Enter mobile number to begin">
                            <template #prepend-inner>
                                <img src="@/assets/phone-icon white.svg" width="100%" class="mx-2" alt="phone-icon" />
                            </template>
                        </v-text-field>
                        <v-btn type="submit" block height="48px" :color="valid ? '#FFF07E' : '#FFF07Ec2'"
                            class="text-none rounded-pill elevation-0"><span class="black--text font-weight-bold">Sign
                                up</span></v-btn>
                    </v-form>
                </v-card>
            </div>
        </div>

        <div>

            <v-expand-transition class="">
                <v-card v-show="banndialog" class="elevation-0">
                    <div class=" ">

                        <v-card v-if="showbox" elevation="2" color="#E5EBEC" width="100%"
                            class="webbannerinshow rounded-xl ">
                            <v-toolbar elevation="0" color="#E5EBEC" dense class=" mt-1 pa-0">
                                <p class="font-weight-bold mb-4 fs-18 mt-2 black--text">Sign Up Now! </p>

                                <v-spacer></v-spacer>

                                <v-btn class="mr-0" icon @click="closecard()">
                                    <v-icon color="black">mdi-close</v-icon>
                                </v-btn>
                            </v-toolbar>
                            <div class="px-4 pb-4">
                                <form @submit.prevent="formadataipo">

                                    <v-text-field v-model="formaname" :disabled="loader" :rules="nameRules" required
                                        block flat solo background-color="#ffffff"
                                        class="elevation-0  rounded-pill caption" label="Enter your name">
                                        <template #prepend-inner>
                                            <img src="@/assets/contactus/user-c-frame.svg" width="100%" class="mx-2"
                                                alt="user-icon" />
                                        </template>
                                    </v-text-field>

                                    <v-text-field v-model="frommobile" :maxlength="10" :disabled="loader"
                                        :rules="mobileRules" required block flat solo background-color="#ffffff"
                                        class="elevation-0 rounded-pill caption" label="Enter your mobile number">
                                        <template #prepend-inner>
                                            <img src="@/assets/contactus/phone.svg" width="100%" class="mx-2"
                                                alt="phone-icon" />
                                        </template>
                                    </v-text-field>
                                    <v-text-field v-model="formemail" :disabled="loader" :rules="emailRules" required
                                        block flat solo background-color="#ffffff"
                                        class="elevation-0 rounded-pill caption" label="Enter your email address">
                                        <template #prepend-inner>
                                            <img src="@/assets/contactus/send-message.svg" width="100%" class="mx-2"
                                                alt="send-msg-icon" />
                                        </template>
                                    </v-text-field>
                                    <v-row>

                                        <v-col cols="12">

                                            <v-btn type="submit" block large rounded
                                                :color="valid ? '#FFF07E' : '#FFF07Ec2'" :loading="loader" height="48px"
                                                class="elevation-0 mr-7 text-none">
                                                <span class="black--text px-7">Submit</span>
                                            </v-btn>
                                        </v-col>

                                    </v-row>
                                </form>
                            </div>
                        </v-card>

                        <div class="webbannerins" v-if="signupbtn">

                            <v-btn @click="opencard()" class=" mx-2 white--text text-none elevation-0  " rounded large
                                :color="valid ? '#0037B7' : '#0037B7'">
                                <svg width="25" height="20" viewBox="0 0 460 512" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M71.25 0H336.75L337.75 0.5L346.25 2.25L352.25 4L357.5 6L363.75 9L367.75 11.25L372 14L375 16.25L378.5 19L382.25 22.5L386 26.25L390.5 31.75L393.5 36L396.5 41L399 45.75L401.5 51.75L403.5 57.75L405 63.75L406 70.25L406.5 76.25V205L405.75 208.75L404.5 211.75L402.75 214.75L400.5 217.5H400V218L397.25 220L393.75 221.75L390.25 222.75L388.5 223H384L380.5 222.25L377.5 221L374.5 219.25L371.5 216.5L369.25 213.5L367.5 210L366.5 206.25L366.25 203.75L366 79L365.25 72L363.25 65.25L361.25 61L358.5 56.5L355.25 52.75L354.25 51.5L350.75 48.75L348.25 46.75L342.75 43.75L336 41.5L333.75 41L328.75 40.5L323.25 40.25H82.75L75 40.75L70 41.75L64.75 43.5L58.75 46.75L54.75 50L52.25 52.25L50.25 54.5L47.25 58.5L44.25 64L42.25 69.75L41.25 75.25L41 78V433.75L41.5 438.5L42.5 443L44.25 448L46.75 452.75L49 456L52.25 459.75L55.25 462.5L59 465.25L64.5 468.25L68 469.5L71.25 470.5L76.75 471.25L79.5 471.5L147 471.75L152 472.25L155.75 473.5L159.5 475.75L162.5 478.5L164.5 481.25L166.5 485.25L167.5 489.5V494.25L166.75 497.75L165.5 501L163.25 504.5L161 507L157.75 509.25L154.25 511L151.75 511.75L151.5 512H70.5L70 511.5L62.25 510L55.75 508.25L48.5 505.5L41.5 502L36.25 498.75L31.75 495.5L29 493.25L25.75 490.25L23.75 488.5L20.25 484.75L16.75 480.5L13.75 476.25L11.75 473L9.75 469.5L7 464L4.5 457.5L2.5 450.5L1.5 445.5L0.75 440L0.25 431V80L0.75 71.5L2.25 62.5L4.25 55.25L6.75 48.5L10 41.75L13.5 36L16 32.5L18.5 29.25L20.75 26.75L22.5 24.75L27.25 20L29.25 18.25L32.25 16L36 13.25L43 9L49.25 6L56 3.5L63.75 1.5L70.75 0.25L71.25 0Z"
                                        fill="white" />
                                    <path
                                        d="M398 271.75H401.25L407.5 272.25L413 273.25L418.25 274.75L423.5 276.75L427.75 278.75L432.75 281.75L437 285L439.75 287.25L445 292.5L448.25 296.75L450.75 300.5L453 304.5L455 308.75L457.25 315L458.75 321.5L459.5 327.75V336.5L458.5 343.75L457 349.75L455 355.25L452.5 360.5L450 364.75L447.5 368.25L445.25 371L442.5 374L440.75 376L439.75 377H439.25L438.75 378L437 379.5L334.5 482H334L333.5 483H333V483.5L330.5 485.5L327.75 487.25L324.25 488.75L316.75 491L257 507.5L243.75 511.25L240.75 512H233.5L228.5 510L226 508.5L223.5 506.5V506H223L220.5 502.75L218.5 498.75L217.75 496.5L217.25 492.75V490.75L218.25 485.5L221.5 474.75L225 463.25L228.25 452.5L231.75 441L236 427L240 413.75L242 407.25L244 402.75L246.25 400L249 397L298.5 347.5H299L299.5 346.5H300L300.5 345.5H301L301.5 344.5H302L302.5 343.5H303L303.25 342.75L304.75 341.5L306.25 339.75L308 338.25L309.25 336.75L311 335.25L312.25 333.75L314.25 332L358.5 287.75L360.5 286L365 282.5L370.25 279.25L374.25 277.25L377.75 275.75L383 274L388.5 272.75L393.75 272L398 271.75ZM396.5 312.25L392.5 313.25L389 315L385.75 317.5L382 321.25L382.25 322.25L384 324.25L407.25 347.5L408.75 348.75L409.5 349.5L410.5 349L414.75 344.75L417 341.25L418.25 338.5L419 335.75L419.25 331L418.75 327.25L417.5 323.75L415.75 320.5L413 317.25L409.5 314.75L406.25 313.25L402 312.25H396.5ZM353 350.25L351 352V352.5H350.5V353H350V353.5H349.5V354H349V354.5H348.5V355H348V355.5H347.5V356H347V356.5H346.5V357H346V357.5H345.5V358H345V358.5L344 359V359.5L343 360V360.5H342.5V361H342V361.5H341.5V362H341V362.5L340 363V363.5L339 364V364.5L338 365L279.25 423.75L277.5 429L274.25 439.75L268.5 458.75L267.5 461.75V462.5L269.5 462.25L305.75 452.25L308 451.25L310.5 449L381 378.5L380.5 377.25L354.25 351H353.75L353.5 350.25H353Z"
                                        fill="white" />
                                    <path
                                        d="M100.75 199.75H305.75L311 200.25L314.75 201.5L318.5 203.75L321.5 206.5L324 210L325.75 214L326.5 217.5V222.25L325.75 225.75L324.5 229L322.75 231.75L320.5 234.5L317.25 237L313.75 238.75L310 239.75L307.75 240H98.75L95.5 239.5L92.25 238.5L89.25 236.75L86 234L83.5 231L81.5 227L80.5 223.25L80.25 221.25V218.75L81 214.5L82.25 211.25L84 208.25L87 205L89 203.25L92.75 201.25L96 200.25L100.75 199.75Z"
                                        fill="white" />
                                    <path
                                        d="M100.75 119.75H305.75L311 120.25L314.75 121.5L318.5 123.75L321.5 126.5L324 130L325.75 134L326.5 137.5V142.25L325.75 145.75L324.5 148.75L323 151.5L320.25 154.75L317.25 157L313.75 158.75L310 159.75L307.75 160H98.75L95.5 159.5L92.25 158.5L89.25 156.75L86 154L83.5 151L81.25 146.25L80.5 143.25L80.25 141.25V138.75L81 134.5L82.25 131.25L84 128.25L87 125L89 123.25L92.75 121.25L96 120.25L100.75 119.75Z"
                                        fill="white" />
                                    <path
                                        d="M100.75 279.75H224.5L230 280.25L233.25 281.25L237.25 283.5L240.25 286.25L242 288.25L244 291.75L245.25 295.5L245.75 298.5V301.25L245 305.5L243.75 308.75L241.75 312L239 315L236 317.25L232.75 318.75L229 319.75L227 320H98.75L95.5 319.5L92.25 318.5L88.5 316.25L85.5 313.5L83.25 310.5L81.25 306.25L80.5 303.25L80.25 301.25V298.75L81 294.5L82.25 291.25L84 288.25L87 285L89 283.25L92.75 281.25L96 280.25L100.75 279.75Z"
                                        fill="white" />
                                </svg>
                                <!-- <v-icon size="22" color="white" class="mr-2"> </v-icon> -->
                                Sign Up Now!
                            </v-btn>
                        </div>


                    </div>
                </v-card>
            </v-expand-transition>
            <!-- <v-footer class="footercss pa-0 px-4 d-md-none" color="white" v-bind="localAttrs" :padless="padless">
                <v-btn @click="dialog = true" block large rounded color="#FFF07Ec2" height="48px"
                    class="elevation-0 text-none">
                    <span class="black--text font-weight-bold">Sign Up Now! </span>
                </v-btn>
            </v-footer> -->

            <v-dialog persistent v-model="dialog" elevation="0" class="rounded-xl pa-3"
                transition="dialog-bottom-transition">
                <v-card elevation="0" color="#E5EBEC" width="100%" class="rounded-xl pa-4">
                    <v-card-actions>
                        <p class="font-weight-bold mb-4 fs-18 black--text">Sign Up Now! </p>

                        <v-spacer></v-spacer>

                        <v-btn icon @click="dialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-actions>

                    <v-text-field v-model="formaname" :disabled="loader" :rules="nameRules" required block flat solo
                        background-color="#ffffff" class="elevation-0  rounded-pill caption" label="Enter your name">
                        <template #prepend-inner>
                            <img src="@/assets/contactus/user-c-frame.svg" width="100%" class="mx-2" alt="user-icon" />
                        </template>
                    </v-text-field>

                    <v-text-field v-model="frommobile" :maxlength="10" :disabled="loader" :rules="mobileRules" required
                        block flat solo background-color="#ffffff" class="elevation-0 rounded-pill caption"
                        label="Enter your mobile number">
                        <template #prepend-inner>
                            <img src="@/assets/contactus/phone.svg" width="100%" class="mx-2" alt="phone-icon" />
                        </template>
                    </v-text-field>
                    <v-text-field v-model="formemail" :disabled="loader" :rules="emailRules" required block flat solo
                        background-color="#ffffff" class="elevation-0 rounded-pill caption"
                        label="Enter your email address">
                        <template #prepend-inner>
                            <img src="@/assets/contactus/send-message.svg" width="100%" class="mx-2"
                                alt="send-msg-icon" />
                        </template>
                    </v-text-field>
                    <v-row>

                        <v-col cols="12">
                            <v-btn block @click="formadataipo()" large rounded :color="valid ? '#FFF07E' : '#FFF07Ec2'"
                                :loading="loader" height="48px" class="elevation-0 mr-7 text-none">
                                <span class="black--text px-7">Submit</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-dialog>
        </div>

    </div>
</template>

<script>
import { ipolpdatastore } from "../../apiurl";

export default {
    data: () => ({
        datas: [
            {
                id: 0,
                image: require("@/assets/product/lpins/lifein.svg"),
                tit: "Life Insurance",
                sub: "Protect what matters most—your family. Our life insurance plans are designed to provide financial security for your loved ones, tailored to your unique needs and life stage. Secure their future today and safeguard them against life’s uncertainties.",
            },
            {
                id: 1,
                image: require("@/assets/product/lpins/genin.svg"),
                tit: "General Insurance",
                sub: "Whether it’s your car, two-wheeler, home, or business, our comprehensive general insurance policies are here to protect your most valuable assets. Enjoy peace of mind and safeguard your belongings with reliable coverage. We’ve got you covered, no matter where life takes you.",
            },
            {
                id: 2,
                image: require("@/assets/product/lpins/healin.svg"),
                tit: "Health Insurance",
                sub: "Be ready for the unexpected with our health insurance plans. From medical emergencies to accidents, our policies ensure you’re financially prepared when it matters most. Stay worry-free and focus on your well-being while we take care of the rest.",
            },

        ],
        steps: [
            {
                id: 0,
                image: require("@/assets/product/lpins/in1.svg"),
                tit: "Receive a custom premium quote and expert advice instantly.",
                sub: "Get Quote"
            },
            {
                id: 1,
                image: require("@/assets/product/lpins/in2.svg"),
                tit: "Use “Quote Compare“ to find the best budget-friendly policy for your needs.",
                sub: "Smart Compare"
            },
            {
                id: 2,
                image: require("@/assets/product/lpins/in3.svg"),
                tit: "Insured Make online payment and stay rest assured with Insurance Policy",
                sub: "Get Quick"
            }
        ],
        numberis: [(v) => !!v || "Your mobile number is required", (v) => /^[6-9][0-9]{9}$/.test(v) || "mobile number must be 10 Digit Number"],
        learnitems: [

            {
                title: 'Quick Expert Advisory',
                image: require('@/assets/product/lpins/pd (4).svg'),
                // subtitle: 'With dedicated RM',
            },
            {
                title: 'Ready Online Plans offer ',
                image: require('@/assets/product/lpins/client1.svg'),
                // subtitle: 'Tailored to your needs',

            },

            {
                title: 'Reliable Claim Assistance',
                image: require('@/assets/product/lpins/pd (3).svg'),
                // subtitle: '0 today',

            },

        ],
        padless: false,
        variant: "fixed",
        detailitem: [
            {
                image: require('@/assets/product/lpins/detailins.svg'),

                title: "Fill in your details to receive premium quotes instantly and conveniently."
            },
            {
                image: require('@/assets/product/lpins/planins.svg'),

                title: "Choose a suitable plan that fits your requirements and budget."
            },
            {
                image: require('@/assets/product/lpins/displan.svg'),

                title: "One-to-one discussion Pay Online and receive your policy instantly in your inbox"
            }
        ],

        insparlogo: [
            {
                id: 1,
                image: require('@/assets/product/lpins/logo-1.jpg'),
            },
            {
                id: 2,
                image: require('@/assets/product/lpins/logo-2.jpg'),
            },
            {
                id: 3,
                image: require('@/assets/product/lpins/logo-3.jpg'),
            },
            {
                id: 4,
                image: require('@/assets/product/lpins/logo-4.jpg'),
            }, {
                id: 5,
                image: require('@/assets/product/lpins/logo-5.jpg'),
            },
            {
                id: 6,
                image: require('@/assets/product/lpins/logo-6.jpg'),
            }, {
                id: 7,
                image: require('@/assets/product/lpins/logo-7.jpg'),
            },

        ],
        activelist: {},
        formaname: "",
        frommobile: "",
        formemail: "",
        nameRules: [(v) => !!v || "Your Name is required", (v) => (v && v.length >= 3) || "Name must be more than 3 characters"],
        emailRules: [(v) => !!v || "Your E-mail is required", (v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
        mobileRules: [(v) => !!v || "Your mobile number is required", (v) => /^[6-9][0-9]{9}$/.test(v) || "mobile number must be 10 Digit Number"],
        loader: false,
        dialog: false,
        mobile: "",
        snackmsgbar: "",
        snackbar: false,
        snackbarclr: "#000",
        marketemail: "",
        valid: false,
        banndialog: true,
        showbox: true,
        signupbtn: false,

    }),


    methods: {
        loveTo() {
            const element = document.getElementById("love");
            element.scrollBy({
                left: -600,
                behavior: "smooth",
            });
        },

        loveBk() {
            const element = document.getElementById("love");
            element.scrollBy({
                left: 600,
                behavior: "smooth",
            });
        },
        changeTois(l) {
            this.activelist = l;
        },
        opencard() {
            this.signupbtn = false
            this.showbox = true
        },
        closecard() {
            this.showbox = false
            this.signupbtn = true

        },
        formadataipo() {

            if ((this.formaname != "" && this.frommobile != "" && this.formemail != "") || this.marketemail != "") {
                this.loader = true;
                const axios = require("axios");
                let data = JSON.stringify({

                    client_name: this.marketemail ? "Book a call" : this.formaname,
                    mobile_number: this.marketemail || this.frommobile,
                    email: this.marketemail ? "Bookacall@gmail.com" : this.formemail,
                    source: "Insurance",



                });

                let config = {
                    method: "post",
                    maxBodyLength: Infinity,
                    url: `${ipolpdatastore}/add`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: data,
                };
                var axiosThis = this;
                axios
                    .request(config)
                    .then((response) => {
                        // console.log(JSON.stringify(response.data));
                        axiosThis.loader = false;
                        axiosThis.dialog = false;
                        if (response.data.stat == "Ok") {
                            axiosThis.snackbar = true;
                            axiosThis.snackbarclr = "green";
                            axiosThis.snackmsgbar = "Thank you for your response, <br> One of our team representative will reach you shortly.";
                            axiosThis.closecard()
                        } else {
                            axiosThis.snackbar = true;
                            axiosThis.snackbarclr = "red";
                            axiosThis.snackmsgbar = response.data;

                        }
                        axiosThis.formaname = "";
                        axiosThis.frommobile = "";
                        axiosThis.formemail = "";
                        axiosThis.marketemail = ""
                    })

                    .catch((error) => {
                        console.log(error);
                        axiosThis.dialog = false;
                        axiosThis.loader = false;
                        axiosThis.snackbar = true;
                        axiosThis.snackbarclr = "error";
                        (axiosThis.snackmsgbar = error), (axiosThis.formaname = "");
                        axiosThis.frommobile = "";
                        axiosThis.formemail = "";
                    });
            }

            else {
                this.snackbar = true;
                this.snackbarclr = "error";
                this.snackmsgbar = "Please fill the all fields";
            }
        },
        getCall() {
            if (this.$refs.form.validate() == true) {
                window.open(
                    `https://oa.mynt.in/?ref=zws&mobile=${this.mobile}`
                );
                this.$refs.form.reset();
            }
        },
        NumberValid($event) {
            if (($event.keyCode >= 48 && $event.keyCode <= 57) || ($event.keyCode >= 96 && $event.keyCode <= 105)) {
                // 0-9 only
            } else {
                $event.preventDefault();
            }
        },
    },
    computed: {
        localAttrs() {
            const attrs = {};

            if (this.variant === "default") {
                attrs.absolute = false;
                attrs.fixed = false;
            } else {
                attrs[this.variant] = true;
            }
            return attrs;
        },

    },
    mounted() {
        this.activelist = this.datas[0];
    }

};
</script>