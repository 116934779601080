<template>
    <div>
        <v-app-bar v-if="mobilebar" width="100%" permanent class="cust-appbar px-md-2 d-md-none z-i4">

            <v-autocomplete class="menu-search elevation-0 mt-2 rounded-pill caption search-field mb-010" flat
                solo background-color="#F1F3F8" v-model="model" dense :items="items" :loading="isLoading"
                :search-input.sync="searchscript" hide-details hide-selected item-text="tsym" @change="addSearch()"
                item-value="token" label="Search for stocks" prepend-inner-icon="mdi-magnify" append-icon="" no-filter
                return-object auto-select-first oninput="this.value = this.value.toUpperCase()" clearable>
                <template v-slot:no-data>
                    <v-col class="text-center mx-auto pa-6">
                        <p class="font-weight-bold mb-2 black--text">
                            {{
            nodata == null ? "Type more than 2 letter" : "No Contract Found" }} </p>
                        <span class="body-2 mb-5 grey--text">
                            {{
            nodata == null ? "Eg. for Reliance Type: rel" : "Try search for diff name" }}</span>
                    </v-col>
                </template>
                <template v-slot:item="data">
                    <v-list-item-content>
                        <v-list-item-title class="txt-000 fs-12">{{ data.item.dname ? data.item.dname : data.item.cname
                            }}</v-list-item-title>
                        <v-list-item-subtitle class="fs-10">{{  data.item.tsym
                            }}</v-list-item-subtitle>
                    </v-list-item-content>
                </template>
            </v-autocomplete>

            <v-btn class="d-flex d-md-none" @click="mobilebar = false" icon>
                <v-icon color="black">mdi-close</v-icon>
            </v-btn>

        </v-app-bar>
        <v-app-bar v-else width="100%" permanent class="cust-appbar px-md-2 z-i4">
            <img @click="toHome(), nativeDrawer()" src="https://zebu.mynt.in/static/logo/mainlogo.svg" width="80px" alt="zebulogo" class="mr-4">

            <v-btn to="/invest" text class="menu-app-btn text-capitalize d-none d-md-flex"><span
                    class="menu-app-text font-weight-medium">Invest</span></v-btn>
            <v-btn to="/trade" text class="menu-app-btn text-capitalize d-none d-md-flex"><span
                    class="menu-app-text font-weight-medium">Trade</span></v-btn>
            <v-btn to="/product" text class="menu-app-btn text-capitalize d-none d-md-flex"><span
                    class="menu-app-text font-weight-medium">Product</span></v-btn>
            <!-- open-on-hover  -->
            <v-menu close-on-click offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" text
                        class="menu-app-btn text-capitalize d-none d-md-flex px-3"><span
                            class="menu-app-text font-weight-medium">Learn <v-icon
                                class="menu-app-text pl-1">mdi-chevron-down</v-icon></span></v-btn>
                </template>
                <v-list class="appbar-hover">
                    <v-list-item class="menu-app-btn px-3" v-for="(item, index) in menuitems.slice(0, 4)" :key="index"
                        :to="item.too">
                        <v-list-item-title class="menu-app-text">{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <!-- <v-btn to="/price" text class="menu-app-btn text-capitalize d-none d-md-flex"><span
                    class="menu-app-text font-weight-medium">Pricing</span></v-btn> -->
            <v-btn to="/support" text class="menu-app-btn text-capitalize d-none d-md-flex"><span
                    class="menu-app-text font-weight-medium">Support</span></v-btn>

            <v-menu close-on-click offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" text
                        class="menu-app-btn text-capitalize d-none d-md-flex pr-2"><span
                            class="menu-app-text font-weight-medium">Company <v-icon
                                class="menu-app-text pl-1">mdi-chevron-down</v-icon></span></v-btn>
                </template>
                <v-list class="appbar-hover">
                    <v-list-item class="menu-app-btn px-4" v-for="(item, index) in menuitems.slice(4, 8)" :key="index"
                        :to="item.too">
                        <v-list-item-title class="menu-app-text">{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-spacer></v-spacer>
            <v-autocomplete
                class="menu-search elevation-0 mt-2 rounded-pill caption search-field d-none d-md-block mb-010" flat
                solo background-color="#F1F3F8" v-model="model" dense :items="items" :loading="isLoading"
                :search-input.sync="searchscript" hide-details hide-selected item-text="tsym" @change="addSearch()"
                item-value="token" label="Search for stocks" prepend-inner-icon="mdi-magnify" append-icon="" no-filter
                return-object auto-select-first oninput="this.value = this.value.toUpperCase()" clearable>
                <template v-slot:no-data>
                    <v-col class="text-center mx-auto pa-6">
                        <p class="font-weight-bold mb-2 black--text">
                            {{
            nodata == null ? "Type more than 2 letter" : "No Contract Found" }} </p>
                        <span class="body-2 mb-5 grey--text">
                            {{
            nodata == null ? "Eg. for Reliance Type: rel" : "Try search for diff name" }}</span>
                    </v-col>
                </template>
                <template v-slot:item="data">
                    <v-list-item-content>
                        <v-list-item-title class="txt-000 fs-12">{{ data.item.dname ? data.item.dname : data.item.cname
                            }}</v-list-item-title>
                        <v-list-item-subtitle class="fs-10">{{  data.item.tsym
                            }}</v-list-item-subtitle>
                    </v-list-item-content>
                </template>
            </v-autocomplete>
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon class="menu-app-btn mr-2 d-none d-md-flex">
                        <img src="@/assets/apps.svg" alt="apps">
                    </v-btn>
                </template>
                <v-card class="apps-menu-card pt-3 elevation-0 overflow-y-auto overflow-x-hidden" width="380px"
                    height="380px">
                    <v-row no-glutters class="mx-2">
                        <v-col cols="12" class="pb-0">
                            <p class="fs-18 mb-0">Mynt apps</p>
                        </v-col>

                        <v-col cols="3" class="text-center py-1 px-0">
                            <v-card height="80px" color="transparent" class="app-menu-items pt-2 rounded-lg elevation-0"
                                target="_blank" rel="noopener noreferrer"
                                href="https://zebu.mynt.in/static/Downloads/Apps/ZEBU_INV.exe">
                                <img src="@/assets/app-desk.svg" alt="apps-desk" class="mb-0 pa-1" width="38px"><br>
                                <p class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16"> Desk</p>
                            </v-card>
                        </v-col>

                        <v-col cols="3" class="text-center py-1 px-0">
                            <v-card height="80px" color="transparent" class="app-menu-items pt-3 rounded-lg elevation-0"
                                target="_blank" rel="noopener noreferrer" href="https://go.mynt.in/">
                                <img src="@/assets/product/webtrading2.svg" alt="web" class="mb-1" width="30px"> <br>
                                <p class="app-menu-item-txt mb-0 caption font-weight-light black--text lh-16"> Web</p>
                            </v-card>
                        </v-col>

                        <v-col cols="3" class="text-center py-1 px-0">
                            <v-card height="80px" color="transparent" class="app-menu-items pt-2 rounded-lg elevation-0"
                                target="_blank" rel="noopener noreferrer" href="https://zebumyntapi.web.app/">
                                <img src="@/assets/api-svg.svg" alt="API" class="mb-0 pt-1" width="30px"><br>
                                <p class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16"> API</p>
                            </v-card>
                        </v-col>

                        <v-col cols="3" class="text-center py-1 px-0">
                            <v-card height="80px" color="transparent" class="app-menu-items pt-2 rounded-lg elevation-0"
                                target="_blank" rel="noopener noreferrer"
                                href="https://zebu.mynt.in/static/Downloads/Apps/MYNT_Amibroker.zip">
                                <img src="@/assets/desktop-d.svg" alt="desk" class="mb-0" width="37px"><br>
                                <p class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16">
                                    Amibroker
                                </p>
                            </v-card>
                        </v-col>

                        <v-col cols="3" class="text-center py-1 px-0">
                            <v-card height="80px" color="transparent" class="app-menu-items pt-3 rounded-lg elevation-0"
                                target="_blank" rel="noopener noreferrer"
                                href="https://play.google.com/store/apps/details?id=com.zebu.mynt">
                                <img src="@/assets/android.svg" alt="android" class="mb-1" width="26px"> <br>
                                <p class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16"> Android
                                </p>
                            </v-card>
                        </v-col>
                        <v-col cols="3" class="text-center py-1 px-0">
                            <v-card height="80px" color="transparent" class="app-menu-items pt-3 rounded-lg elevation-0"
                                target="_blank" rel="noopener noreferrer"
                                href="https://apps.apple.com/in/app/mynt-zebu/id1663709767">
                                <img src="@/assets/product/apple-logo.svg" alt="apple" class="mb-01" width="29px"> <br>
                                <p class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16"> IOS</p>
                            </v-card>
                        </v-col>
                        <v-col cols="3" class="text-center py-1 px-0">
                            <v-card height="80px" color="transparent" class="app-menu-items pt-3 rounded-lg elevation-0"
                                target="_blank" rel="noopener noreferrer" href="https://tv.mynt.in/">
                                <img src="@/assets/product/mynt-trading-logo.svg" alt="mynt-trading" class="mb-0 pa-1"
                                    width="42px"><br>
                                <p class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16">
                                    Tradingview
                                </p>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row no-glutters>
                        <v-col cols="12" class="py-0 px-0">
                            <v-card class="elevation-0 rounded-0 py-1" color="#EBEEF0"></v-card>
                        </v-col>
                    </v-row>
                    <v-row no-glutters class="mx-2">
                        <v-col cols="12" class="pb-0">
                            <p class="fs-18 mb-0">Invest</p>
                        </v-col>
                        <v-col cols="3" class="text-center py-1 px-0">
                            <v-card height="80px" color="transparent" class="app-menu-items pt-2 rounded-lg elevation-0"
                                target="_blank" rel="noopener noreferrer" href="https://go.mynt.in/">
                                <img src="@/assets/products-sec/Stocks-ETFs.png" alt="stocks" class="mb-0"
                                    width="48px"><br>
                                <p class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16"> Stocks
                                </p>
                            </v-card>
                        </v-col>
                        <v-col cols="3" class="text-center py-1 px-0">
                            <v-card height="80px" color="transparent" class="app-menu-items pt-2 rounded-lg elevation-0"
                                target="_blank" rel="noopener noreferrer" href="https://zebu.investwell.app/">
                                <img src="@/assets/products-sec/mutual-funds.png" alt="mf" class="mb-0"
                                    width="48px"><br>
                                <p class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16"> Mutual
                                    Funds
                                </p>
                            </v-card>
                        </v-col>
                        <v-col cols="3" class="text-center py-1 px-0">
                            <v-card height="80px" color="transparent" class="app-menu-items pt-2 rounded-lg elevation-0"
                                target="_blank" rel="noopener noreferrer" href="https://app.mynt.in/bonds">
                                <img src="@/assets/products-sec/bonds.png" alt="bond" class="mb-02" width="46px"><br>
                                <p class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16"> Bonds
                                </p>
                            </v-card>
                        </v-col>
                        <v-col cols="3" class="text-center py-1 px-0">
                            <v-card height="80px" color="transparent" class="app-menu-items pt-2 rounded-lg elevation-0"
                                target="_blank" rel="noopener noreferrer" href="https://app.mynt.in/ipo">
                                <img src="@/assets/products-sec/ipos.png" alt="ipos" class="mb-0" width="48px"><br>
                                <p class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16"> IPOs</p>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row no-glutters>
                        <v-col cols="12" class="py-0 px-0">
                            <v-card class="elevation-0 rounded-0 py-1" color="#EBEEF0"></v-card>
                        </v-col>
                    </v-row>
                    <v-row no-glutters class="mx-2">
                        <v-col cols="12" class="pb-0">
                            <p class="fs-18 mb-0">Desk</p>
                        </v-col>
                        <v-col cols="3" class="text-center py-1 px-0">
                            <v-card height="80px" color="transparent" class="app-menu-items pt-2 rounded-lg elevation-0"
                                target="_blank" rel="noopener noreferrer" href="https://profile.mynt.in/">
                                <img src="@/assets/profile-icon.svg" alt="profile" class="mb-0 pa-1" width="38px"><br>
                                <p class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16"> Profile
                                </p>
                            </v-card>
                        </v-col>
                        <v-col cols="3" class="text-center py-1 px-0">
                            <v-card height="80px" color="transparent" class="app-menu-items pt-2 rounded-lg elevation-0"
                                target="_blank" rel="noopener noreferrer" href="https://profile.mynt.in/">
                                <img src="@/assets/reports-icon.svg" alt="report" class="mb-1" width="32px"><br>
                                <p class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16"> Reports
                                </p>
                            </v-card>
                        </v-col>
                        <v-col cols="3" class="text-center py-1 px-0">
                            <v-card height="80px" color="transparent" class="app-menu-items pt-2 rounded-lg elevation-0"
                                target="_blank" rel="noopener noreferrer" href="https://profile.mynt.in/pledge">
                                <v-icon color="black" size="40" class="mb-03">mdi-hand-extended-outline</v-icon>
                                <br>
                                <p class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16"> Pledge
                                </p>
                            </v-card>
                        </v-col>
                        <v-col cols="3" class="text-center py-1 px-0">
                            <v-card height="80px" color="transparent" class="app-menu-items pt-2 rounded-lg elevation-0"
                                target="_blank" rel="noopener noreferrer" href="https://fund.mynt.in/">
                                <img src="@/assets/funds.svg" alt="fund" class="mb-02 pa-1" width="34px"><br>
                                <p class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16"> Add fund
                                </p>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row no-glutters>
                        <v-col cols="12" class="py-0 px-0">
                            <v-card class="elevation-0 rounded-0 py-1" color="#EBEEF0"></v-card>
                        </v-col>
                    </v-row>
                    <v-row no-glutters class="mx-2">

                        <v-col cols="12" class="pb-0">
                            <p class="fs-18 mb-0">Backoffice</p>
                        </v-col>
                        <v-col cols="3" class="text-center py-1 px-0">
                            <v-card height="80px" color="transparent" class="app-menu-items pt-2 rounded-lg elevation-0"
                                target="_blank" rel="noopener noreferrer" href="https://bo.zebull.in/WebClient/">
                                <img src="@/assets/redir-icon.svg" alt="redir-icon" class="mb-0 pa-1" width="38px"><br>
                                <p class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16"> Client
                                </p>
                            </v-card>
                        </v-col>
                        <v-col cols="3" class="text-center py-1 px-0">
                            <v-card height="80px" color="transparent" class="app-menu-items pt-2 rounded-lg elevation-0"
                                target="_blank" rel="noopener noreferrer"
                                href="https://bo.zebull.in/WebLogin/index.cfm?Logintype=Branch">
                                <img src="@/assets/redir-icon.svg" alt="redir-icon" class="mb-0 pa-1" width="38px"><br>
                                <p class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16"> Branch
                                </p>
                            </v-card>
                        </v-col>
                        <v-col cols="3" class="text-center py-1 px-0">
                            <v-card height="80px" color="transparent" class="app-menu-items pt-2 rounded-lg elevation-0"
                                target="_blank" rel="noopener noreferrer"
                                href="https://bo.zebull.in/WebLogin/index.cfm?Logintype=SUBBRANCH">
                                <img src="@/assets/redir-icon.svg" alt="redir-icon" class="mb-0 pa-1" width="38px"><br>
                                <p class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16"> AP</p>
                            </v-card>
                        </v-col>
                    </v-row>
                    <!-- <v-row no-glutters>
                        <v-col cols="12" class="py-0 px-0">
                            <v-card class="elevation-0 rounded-0 pt-3" color="#EBEEF0"></v-card>
                        </v-col>
                    </v-row> -->
                </v-card>
            </v-menu>
            <!-- <v-text-field prepend-inner-icon="mdi-magnify" flat dense solo background-color="#F1F3F8"
                class="menu-search elevation-0 mt-1 mr-3 rounded-pill caption search-field d-none d-md-flex"
                label="Search for stocks, IPO, SGBs and G-Sec"></v-text-field> -->
            <v-btn target="_blank" rel="noopener noreferrer" href="https://go.mynt.in/" outlined height="34px"
                color="#2A2A2A" class="menu-signin-btn text-capitalize rounded-pill mr-3 d-none d-md-flex"><span
                    class="font-weight-medium">Sign
                    in</span></v-btn>
            <v-btn target="_blank" rel="noopener noreferrer" href="https://oa.mynt.in/?ref=zws" color="#2A2A2A"
                height="34px" class="text-none rounded-pill elevation-0"><span
                    class="white--text font-weight-light body-2">Invest
                    Now</span></v-btn>

            <v-btn class="d-flex d-md-none ml-2" @click="mobilebar = true" icon small>
                <img src="@/assets/search-icon.svg" alt="search-icon" width="20px">
            </v-btn>

            <v-btn v-if="!drawer" class="d-flex d-md-none" @click="drawer = true" icon>
                <img src="@/assets/app-bar-nav-icon.svg" alt="app-bar-nav-icon" width="18px">
            </v-btn>

            <v-btn v-else class="d-flex d-md-none" @click="drawer = false" icon>
                <v-icon color="black">mdi-close</v-icon>

            </v-btn>

        </v-app-bar>

        <v-expand-transition>
            <v-card color="#F4F4F4" v-show="drawer" width="100vw"
                class="app-exp mx-auto elevation-0 rounded-0 overflow-y-auto d-md-none no-scroll">
                <div class="d-flex py-3 px-3">
                    <v-col cols="6">
                        <v-btn target="_blank" rel="noopener noreferrer" href="https://go.mynt.in/" outlined
                            height="40px" color="#2A2A2A" block
                            class="menu-signin-btn text-capitalize rounded-pill"><span
                                class="menu-app-btn font-weight-medium">Sign in</span></v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn target="_blank" rel="noopener noreferrer" href="https://oa.mynt.in/?ref=zws"
                            color="#2A2A2A" height="40px" block class="text-none rounded-pill elevation-0"><span
                                class="white--text font-weight-light body-2">Invest Now</span></v-btn>
                    </v-col>
                </div>
                <v-expansion-panels dense v-model="exppanel" class="elevation-0 rounded-0">
                    <v-expansion-panel class="exp-sty appbar-exp">
                        <v-expansion-panel-header class="font-weight-bold fs-18 py-0"
                            :color="exppanel == 0 ? '#EFF3F3' : '#F4F4F4'">Apps
                            <template v-slot:actions>
                                <div>
                                    <v-icon color="black">{{ exppanel == 0 ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                                </div>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content :color="exppanel == 0 ? '#EFF3F3' : '#F4F4F4'">
                            <div>
                                <v-row no-glutters class="mx-0">
                                    <v-col cols="12" class="pb-0 pl-0">
                                        <p class="fs-16 mb-0">Mynt apps</p>
                                    </v-col>

                                    <v-col cols="3" class="text-center py-1 px-0">
                                        <v-card height="80px" color="transparent"
                                            class="app-menu-items pt-2 rounded-lg elevation-0" target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://zebu.mynt.in/static/Downloads/Apps/ZEBU_INV.exe">
                                            <img src="@/assets/app-desk.svg" alt="desk" class="mb-0 pa-1"
                                                width="38px"><br>
                                            <p
                                                class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16">
                                                Desk
                                            </p>
                                        </v-card>
                                    </v-col>

                                    <v-col cols="3" class="text-center py-1 px-0">
                                        <v-card height="80px" color="transparent"
                                            class="app-menu-items pt-3 rounded-lg elevation-0" target="_blank"
                                            rel="noopener noreferrer" href="https://go.mynt.in/">
                                            <img src="@/assets/product/webtrading2.svg" alt="web" class="mb-1"
                                                width="30px">
                                            <br>
                                            <p
                                                class="app-menu-item-txt mb-0 caption font-weight-light black--text lh-16">
                                                Web
                                            </p>
                                        </v-card>
                                    </v-col>

                                    <v-col cols="3" class="text-center py-1 px-0">
                                        <v-card height="80px" color="transparent"
                                            class="app-menu-items pt-2 rounded-lg elevation-0" target="_blank"
                                            rel="noopener noreferrer" href="https://zebumyntapi.web.app/">
                                            <img src="@/assets/api-svg.svg" alt="API" class="mb-0 pt-1"
                                                width="30px"><br>
                                            <p
                                                class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16">
                                                API
                                            </p>
                                        </v-card>
                                    </v-col>

                                    <v-col cols="3" class="text-center py-1 px-0">
                                        <v-card height="80px" color="transparent"
                                            class="app-menu-items pt-2 rounded-lg elevation-0" target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://zebu.mynt.in/static/Downloads/Apps/MYNT_Amibroker.zip">
                                            <img src="@/assets/desktop-d.svg" alt="desks" class="mb-0" width="37px"><br>
                                            <p
                                                class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16">
                                                Amibroker
                                            </p>
                                        </v-card>
                                    </v-col>

                                    <v-col cols="3" class="text-center py-1 px-0">
                                        <v-card height="80px" color="transparent"
                                            class="app-menu-items pt-3 rounded-lg elevation-0" target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://play.google.com/store/apps/details?id=com.zebu.mynt">
                                            <img src="@/assets/android.svg" alt="android" class="mb-1" width="26px">
                                            <br>
                                            <p
                                                class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16">
                                                Android</p>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="3" class="text-center py-1 px-0">
                                        <v-card height="80px" color="transparent"
                                            class="app-menu-items pt-3 rounded-lg elevation-0" target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://apps.apple.com/in/app/mynt-zebu/id1663709767">
                                            <img src="@/assets/product/apple-logo.svg" alt="apple" class="mb-01"
                                                width="29px">
                                            <br>
                                            <p
                                                class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16">
                                                IOS
                                            </p>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="3" class="text-center py-1 px-0">
                                        <v-card height="80px" color="transparent"
                                            class="app-menu-items pt-3 rounded-lg elevation-0" target="_blank"
                                            rel="noopener noreferrer" href="https://tv.mynt.in/">
                                            <img src="@/assets/product/mynt-trading-logo.svg" alt="mynt-trading"
                                                class="mb-0 pa-1" width="42px"><br>
                                            <p
                                                class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16">
                                                Tradingview
                                            </p>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <v-row no-glutters>
                                    <v-col cols="12" class="py-0 px-0">
                                        <v-card class="elevation-0 rounded-0 py-1" color="#EBEEF0"></v-card>
                                    </v-col>
                                </v-row>
                                <v-row no-glutters class="mx-0">
                                    <v-col cols="12" class="pb-0 pl-0">
                                        <p class="fs-16 mb-0">Invest</p>
                                    </v-col>
                                    <v-col cols="3" class="text-center py-1 px-0">
                                        <v-card height="80px" color="transparent"
                                            class="app-menu-items pt-2 rounded-lg elevation-0" target="_blank"
                                            rel="noopener noreferrer" href="https://go.mynt.in/">
                                            <img src="@/assets/products-sec/Stocks-ETFs.png" alt="stocks" class="mb-0"
                                                width="48px"><br>
                                            <p
                                                class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16">
                                                Stocks
                                            </p>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="3" class="text-center py-1 px-0">
                                        <v-card height="80px" color="transparent"
                                            class="app-menu-items pt-2 rounded-lg elevation-0" target="_blank"
                                            rel="noopener noreferrer" href="https://zebu.investwell.app/">
                                            <img src="@/assets/products-sec/mutual-funds.png" alt="mf" class="mb-0"
                                                width="48px"><br>
                                            <p
                                                class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16">
                                                Mutual Funds
                                            </p>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="3" class="text-center py-1 px-0">
                                        <v-card height="80px" color="transparent"
                                            class="app-menu-items pt-2 rounded-lg elevation-0" target="_blank"
                                            rel="noopener noreferrer" href="https://app.mynt.in/bonds">
                                            <img src="@/assets/products-sec/bonds.png" alt="bond" class="mb-02"
                                                width="46px"><br>
                                            <p
                                                class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16">
                                                Bonds</p>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="3" class="text-center py-1 px-0">
                                        <v-card height="80px" color="transparent"
                                            class="app-menu-items pt-2 rounded-lg elevation-0" target="_blank"
                                            rel="noopener noreferrer" href="https://app.mynt.in/ipo">
                                            <img src="@/assets/products-sec/ipos.png" alt="ipos" class="mb-0"
                                                width="48px"><br>
                                            <p
                                                class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16">
                                                IPOs
                                            </p>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <v-row no-glutters>
                                    <v-col cols="12" class="py-0 px-0">
                                        <v-card class="elevation-0 rounded-0 py-1" color="#EBEEF0"></v-card>
                                    </v-col>
                                </v-row>
                                <v-row no-glutters class="mx-0">
                                    <v-col cols="12" class="pb-0 pl-0">
                                        <p class="fs-16 mb-0">Desk</p>
                                    </v-col>
                                    <v-col cols="3" class="text-center py-1 px-0">
                                        <v-card height="80px" color="transparent"
                                            class="app-menu-items pt-2 rounded-lg elevation-0" target="_blank"
                                            rel="noopener noreferrer" href="https://profile.mynt.in/">
                                            <img src="@/assets/profile-icon.svg" alt="profile" class="mb-0 pa-1"
                                                width="38px"><br>
                                            <p
                                                class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16">
                                                Profile</p>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="3" class="text-center py-1 px-0">
                                        <v-card height="80px" color="transparent"
                                            class="app-menu-items pt-2 rounded-lg elevation-0" target="_blank"
                                            rel="noopener noreferrer" href="https://profile.mynt.in/">
                                            <img src="@/assets/reports-icon.svg" alt="reports" class="mb-1"
                                                width="32px"><br>
                                            <p
                                                class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16">
                                                Reports</p>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="3" class="text-center py-1 px-0">
                                        <v-card height="80px" color="transparent"
                                            class="app-menu-items pt-2 rounded-lg elevation-0" target="_blank"
                                            rel="noopener noreferrer" href="https://profile.mynt.in/pledge">
                                            <v-icon color="black" size="40"
                                                class="mb-03">mdi-hand-extended-outline</v-icon>
                                            <br>
                                            <p
                                                class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16">
                                                Pledge</p>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="3" class="text-center py-1 px-0">
                                        <v-card height="80px" color="transparent"
                                            class="app-menu-items pt-2 rounded-lg elevation-0" target="_blank"
                                            rel="noopener noreferrer" href="https://fund.mynt.in/">
                                            <img src="@/assets/funds.svg" alt="fund" class="mb-02 pa-1"
                                                width="34px"><br>
                                            <p
                                                class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16">
                                                Add
                                                fund</p>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <v-row no-glutters>
                                    <v-col cols="12" class="py-0 px-0">
                                        <v-card class="elevation-0 rounded-0 py-1" color="#EBEEF0"></v-card>
                                    </v-col>
                                </v-row>
                                <v-row no-glutters class="mx-0">

                                    <v-col cols="12" class="pb-0 pl-0">
                                        <p class="fs-16 mb-0">Backoffice</p>
                                    </v-col>
                                    <v-col cols="3" class="text-center py-1 px-0">
                                        <v-card height="80px" color="transparent"
                                            class="app-menu-items pt-2 rounded-lg elevation-0" target="_blank"
                                            rel="noopener noreferrer" href="https://bo.zebull.in/WebClient/">
                                            <img src="@/assets/redir-icon.svg" alt="redir-icon" class="mb-0 pa-1"
                                                width="38px"><br>
                                            <p
                                                class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16">
                                                Client</p>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="3" class="text-center py-1 px-0">
                                        <v-card height="80px" color="transparent"
                                            class="app-menu-items pt-2 rounded-lg elevation-0" target="_blank"
                                            rel="noopener noreferrer" alt="redir-icon"
                                            href="https://bo.zebull.in/WebLogin/index.cfm?Logintype=Branch">
                                            <img src="@/assets/redir-icon.svg" class="mb-0 pa-1" width="38px"><br>
                                            <p
                                                class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16">
                                                Branch</p>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="3" class="text-center py-1 px-0">
                                        <v-card height="80px" color="transparent"
                                            class="app-menu-items pt-2 rounded-lg elevation-0" target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://bo.zebull.in/WebLogin/index.cfm?Logintype=SUBBRANCH">
                                            <img src="@/assets/redir-icon.svg" alt="redir-icon" class="mb-0 pa-1"
                                                width="38px"><br>
                                            <p
                                                class="app-menu-item-txt  mb-0 caption font-weight-light black--text lh-16">
                                                AP
                                            </p>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-expansion-panel-content>
                        <v-divider></v-divider>
                    </v-expansion-panel>

                    <v-expansion-panel class="exp-sty appbar-exp" @click="nativeDrawer(), $router.push('/invest')">
                        <v-expansion-panel-header class="font-weight-bold fs-18 py-0"
                            :color="exppanel == 1 ? '#EFF3F3' : '#F4F4F4'">Invest
                            <template v-slot:actions>
                                <v-icon></v-icon>
                            </template>
                        </v-expansion-panel-header>
                        <v-divider></v-divider>
                    </v-expansion-panel>
                    <v-expansion-panel class="exp-sty appbar-exp" @click="nativeDrawer(), $router.push('/trade')">
                        <v-expansion-panel-header class="font-weight-bold fs-18 py-0"
                            :color="exppanel == 2 ? '#EFF3F3' : '#F4F4F4'">Trade
                            <template v-slot:actions>
                                <v-icon></v-icon>
                            </template>
                        </v-expansion-panel-header>
                        <v-divider></v-divider>
                    </v-expansion-panel>
                    <v-expansion-panel class="exp-sty appbar-exp" @click="nativeDrawer(), $router.push('/product')">
                        <v-expansion-panel-header class="font-weight-bold fs-18 py-0"
                            :color="exppanel == 3 ? '#EFF3F3' : '#F4F4F4'">Product
                            <template v-slot:actions>
                                <v-icon></v-icon>
                            </template>
                        </v-expansion-panel-header>
                        <v-divider></v-divider>
                    </v-expansion-panel>
                    <v-expansion-panel class="exp-sty appbar-exp">
                        <v-expansion-panel-header class="font-weight-bold fs-18 py-0"
                            :color="exppanel == 4 ? '#EFF3F3' : '#F4F4F4'">Learn
                            <template v-slot:actions>
                                <div>
                                    <v-icon color="black">{{ exppanel == 4 ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                                </div>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content :color="exppanel == 4 ? '#EFF3F3' : '#F4F4F4'">
                            <div>
                                <router-link v-for="(item, index) in menuitems.slice(0, 4)" :key="index" :to="item.too"
                                    @click.native="nativeDrawer()" class="txt-dec">
                                    <p class="mb-4 txt-666666 lh-16">{{ item.title }}</p>
                                </router-link>
                            </div>
                        </v-expansion-panel-content>
                        <v-divider></v-divider>
                    </v-expansion-panel>
                    <!-- $router.push('/price') -->
                    <v-expansion-panel class="exp-sty appbar-exp d-none" @click="nativeDrawer()">
                        <v-expansion-panel-header class="font-weight-bold fs-18 py-0"
                            :color="exppanel == 5 ? '#EFF3F3' : '#F4F4F4'">Pricing
                            <template v-slot:actions>
                                <v-icon></v-icon>
                            </template>
                        </v-expansion-panel-header>
                        <!-- <v-divider></v-divider> -->
                    </v-expansion-panel>
                    <v-expansion-panel class="exp-sty appbar-exp" @click="nativeDrawer(), $router.push('/support')">
                        <v-expansion-panel-header class="font-weight-bold fs-18 py-0"
                            :color="exppanel == 6 ? '#EFF3F3' : '#F4F4F4'">Support
                            <template v-slot:actions>
                                <v-icon></v-icon>
                            </template>
                        </v-expansion-panel-header>
                        <v-divider></v-divider>
                    </v-expansion-panel>
                    <v-expansion-panel class="exp-sty appbar-exp">
                        <v-expansion-panel-header class="font-weight-bold fs-18 py-0"
                            :color="exppanel == 7 ? '#EFF3F3' : '#F4F4F4'">Company
                            <template v-slot:actions>
                                <div>
                                    <v-icon color="black">{{ exppanel == 7 ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                                </div>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content :color="exppanel == 7 ? '#EFF3F3' : '#F4F4F4'">
                            <div>
                                <router-link v-for="(item, index) in menuitems.slice(4, 8)" :key="index" :to="item.too"
                                    @click.native="nativeDrawer()" class="txt-dec">
                                    <p class="mb-4 txt-666666 lh-16">{{ item.title }}</p>
                                </router-link>
                            </div>
                        </v-expansion-panel-content>
                        <v-divider></v-divider>
                    </v-expansion-panel>

                </v-expansion-panels>
            </v-card>
        </v-expand-transition>
    </div>
</template>

<script>
import axios from 'axios';
import eventBus from '../eventBus.js'
import { genuid, myntapi } from '../apiurl.js';

export default {
    data: () => ({
        drawer: false,
        mobilebar: false,
        exppanel: null,

        one: false,
        two: false,
        tre: false,
        fou: false,
        fiv: false,
        menuitems: [
            { too: "/blogs", title: 'Blogs' },
            { too: "/videos", title: 'Videos' },
            { too: "/news", title: 'News' },
            { too: "/calculator", title: 'Calculators' },
            { too: "/about", title: 'About Zebu' },
            { too: "/lifeatzebu", title: 'Life @ Zebu' },
            { too: "/career", title: 'Career' },
            { too: "/contactus", title: 'Contact us' },
        ],

        isLoading: false,
        items: [],
        searchscript: null,
        model: null,
        nodata: null,
    }),
    watch: {
        searchscript(val) {
            if (val && val.length > 2) {
                this.isLoading = true
                let data = `jData={"uid":"${genuid.uid}","stext":"${val.replace(' ', '_')}"}&jKey=${genuid.token}`;
                let config = {
                    method: 'post',
                    url: `${myntapi}/SearchScrip`,
                    headers: {
                        'Content-Type': 'text/plain'
                    },
                    data: data
                };

                let axiosThis = this;
                axios.request(config)
                    .then((response) => {
                        axiosThis.nodata = null;
                        if (response.data.stat == "Ok" || response.data.values) {
                            axiosThis.items = [];
                            for (const element of response.data.values) {
                                if (element.exch == "NSE" && element.instname == 'EQ') {
                                    axiosThis.items.push(element);
                                    // axiosThis.items = response.data.values;

                                }
                            }
                        } else if (response.data.emsg == "No Data :   ") {
                            axiosThis.items = [];
                            axiosThis.nodata = 'noooo';
                        }
                        axiosThis.isLoading = false

                    })
                    .catch((error) => {
                        // console.log(error);
                        if (error) {
                            axiosThis.items = [];
                            axiosThis.isLoading = false;
                            axiosThis.nodata = 'noooo';
                        }
                        axiosThis.$emit('snack', 0, error)
                    });
            }
        },
    },

    mounted() {
        // setTimeout(() => {
        //     this.$router.push({ path: '/stocks/single' });
        // }, 4000);
    },
    methods: {
        nativeDrawer() {
            this.drawer = false;
            // this.exppanel = null;
        },
        toHome() {
            this.$router.push('/');
        },
        addSearch() {
            if(this.$route.path == '/stocks/single'){
                eventBus.$emit('custom-event', `${this.model.exch}:${this.model.tsym}`)
            }else if (this.$route.name == 'single stocks') {
                window.location.href = `/stocks/${this.model.tsym.split('-')[0].toLowerCase()}`;
            } else {
                this.$router.push({ name: `single stocks`, params: { token: this.model.token, tsym: `${this.model.exch}:${this.model.tsym}`, exch: this.model.exch } });
            }
            // window.location.href = `/stocks/${this.model.tsym.split('-')[0].toLowerCase()}`;
            this.items = [];
            this.model = null;
        }
    }
}
</script>