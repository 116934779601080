<template>
    <div>

        <div>
            <div class="my-16">
                <v-toolbar dense class="elevation-0 mb-6">
                    <div>
                        <p class="mb-0 font-weight-bold secondary-infont fs-32 lh-32">All shorts</p>
                        <p class="mb-0 title font-weight-regular">Categorised list of all the videos we've made.</p>
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn data-aos="fade-up" medium color="#2A2A2A" class="rounded-pill mr-2 text-none brd-2"
                        outlined>Popular</v-btn>
                    <v-btn data-aos="fade-up" medium color="#2A2A2A" class="rounded-pill mr-2 text-none brd-2"
                        outlined>Latest</v-btn>

                </v-toolbar>

                <div class="pl-4">
                    <v-row v-if="allshort.length > 0" no-glutters class="pt-6">
                        <v-col v-for="(v, s) in allshort" :key="s" cols="12" sm="3" md="2" xl="1">
                            <div @click="shortShow(val = v)" data-aos="zoom-in-up" class="mr-3 hcard">
                                <v-card class="rounded-lg elevation-0" color="transparent">
                                    <img :src="v.stdurl" width="100%" height="328px" class="short-sty" :alt="v.stdurl">
                                </v-card>
                                <v-list-item class="px-0">
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-bold subtitle-1 text-rap-l3">{{ v.title
                                        }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                        </v-col>
                    </v-row>
                    <div v-else>
                        <v-container fill-height>
                            <v-card class="elevation-0 mx-auto my-16">
                                <v-progress-circular size="80" indeterminate color="#1e53e5"></v-progress-circular>
                            </v-card>
                        </v-container>
                    </div>
                    <v-container class="mt-10">
                        <v-card v-if="shortpagecount" data-aos="fade-down" max-width="600px"
                            class="box-s1 rounded-xl mx-auto py-1">
                            <v-pagination @input="getShort()" color="#000" prev-icon="mdi-arrow-left"
                                next-icon="mdi-arrow-right" v-model="shortpage" class="my-3 elevation-0" :total-visible="11"
                                :length="shortpagecount"></v-pagination>
                        </v-card>
                    </v-container>
                </div>
            </div>
        </div>
        <div>
            <v-dialog v-model="shortdialog" class="elevation-0 my-0" content-class="elevation-0">
                <v-card color="transparent" class="rounded-md mx-auto elevation-0 pb-0 mb-0 short-dialog">
                    <div class="pos-rlt">
                        <div class="pos-abs abs-post5 d-none d-md-block">
                            <v-btn :disabled="playshort ? playshort.id != 0 ? false : true : null" @click="shortPrev()"
                                @keyup.left="shortPrev()" medium color="#ffffff"
                                class="rounded-pill mr-3 text-none my-auto brd-2" outlined>Previous
                                short</v-btn>
                        </div>
                        <div>
                            <v-toolbar color="transparent" heigth="32px" dense class="tool-dialog elevation-0">
                                <v-spacer></v-spacer>
                                <v-icon @click="shortClose()" color="#ffffff">mdi-close</v-icon>
                            </v-toolbar>
                            <iframe class="rounded-lg short-frame" type="text/html" width="100%"
                                :src="`https://www.youtube.com/embed/${playshort ? playshort.videoid : null}?autoplay=1&amp;modestbranding=1`"
                                frameborder="0"
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                        </div>
                        <v-row no-glutters class="d-md-none">
                            <v-col cols="6">
                                <v-btn :disabled="playshort ? playshort.id != 0 ? false : true : null" @click="shortPrev()"
                                    @keyup.left="shortPrev()" medium color="#ffffff" block
                                    class="rounded-pill text-none my-auto brd-2" outlined>Previous
                                    short</v-btn>
                            </v-col>
                            <v-col cols="6">
                                <v-btn :disabled="playshort ? playshort.id != 23 ? false : true : null" @click="shortNext()"
                                    @keyup.right="shortNext()" medium color="#ffffff" block
                                    class="rounded-pill text-none my-auto brd-2" outlined>Next
                                    short</v-btn>
                            </v-col>
                        </v-row>
                        <div class="pos-abs z-in1 trans-cent abs-post4">
                            <v-progress-circular v-if="shortdialog" size="80" :width="4" indeterminate
                                color="#ffffff"></v-progress-circular>
                        </div>
                        <div class="pos-abs abs-post6 d-none d-md-block">
                            <v-btn :disabled="playshort ? playshort.id != 23 ? false : true : null" @click="shortNext()"
                                @keyup.right="shortNext()" medium color="#ffffff"
                                class="rounded-pill ml-3 text-none my-auto brd-2" outlined>Next
                                short</v-btn>
                        </div>
                    </div>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { sessapi } from '../../apiurl'

export default {
    data: () => ({
        display: 0,
        playshort: null,
        shortdialog: false,
        shortpage: 1,
        shortpagecount: 0,
        allshort: [],
    }),
    mounted() {
        this.getShort();
        this.shortClose();
        this.scrollBehavior();
    },
    methods: {
        getShort() {
            this.allshort = []
            let config = {
                method: 'post',
                url: `${sessapi}/getallshorts?pagecount=${this.shortpage}&pageSize=24`,
            };

            let axiosThis = this;
            axios.request(config)
                .then((response) => {
                    if (response.data.data) {
                        let data = response.data.data
                        for (let v = 0; v < data.length; v++) {
                            data[v]['isdate'] = `${new Date(data[v].uploadtime).toDateString().slice(3)} ${new Date(data[v].uploadtime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} IST`;
                            data[v]['ago'] = axiosThis.differentDate(data[v].uploadtime);
                            data[v]['view'] = axiosThis.differentView(Number(data[v].viewCount));
                            data[v]['id'] = v;
                            axiosThis.allshort.push(data[v]);
                        }
                        axiosThis.shortpagecount = response.data.TotalPages;
                        axiosThis.scrollBehavior();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        differentView(view) {
            return `${view > 999 ? Math.round(view / 1000) : view > 999999 ? Math.round(view / 1000000) : view > 999999999 ? Math.round(view / 1000000000) : view} ${view > 999 ? 'K' : view > 999999 ? 'M' : view > 999999999 ? 'B' : ''}`
        },
        differentDate(date) {
            var date1 = new Date(date);
            var date2 = new Date();
            var ov = Math.abs(date2.getTime() - date1.getTime())
            var mt = Math.round(ov / 3600000)
            var dd = mt > 60 ? Math.round(mt / 24) : 0;
            var mm = dd > 30.4166667 ? Math.round(dd / 30.4166667) : 0;
            var yy = mm > 12 ? Math.round(dd / 365) : 0;
            return `${yy != 0 ? yy : mm != 0 ? mm : dd != 0 ? dd : mt != 0 ? mt : 0} ${yy != 0 ? yy > 1 ? 'years' : 'year' : mm != 0 ? mm > 1 ? 'months' : 'month' : dd != 0 ? dd > 1 ? 'days' : 'day' : mt > 1 ? 'hours' : 'minutes'} ago`;
        },
        scrollBehavior() {
            window.scrollTo(0, 0);
        },

        shortShow(val) {
            this.playshort = val;
            this.shortdialog = true;
        },
        shortNext() {
            let ids = this.allshort[this.playshort.id + 1];
            this.playshort = null;
            this.playshort = ids;
        },
        shortPrev() {
            let ids = this.allshort[this.playshort.id - 1];
            this.playshort = null;
            this.playshort = ids;
        },
        shortClose() {
            this.playshort = [];
            this.shortdialog = false;
        },
    }
}
</script>